<template>

  <div>
    <input :id="id" type="checkbox" class="is-checkradio" :class="classes" @change="handleChange" :checked="checked">
    <label :for="id"><slot/></label>
  </div>

</template>
<script>

  import uuid from '~/lib/uuid';

  export default {

    props: {
      checked: {
        required: true,
        type: Boolean,
      },
      classes: {
        required: false,
        type: Array,
        default: () => [],
      },
    },

    data () {
      return {
        id: `toggle-switch-${uuid.v4()}`,
      };
    },

    methods: {
      handleChange (event) {
        let {checked} = event.target;
        this.$emit('toggle', checked);
      },
    },
  };

</script>
