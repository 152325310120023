import date from '~/lib/date';
import {duration} from '~/lib/date';
import {WARRANTY_TYPES, WARRANTY_TYPE_WORKMANSHIP, WARRANTY_TERM_UNITS} from '~/lib/domain/constants';
import {InvalidArgument} from '~/lib/exceptions';

export class Warranty {

  static params (attrs) {
    let warranty = new Warranty(attrs);
    warranty.validate();
    return warranty.toJSON();
  }

  constructor ({type, exclusion, lifetime, unit, length, description, start}) {
    this.type = type;
    this.exclusion = Boolean(exclusion);
    this.lifetime = Boolean(lifetime);
    this.unit = unit;
    this.length = Number(length);
    this.description = description;
    this.start = start;

    if (this.exclusion) {
      this.lifetime = false;
      this.unit = null;
      this.length = null;
    }
    else if (this.lifetime) {
      this.exclusion = false;
      this.unit = null;
      this.length = null;
    }

    if (this.type === WARRANTY_TYPE_WORKMANSHIP) {
      this.description = null;
    }
  }

  validate () {
    this.validateType();
    this.validateTerm();
    this.validateStartDate();
  }

  validateType () {
    let {type} = this;
    if (!type || !WARRANTY_TYPES.hasOwnProperty(type)) {
      throw new InvalidArgument('invalid warranty type');
    }
  }

  validateTerm () {
    let {exclusion, lifetime, unit, length} = this;
    if (exclusion || lifetime) {
      return;
    }
    if (!unit || !WARRANTY_TERM_UNITS.hasOwnProperty(unit)) {
      throw new InvalidArgument('invalid warranty term unit');
    }
    if (typeof length !== 'number' || isNaN(length) || length < 1) {
      throw new InvalidArgument('invalid warranty term unit size');
    }
  }

  validateStartDate () {
    let {start} = this;
    if (start && !date(start).isValid()) {
      throw new InvalidArgument('invalid warranty start date');
    }
  }

  toJSON () {
    let {type, exclusion, lifetime, unit, length, description, start} = this;
    return {
      type,
      exclusion,
      lifetime,
      term: unit && length ? duration.spec(unit, length) : null,
      description,
      starts_at: start,
    };
  }
}

export default {
  Warranty,
};
