import DataRecordModule from '~/lib/vue/modules/datarecord';
import P from '~/lib/promise';
import lang from '~/lib/lang';
import constants from '~/lib/domain/constants';

export default (services, namespace='record') => {

  const propagate = async ({state, dispatch, commit}) => {
    let {attributes} = state;
    let {service, billing, mailing} = attributes.addresses;

    let chain1 = [];
    let chain2 = [];

    if (service) {
      if (service.country) {
        chain1.push(dispatch('customer/serviceAddressTerritories/setCountry', service.country.id, {root: true}));
      }
      if (service.province) {
        chain2.push(dispatch('customer/serviceAddressTerritories/setProvince', service.province.id, {root: true}));
      }
    }

    if (billing) {
      if (billing.country) {
        chain1.push(dispatch('customer/billingAddressTerritories/setCountry', billing.country.id, {root: true}));
      }
      if (billing.province) {
        chain2.push(dispatch('customer/billingAddressTerritories/setProvince', billing.province.id, {root: true}));
      }
    }

    if (mailing) {
      if (mailing.country) {
        chain1.push(dispatch('customer/mailingAddressTerritories/setCountry', mailing.country.id, {root: true}));
      }
      if (mailing.province) {
        chain2.push(dispatch('customer/mailingAddressTerritories/setProvince', mailing.province.id, {root: true}));
      }
    }

    await P.all(chain1);
    await P.all(chain2);
  };

  return DataRecordModule.create({
    namespace,
    services,
    type: 'customer',
    resource: '/customers',
    describe: (payload) => {
      let {type, name, company, firstname, lastname} = payload;
      if (type === constants.CUSTOMER_TYPE_COMPANY) {
        return `Customer ${name}`;
      }
      if (company && company.name) {
        return `${name} (${company.name})`;
      }
      return name;
    },

    /**
     * Representation of the record from server to client
     */
    attributes: {
      id: null,
      type: null,
      name: null,
      firstname: null,
      lastname: null,
      email: null,
      tel: null,
      tel_secondary: null,
      company: null,
      addresses: {
        service: null,
        billing: null,
        mailing: null,
      },
    },

    /**
     * Blank representation of the record from client to server
     */
    form: {
      type: null,
      name: null,
      company: null,
      company_id: null,
      firstname: null,
      lastname: null,
      email: null,
      tel: null,
      tel_secondary: null,
      service_address: null,
      service_city: null,
      service_province_id: null,
      service_postcode: null,
      billing_address: null,
      billing_city: null,
      billing_province_id: null,
      billing_postcode: null,
      mailing_address: null,
      mailing_city: null,
      mailing_province_id: null,
      mailing_postcode: null,
      company: null,
    },

    /**
     * Map attributes to the form; used to assign initial form values
     * @param {Object} attributes
     * @return {Object}
     */
    map: (attributes) => {
      let {addresses, company} = attributes;
      let service = addresses.service || {};
      let billing = addresses.billing || {};
      let mailing = addresses.mailing || {};
      return {
        ...attributes,
        type: attributes.type ? attributes.type : constants.CUSTOMER_TYPE_INDIVIDUAL,
        company_id: company ? company.id : null,
        billing_address: billing.address,
        billing_city: billing.city,
        billing_postcode: billing.postcode,
        billing_province_id: billing.province ? billing.province.id : null,
        service_address: service.address,
        service_city: service.city,
        service_postcode: service.postcode,
        service_province_id: service.province ? service.province.id : null,
        mailing_address: mailing.address,
        mailing_city: mailing.city,
        mailing_postcode: mailing.postcode,
        mailing_province_id: mailing.province ? mailing.province.id : null,
      };
    },

    /**
     * Collect attributes from external modules and assign them to the form
     * @param {vuex.Store} store
     * @param {Object} form
     * @return {Object}
     */
    marshal: ({rootGetters}, form) => {
      return {
        ...form,
        service_province_id: rootGetters['customer/serviceAddressTerritories/province_id'],
        billing_province_id: rootGetters['customer/billingAddressTerritories/province_id'],
        mailing_province_id: rootGetters['customer/mailingAddressTerritories/province_id'],
      };
    },

    afterLoad: propagate,

    getters: {

      deletable: (state) => {
        let {stats} = state.attributes;
        if (stats) {
          let {job_count, member_count} = stats; 
          return job_count === 0 && member_count === 0;
        }
        return null;
      },
    },

    actions: {

      clearBillingAddress (store) {
        store.dispatch('assignFormAttributes', {
          billing_address: null,
          billing_city: null,
          billing_province_id: null,
          billing_postcode: null,
        });
      },

      clearMailingAddress (store) {
        store.dispatch('assignFormAttributes', {
          mailing_address: null,
          mailing_city: null,
          mailing_province_id: null,
          mailing_postcode: null,
        });
      },
    },

  });
};
