<template>
  <main-menu>
    <div v-if="auth.user" slot="navbar-end" class="navbar-end">
      <a class="navbar-item" @click="logout"><icon :classes="['fa', 'fa-sign-out-alt']" :tooltip="{text: 'Sign out', position: 'bottom'}" /></a>
    </div>
  </main-menu>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import MainMenu from '~/components/MainMenu.vue';
  import Icon from '~/components/Icon.vue';
  export default {

    components: {
      Icon,
      MainMenu,
    },

    computed: {
      ...mapGetters('auth', {
        auth: 'props',
      }),
      ...mapGetters('upload', {
        upload: 'props',
      }),

      uploadButtonClassBindings () {
        let {blink} = this.upload.panel;
        return {blink};
      },
    },

    methods: {
      ...mapActions('auth', {
        initializeAuth: 'initialize',
        logout: 'logout',
      }),
      ...mapActions('upload', {
        toggleUploadPanel: 'togglePanel',
      }),
    },

    mounted () {
      this.initializeAuth();
    },
  };

</script>
