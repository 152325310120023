<template>

	<article class="media attachment">
		<figure class="media-left">
			<div class="thumbnail">
				<attachment-thumbnail :attributes="attributes"/>
        <p class="file-info">{{ attributes.blob.mime }} / {{ fileSize }}</p>
			</div>
		</figure>
		<div class="media-content">
      <attachment-form
        :index="index"
        :attributes="attributes"
        :clipboard="clipboard"
        @change="handleChange"
      />
    </div>
		<div class="media-right">
      <btn :flat="true" @click="handleDetach">
        <icon :classes="['fa', 'fa-times']" tooltip="Remove"/>
      </btn>
		</div>
	</article>

</template>

<script>
  import AttachmentForm from './AttachmentForm.vue';
  import AttachmentThumbnail from './AttachmentThumbnail.vue';
  import Btn from '~/components/Btn.vue';
  import Icon from '~/components/Icon.vue';

  export default {

    components: {
      AttachmentForm,
      AttachmentThumbnail,
      Btn,
      Icon,
    },

    props: {
      attributes: {
        required: true,
        type: Object,
      },
      index: {
        required: true,
        type: Number,
      },
      clipboard: {
        required: false,
        default: null,
      },
    },

    computed: {
      abbreviatedSum () {
        let {sum} = this.attributes.blob;
        return sum.slice(0, 16);
      },
      fileSize () {
        let {size} = this.attributes.blob;
        let kb = 1024;
        let mb = 1024 * kb;
        let gb = 1000 * mb;
        let format = (v, unit) => `${Number.parseFloat(v).toFixed(1)} ${unit}`;
        if (size > gb) {
          return format(size/gb, 'GB');
        }
        if (size > mb) {
          return format(size/mb, 'MB');
        }
        return format(size/kb, 'kB');
      },
    },

    methods: {
      handleDetach () {
        let {rel, blob} = this.attributes;
        let {sum} = blob;
        this.$emit('detach', {rel, sum});
      },

      handleChange (...args) {
        this.$emit('change', ...args);
      },
    },
  };

</script>
