import DataRecordModule from '~/lib/vue/modules/datarecord';
import P from '~/lib/promise';
import lang from '~/lib/lang';
import constants from '~/lib/domain/constants';
import {CaseStudyValidator} from '~/lib/domain/validation';
const {clone} = lang.object;

export default (services, namespace='case-study') => {

  const create_default_picture = () => {
    return {
      image: null,
      thumbnail: null,
      caption: '',      
    };
  };

  const create_default_poster = () => {
    return {
      tagline: null,
      text: null,
      image: null,
      thumbnail: null,
    };
  };

  return DataRecordModule.create({
    namespace,
    services,
    type: 'case-study',
    resource: '/content/case-studies',
    describe: (payload) => {
      return payload.title;
    },

    /**
     * Representation of the record from server to client
     */
    attributes: {
      id: null,
      job_id: null,
      slug: null,
      title: null,
      text: null,
      content: {},
      created_at: null,
      updated_at: null,
      published_at: null,
    },

    validator: CaseStudyValidator,

    /**
     * Blank representation of the record from client to server
     */
    form: {
      job_id: null,
      slug: null,
      title: null,
      text: null,
      content: {
        specs: null,
        poster: null,
        pictures: [],
      },
      published_at: null,
    },

    /**
     * Assign initial values to the form given a set of attributes
     * @param {Object} attributes
     * @return {Object}
     */
    map: (attributes) => {
      let form = {...attributes};
      let content = {...form.content};
      let poster = content.poster || create_default_poster();
      let pictures = content.pictures || [];
      return {
        ...form,
        content: {
          ...form.content,
          poster: {
            ...poster,
            image: poster && poster.image ? poster.image.sum : null,
            thumbnail: poster && poster.thumbnail ? poster.thumbnail .sum : null,
          },
          pictures: pictures.map((picture) => {
            return {
              ...picture,
              image: picture.image.sum,
              thumbnail: picture.thumbnail.sum,
            };
          }),
        },
      };
    },

    /**
     * Collect attributes from external modules and assign them to the form
     * @param {vuex.Store} store
     * @param {Object} form
     * @return {Object}
     */
    marshal: ({rootGetters}, form) => {
      return form;
    },

    //afterLoad: propagate,

    actions: {

      /**
       * @param {String} key 
       * @param {String} value
       */
      assignSpec: (store, {key, value}) => {
        let form = clone(store.state.form);
        let specs = {...form.content.specs};
        specs[key] = value;
        form.content.specs = specs;
        store.commit('form', form);
      },

      clearJob: (store) => {
        let form = {...store.state.form};
        form.job_id = null;
        store.commit('form', form);
      },

      /**
       * @param {String} blob - SHA-256 blob sum
       */
      assignPosterAttributes: (store, {attributes}) => {
        let form = clone(store.state.form);
        let poster = {...form.content.poster};
        lang.collection.each(attributes, (value, attr) => {
          poster[attr] = value;
        });
        form.content.poster = poster;
        store.commit('form', form);
      },

      /**
       * @param {File} blob - an HTML uploaded File object
       */
      assignPosterAsset: async (store, {blob}) => {
        let {image, thumbnail} = await store.dispatch('uploadImage', {blob});
        let form = clone(store.state.form);
        let poster = {...form.content.poster};
        poster.image = image.file.sum;
        poster.thumbnail = thumbnail.file.sum;
        form.content.poster = poster;
        store.commit('form', form);
      },

      deletePosterAsset: (store) => {
        let form = clone(store.state.form);
        let poster = {...form.content.poster};
        poster.image = null;
        poster.thumbnail = null;
        form.content.poster = poster;
        store.commit('form', form);
      },

      addPicture: (store) => {
        let form = clone(store.state.form);
        let pictures = [...form.content.pictures, create_default_picture()];
        form.content.pictures = pictures;
        store.commit('form', form);
      },

      /**
       * @param {Number} picture - picture index
       */
      deletePicture: (store, {picture}) => {
        let form = clone(store.state.form);
        let pictures = [...form.content.pictures];
        pictures.splice(picture, 1);
        form.content.pictures = pictures;
        store.commit('form', form);
      },

      /**
       * @param {String} blob - SHA-256 blob sum
       * @param {Object} attributes
       */
      assignPictureAttributes: (store, {picture, attributes}) => {
        let form = clone(store.state.form);
        let pictures = [...form.content.pictures];
        lang.collection.each(attributes, (value, attr) => {
          pictures[picture][attr] = value;
        });
        form.content.pictures = pictures;
        store.commit('form', form);
      },

      /**
       * @param {File} blob - an HTML uploaded File object
       */
      assignPictureAsset: async (store, {picture, blob}) => {
        let {image, thumbnail} = await store.dispatch('uploadImage', {blob});
        let form = clone(store.state.form);
        let pictures = [...form.content.pictures];
        pictures[picture].image = image.file.sum;
        pictures[picture].thumbnail = thumbnail.file.sum;
        form.content.pictures = pictures;
        store.commit('form', form);
      },

      /**
       * @param {Number} picture - picture index
       */
      deletePictureAsset: (store, {picture}) => {
        let form = clone(store.state.form);
        let pictures = [...form.content.pictures];
        pictures[picture].image = null;
        pictures[picture].thumbnail = null;
        form.content.pictures = pictures;
        store.commit('form', form);
      },

      getUploadBucket: async (store) => {
        let id = store.state.attributes.id || 'default';
        let bucket_id = `case-study:${id}`;
        let title = id ? `Case Study ${id}` : 'New Case Study';
        let bucket = await store.dispatch('upload/getBucket', {id: bucket_id, title}, {root: true});
        return bucket;
      },

      uploadImage: async (store, {blob}) => {

        // transform the images via Imaginary server
        let transformed = await P.all([
          store.dispatch('imaginary/pipeline', {
            blob,
            transforms: [
              {operation: 'resize', params: {width: 1920}},
            ],
          }, {root: true}),
          store.dispatch('imaginary/pipeline', {
            blob,
            transforms: [
              {operation: 'resize', params: {width: 512}},
            ],
          }, {root: true}),
        ]);

        let bucket = await store.dispatch('getUploadBucket');

        // upload the transformed blobs
        let uploads = await P.all(transformed.map(async (blob) => {
          let upload = await store.dispatch('upload/upload', {bucket, blob}, {root: true});
          await upload.finished;
          return upload;
        }));

        let [image, thumbnail] = uploads;

        // return named upload.Upload instances
        return {image, thumbnail};
      }
    },

  });
};
