import DataRecordModule from '~/lib/vue/modules/datarecord';

export default (services, namespace='record') => {

  const propagate = async ({state, dispatch}) => {
    let {country, province} = state.attributes;
    if (country) {
      await dispatch('property/territories/setCountry', country.id, {root: true});
    }
    if (province) {
      await dispatch('property/territories/setProvince', province.id, {root: true});
    }
  };

  return DataRecordModule.create({
    namespace,
    services,
    type: 'property',
    resource: '/properties',
    describe: payload => `Property ${payload.address.split('\n').shift()}, ${payload.city}`,

    attributes: {
      id: null,
      name: null,
      email: null,
      tel: null,
      tel_secondary: null,
      address: null,
      city: null,
      postcode: null,
      coordinates: null,
      province: null,
      country: null,
    },

    form: {
      name: null,
      email: null,
      tel: null,
      tel_secondary: null,
      address: null,
      city: null,
      postcode: null,
      country_id: null,
      province_id: null,
    },

    /**
     * Map attributes to the form; used to assign initial form values
     * @param {Object} attributes
     * @return {Object}
     */
    map: (attributes) => {
      let {province, country} = attributes;
      return {
        ...attributes,
        province_id: province ? province.id : null,
        country_id: country ? country.id : null,
      };
    },

    /**
     * Assign state managed by external modules to the form
     * @param {vuex.Store} store
     * @param {Object} form
     * @return {Object}
     */
    marshal: ({rootGetters}, form) => {
      return {
        ...form,
        country_id: rootGetters['property/territories/country_id'],
        province_id: rootGetters['property/territories/province_id'],
      };
    },

    afterLoad: propagate,

    getters: {

      deletable: (state) => {
        let {stats} = state.attributes;
        return stats ? stats.job_count === 0 : null;
      },
    },
  });
};
