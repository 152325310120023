import {Component} from '~/lib/foundation';
import exceptions from '~/lib/exceptions';
import lang from '~/lib/lang';
import P from '~/lib/promise';
import Job from './job';

class Queue extends Component {

  get defaults () {
    return {
      ...super.defaults,
      concurrency: 2,
      resolvers: {
        job: (...args) => new Job(...args),
      },
    };
  }

  constructor (...args) {
    super(...args);
    this.buffers = this.createBuffers(this.option('concurrency'));
    this.jobs = [];
  }

  createBuffers (n) {
    let buffers = [];
    buffers.length = n;
    return buffers.map((v) => null);
  }

  push (task, options={}) {
    if (typeof task !== 'function') {
      throw new exceptions.InvalidArgument('task argument must be a function');
    }
    let defaults = {ttl: null, parameters: []};
    let {ttl, parameters} = {...defaults, ...options};
    let job = this.make('job', {task, parameters, ttl});

    this.jobs.push(job);

    return job;
  }

  /**
   * Get the index of a free buffer. A free buffer is one that does not
   * contain a job.
   * @return {Number|null}
   */
  free () {
    let {buffers} = this;
    let max = buffers.length;
    let index = null;
    for (let i = 0; i < max; i++) {
      if (!buffers[i]) {
        index = i;
        break;
      }
    }
    return index;
  }

  async run () {
    clearTimeout(this.iterating);
    let iterate, again;

    again = (delay) => {
      this.iterating = setTimeout(iterate, delay);
    };

    iterate = async () => {
      let {jobs, buffers} = this;

      // exit if there are no queued jobs
      if (!jobs.length) {
        return again(200);
      }

      /// identify a free buffer
      let index = this.free();
      if (index === null) {
        return again(200);
      }

      // shift the next job in the queue into the free buffer
      let job = jobs.shift();
      buffers[index] = job;

      job.evictable.then(() => {
        job.destroy();
        buffers[index] = null;
        again(0);
      });

      await job.run();
    };
 
    iterate();
  }

}

export default Queue;
