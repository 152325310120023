<template>

  <div class="media">
    <div class="media-content">
      <note
        v-if="notes.length"
        v-for="(note, i) in notes"
        :key="i"
        :attributes="note"
        @delete="$emit('delete', note.id)"
      />
      <p v-else>No notes.</p>
    </div>
  </div>

</template>
<script>

  import Note from './Note.vue';

  export default {
    name: 'note-list',
    props: {
      notes: {
        type: Array,
        required: true,
      },
    },
    components: {
      Note,
    },
  };

</script>
