<template>
  <div class="container crewsheet" v-if="job.attributes.id">

    <h1 class="title">Job #{{ job.attributes.id }}</h1>

    <div class="columns">
      <div class="column">
        <GmapMap
          v-if="job.attributes.property.coordinates"
          ref="gmap"
          :center="job.attributes.property.coordinates"
          :zoom="15"
          :options="mapOptions"
          style="height: 4in; cursor: default;">
          <GmapMarker
            :position="job.attributes.property.coordinates"
            :clickable="false"
            :draggable="false"
            @click="center=job.attributes.property.coordinates"
          />
        </GmapMap>
        <table class="table">
          <tbody>
            <tr>
              <td>Customer</td>
              <td>{{ customerName }}</td>
            </tr>
            <tr>
              <td>Address</td>
              <td>{{ job.attributes.property.address}}<br>{{ job.attributes.property.city }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="column">
        <div class="content">
          <ul v-if="route">
            <li v-for="step in route.steps" v-html="step.instructions"/>
          </ul>
        </div>
      </div>
    </div>

    <div class="container text-container">
      <h2 class="subtitle">Tasks</h2>
      <ul>
        <li v-for="text in aggregateDocumentLineItems">{{ text }}</li>
      </ul>

      <h2 class="subtitle">Notes</h2>
      <ul>
        <li v-for="note in jobNotes.notes">{{ note.text }}</li>
      </ul>
    </div>

  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import JobContext from './mixins/JobContext.vue';
  import DocumentContext from './mixins/DocumentContext.vue';
  import P from '~/lib/promise';
  import {DOCUMENT_STATE_ACCEPTED} from '~/lib/domain/constants';

  export default {
    mixins: [JobContext, DocumentContext],

    data () {
      return {
        route: null,
      };
    },

    computed: {

      ...mapGetters('layout', [
        'layoutName',
        'notificationsEnabled',
        'modalsEnabled',
      ]),

      propertyAddress () {
        let {property} = this.job.attributes;
        return `${property.address}, ${property.city}`;
      },

      mapOptions () {
        return {
          zoom: 16,
        };
      },

      customerName () {
        let {firstname, lastname} = this.job.attributes.customer;
        return [firstname, lastname].filter(Boolean).join(' ');
      },

      aggregateDocumentLineItems () {
        return this.documentTable.rows.reduce((out, doc) => {
          let accepted = doc.optionals.filter(i => i.accepted);
          out.push(doc.items.concat(accepted).map(i => i.text));
          return out;
        }, []).flat();
      },

    },

    methods: {

      ...mapActions('layout', [
        'setLayout',
      ]),

      async getGoogleMaps () {
        await this.$refs.gmap.$mapPromise;
        return window.google.maps;
      },

      async getDirectionsService () {
        let maps = await this.getGoogleMaps();
        return new maps.DirectionsService();
      },

      async acquireRoute () {
        let directions = await this.getDirectionsService();
        let params = {
          origin: '120 Sagamore Street, San Francisco, CA',
          destination: this.propertyAddress,
          travelMode: 'DRIVING'
        };

        directions.route(params, (response, status) => {
          if (status === 'OK') {
            this.route = response.routes[0].legs[0];
          }
        });
      },

    },

    mounted () {
      this.setLayout('print');

      let id = this.guessJobIdFromRoute();
      (async () => {

        await P.all([
          this.initializeJobContext(),
          this.initializeJobNotes({subject: id}),
          this.initializeDocumentTable({
            query: {
              filters: {
                match_jobs: [id],
                match_states: [DOCUMENT_STATE_ACCEPTED],
              },
            },
          }),
        ]);

        await P.all([
          this.loadJob({id}),
          this.loadDocumentTable(),
          this.loadJobNotes(),
          this.acquireRoute(),
        ]);

      })();
    },

    destroyed () {
      this.setLayout('default');
    },
  };
</script>

<style lang="scss">
.crewsheet {
  font-size: 11pt !important;

  .subtitle {
    font-size: 12pt;
    font-weight: bold;
  }

  .text-container {
    font-size: 0.8rem;

    ul {
      margin-left: 1rem;
      li {
        list-style-type: disc;
        margin-bottom: 0.8rem;
      }
    }
  }
}
</style>
