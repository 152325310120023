import DataTableModule from '~/lib/vue/modules/datatable';
import lang from '~/lib/lang';
import constants from '~/lib/domain/constants';

/**
 * @param {~/lib.foundation.Container} services
 * @return {Object}
 */
export default (services, namespace='case-studies') => {

  return DataTableModule.create({
    namespace,
    services,
    url: '/content/case-studies',
    columns: [
      {
        label: 'ID',
        value: row => row.id,
      },
      {
        label: 'Title',
        value: row => row.title,
      },
      {
        label: 'Published at',
        value: row => row.published_at || '-',
      },
    ],
  });
};
