<template>
  <div class="drop-zone" :class="classBindings">
    <p class="prompt heading">
      <icon :classes="['fas', 'fa-cloud-upload-alt']"/>
      <slot name="prompt">Drop files here</slot>
    </p>
    <slot/>
  </div>
</template>

<script>
  import Icon from '~/components/Icon.vue';
  import lang from '~/lib/lang';
  const {each} = lang.collection;

  export default {

    components: {
      Icon,
    },

    data () {
      return {
        highlight: false,
        counter: 0,
      };
    },

    computed: {

      classBindings () {
        let {highlight} = this;
        return {highlight};
      },
    },

    methods: {

      async handleDrop (event) {
        event.preventDefault();
        this.highlight = false;
        let files = this.extractFiles(event);
        if (files && files.length) {
          this.$emit('files', files);
        }
      },

      async handleDragEnter (event) {
        this.counter += 1;
        this.highlight = true;
      },

      async handleDragLeave (event) {
        this.counter -= 1;
        if (this.counter <= 0) {
          this.highlight = false;
        }
      },

      async handleDragOver (event) {
        event.preventDefault();
        event.stopPropagation();
      },

      extractFiles (event) {
        let {dataTransfer} = event;

        if (!dataTransfer) {
          return;
        }

        let {files, items} = dataTransfer;

        if (files) {
          return [].slice.call(files);
        }
        if (items) {
          return  [].slice.call(items).map((item) => item.getAsFile());
        }

        throw new Error('failed to acquire files');
      },

    },

    mounted () {
      this.listeners = {
        drop: (event) => this.handleDrop(event),
        dragover: (event) => this.handleDragOver(event),
        dragenter: (event) => this.handleDragEnter(event),
        dragleave: (event) => this.handleDragLeave(event),
      };
      each(this.listeners, (listener, event) => this.$el.addEventListener(event, listener));
    },

    beforeDestroy () {
      each(this.listeners, (listener, event) => this.$el.removeEventListener(event, listener));
    },

  };

</script>

<style lang="scss">
	@import '~/scss/main.scss';

	.drop-zone {
		display: block;
		position: relative;
		border: 4px solid $color-lightgray;
		min-height: 100px;
		width: 100%;
		padding: 2em;

		.prompt {
			color: $color-gray;
			position: relative;
			text-transform: uppercase;
			left: 50%;
			top: 50%;
			transform: translate3d(-50%, -50%, 0);
			text-align: center;
		}

		&.highlight {
			border-color: $color-dark-blue;
		}
	}

</style>
