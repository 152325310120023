<template>

  <router-link v-if="route && !tooltip" :to="route" :class="{button: !flat}" style="color: inherit;">
    <slot v-if="hasIcon && iconPosition === 'left'" name="icon"/>
    <span v-if="hasTextLabel"><slot/></span>
    <slot v-if="hasIcon && iconPosition === 'right'" name="icon"/>
  </router-link>

  <router-link v-else-if="route && tooltip" :to="route" :class="{button: !flat, tooltip: true}" style="color: inherit;" :data-tooltip="tooltip">
    <slot v-if="hasIcon && iconPosition === 'left'" name="icon"/>
    <span v-if="hasTextLabel"><slot/></span>
    <slot v-if="hasIcon && iconPosition === 'right'" name="icon"/>
  </router-link>

  <a v-else-if="!route && !tooltip" :class="{button: !flat}" @click="$emit('click')" style="color: inherit;">
    <slot v-if="hasIcon && iconPosition === 'left'" name="icon"/>
    <span v-if="hasTextLabel"><slot/></span>
    <slot v-if="hasIcon && iconPosition === 'right'" name="icon"/>
  </a>

  <a v-else="!route && tooltip" :class="{button: !flat, tooltip: true}" @click="$emit('click')" style="color: inherit;" :data-tooltip="tooltip">
    <slot v-if="hasIcon && iconPosition === 'left'" name="icon"/>
    <span v-if="hasTextLabel"><slot/></span>
    <slot v-if="hasIcon && iconPosition === 'right'" name="icon"/>
  </a>

</template>
<script>

  export default {
    name: 'btn',

    props: {
      iconPosition: {
        default: 'left',
        required: false,
        validator: (v) => {
          return v === 'left' || v === 'right';
        },
      },
      route: {
        type: Object,
        required: false,
        default: null,
        validator: (v) => v.hasOwnProperty('name') && typeof v.name === 'string',
      },
      flat: {
        type: Boolean,
        required: false,
        default: false,
      },
      tooltip: {
        type: String,
        required: false,
        default: null,
      },
    },

    computed: {
      hasIcon () {
        return !!this.$slots.icon;
      },
      hasTextLabel () {
        return !!this.$slots.default;
      },
    },
  };

</script>
