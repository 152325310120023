import {Component} from '~/lib/foundation';
import copy from 'clipboard-copy';
import lang from '~/lib/lang';

export class Clipboard extends Component {

  get defaults () {
    return {
      copy,
      ns: 'clipboard',
      notifier: null,
    };
  }

  constructor (...args) {
    super(...args);
    this.listeners = null;
  }

  /**
   * Copy supplied text string to the clipboard
   * @async
   * @param {String} text
   */
  async put (text) {
    let put = this.option('copy');

    await put(text);
    this.emit('put', text);

    let notifier = this.option('notifier', null);
    notifier && notifier.notify({
      title: 'Copied',
      text: lang.string.truncate(text, 25).split('\n').join(' '),
    });
  }

  watch (root) {
    if (this.listeners) {
      throw new Error('already watching the DOM');
    }
    if (!root || typeof root.addEventListener !== 'function') {
      throw new Error('expected HTMLElement');
    }

    this.root = root;
    this.listeners = {
      mouseover: (...args) => this.handleHover(...args),
      mouseout: (...args) => this.handleHover(...args),
      click: (...args) => this.handleButtonClick(...args),
    };

    let attach = (listener, name) => this.root.addEventListener(name, listener);
    lang.collection.each(this.listeners, attach);

    return this;
  }

  unwatch () {
    if (!this.root) {
      return this;
    }

    let detach = (listener, name) => this.root.removeEventListener(name, listener, true);
    lang.collection.each(this.listeners, detach);

    this.listeners = null;
    this.root = null;

    return this;
  }

  handleHover (event) {
    let {type, target: el} = event;
    if (!el || !el.classList) {
      return;
    }

    let ns = this.option('ns');
    let hover_class = `${ns}-hover`;

    if (!el.classList.contains(hover_class)) {
      el = el.closest(`.${ns}`);
    }

    if (el) {
      if (type === 'mouseover') {
        el.classList.add(hover_class);
      }
      if (type === 'mouseout') {
        el.classList.remove(hover_class);
      }
    }
  }

  async handleButtonClick (event) {
    let {target: button} = event;
    let ns = this.option('ns');
    let button_class = `${ns}-button`;
    if (!button && !button.classList && !button.classList.contains(button_class)) {
      return;
    }
    let hover_class = `${ns}-hover`;
    let field = button.closest(`.${hover_class}`);
    if (field) {
      let text = this.extractTextFromField(field);
      await this.put(text);
    }
  }

  extractTextFromField (el) {
    let {text} = el.dataset;
    return text || el.innerHTML || '';
  }

  async destroy (...args) {
    this.stopWatching();
    return Component.prototype.destroy.call(this, ...args);
  }

}

export default {
  Clipboard,
};
