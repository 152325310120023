import he from 'he';

export const encode = (str, options={}) => he.encode(str, options);

export class LinkProtector {

}

export default {
  encode,
  LinkProtector,
};
