<template>
  <form novalidate @submit:prevent class="term-search-form">
    <term-search-input
      :terms="terms"
      :results="results"
      :error="error"
      :progress="progress"
      :valid="valid"
      :placeholder="placeholder"
      @change="handleChange"
      @reset="handleReset"
    >
      <template slot="button">
        <slot name="button"/>
      </template>
      <template slot="results">
        <slot name="results"/>
      </template>
    </term-search-input>
  </form>
</template>

<script>
  import TermSearchInput from './TermSearchInput.vue';

  export default {
    name: 'term-search-form',

    components: {
      TermSearchInput,
    },

    props: {
      terms: {
        type: String,
        required: true,
      },
      results: {
        type: Array,
        required: true,
      },
      error: {
        type: String,
        required: true,
      },
      progress: {
        type: Boolean,
        required: true,
      },
      valid: {
        type: Boolean,
        required: true,
      },
      placeholder: {
        type: String,
        default: 'Enter search terms',
      },
    },

    methods: {

      async handleChange (terms) {
        this.$emit('change', terms || '');
      },

      async handleReset () {
        this.$emit('reset');
      },
    },
  };

</script>
