import Module from '~/lib/vue/modules/module';
import company from './company';
import companies from './companies';
import datatable from './datatable';
import list from './list';
import record from './record';
import search from './search';
import notes from './notes';
import serviceAddressTerritories from './service-address-territories';
import billingAddressTerritories from './billing-address-territories';
import mailingAddressTerritories from './mailing-address-territories';
import addresses from './addresses';
import address from './address';
import companySearch from './company-search';

export default (services, namespace='customer') => {
  return Module.create({
    namespace,
    services,
    modules: {
      address,
      addresses,
      company,
      companies,
      companySearch,
      datatable,
      list,
      notes,
      record,
      search,
      serviceAddressTerritories,
      billingAddressTerritories,
      mailingAddressTerritories,
    },
  });
};
