export const contentRange = (header) => {
  if (!header) {
    return null;
  }

  if (header === 'objects none') {
    return {total: 0, range: [0, 0]};
  }

  const pattern = /^objects (\d+)-(\d+) of (\d+)$/;
  const tokens = header.match(pattern).filter(Boolean);

  if (!tokens || tokens.length < 4) {
    return null;
  }

  const integer = v => parseInt(v, 10);
  const total = integer(tokens[3]);
  const start = integer([1] - 1);
  const end = integer(tokens[2] - 1);

  return {total, range: [start, end]};
};

export default {
  contentRange,
};
