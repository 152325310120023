<template>

  <div class="card">
    <div class="card-content">
      <div class="level">
        <div class="level-left">
          Secondary Contact
        </div>
        <div class="level-right">
          <slot name="buttons"/>
        </div>
      </div>
      <div>
        <table class="table is-fullwidth is-size-6">
          <tbody>
            <tr>
              <td><label>Name </label></td>
              <td>{{ attributes.firstname }} {{ attributes.lastname }}</td>
            </tr>
            <tr>
              <td><label>Description</label></td>
              <td>{{ attributes.description }}</td>
            </tr>
            <tr>
              <td><label>Email</label></td>
              <td>{{ attributes.email || '-' }}</td>
            </tr>
            <tr>
              <td><label>Phone</label></td>
              <td>{{ attributes.tel || '-' }}</td>
            </tr>
            <tr>
              <td><label>Secondary phone</label></td>
              <td>{{ attributes.tel_secondary || '-' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</template>
<script>

  export default {

    props: {
      attributes: {
        required: true,
        type: Object,
        validator: (attrs) => {
          let keys = ['firstname', 'lastname', 'description', 'email', 'tel', 'tel_secondary'];
          return keys.reduce((result, k) => result && attrs.hasOwnProperty(k), true);
        },
      },
    },
  };

</script>
