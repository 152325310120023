<template>

  <div class="box">
    <slot name="list"></slot>
    <slot name="input"></slot>
  </div>

</template>
<script>

  export default {

  };

</script>
