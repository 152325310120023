import UploadModule from '~/lib/vue/modules/upload';

export default (services, namespace='upload') => {
  return UploadModule.create({
    namespace,
    services,
    api: services.get('api'),
    preprocessors: [
      
    ],
  });
};
