import NotesModule from '~/lib/vue/modules/notes';

/**
 * @param {~/lib.foundation.Container} services
 * @return {Object}
 */
export default (services, namespace='notes') => {

  return NotesModule.create({
    namespace,
    services,
    rel: 'sales',
    url_resolver: (subject, id) => {
      let url = `/customers/${subject}/notes`;
      return id ? `${url}/${id}` : url;
    },
  });
};
