import Module from './module';
import { parse } from '~/lib/jwt';
import * as Sentry from "@sentry/vue";

export class AuthModule extends Module {

  get defaultState () {
    return {
      token: null,
      user: null,
      keychain: null,
      error: null,
    };
  }

  get state () {
    return this.defaultState;
  }

  get getters () {
    return {
      token: (state) => {
        let {token} = state;
        if (token) {
          let payload = parse(token);
          if (payload) {
            let {exp} = payload;
            let now = Math.floor(new Date().getTime()/1000);
            if (now < exp) {
              return token;
            }
          }
        }
        return false;
      },
      user: (state) => {
        if (state.token && state.user) {
          return state.user;
        }
        return null;
      },
      keychain: (state) => state.keychain,
      error: (state) => state.error,
    };
  }

  get mutations () {
    return {
      token: (state, token) => state.token = token,
      user: (state, user) => state.user = {...user},
      keychain: (state, keychain) => state.keychain = {...keychain},
      error: (state, error) => state.error = error,
    };
  }

  get actions () {
    let api = this.services.get('api');
    let router = this.services.get('router');
    let storage = this.services.get('storage');

    return {

      initialize: async (store) => {
        const { user = null, token = null, keychain = null } = storage.get('auth') || {};
        Sentry.setUser(user);
        store.commit('user', user);
        token && store.commit('token', token);
        keychain && store.commit('keychain', keychain);
      },

      login: async (store, {email, password}) => {
        store.commit('error', null);

        try {
          let {ok, payload} = await api.post('/auth/login', {
            body: JSON.stringify({email, password}),
          });

          let {user, token, keychain} = payload;
          Sentry.setUser(user);
          store.commit('user', user);
          store.commit('token', token);
          store.commit('keychain', keychain);
          storage.set('auth', payload);
          router.replace({path: '/dashboard'});
          storage.remove('auth:dest');
        }
        catch (e) {
          console.error(e);
          Sentry.setUser(null);
          store.commit('error', 'Invalid credentials');
          storage.set('auth', null);
        }
      },

      logout: async (store) => {
        store.commit('user', null);
        store.commit('token', null);
        store.commit('keychain', null);
        store.commit('error', null);
        storage.remove('auth');
        router.push({path: '/'});
      },

      // dispatch a verification email message to
      verify: async (store, {email, dest}) => {
        await api.post('/auth/verify', {
          body: JSON.stringify({
            email,
            dest,
          }),
        });
      },

    };
  }

}

export default AuthModule;
