import foundation from '~/lib/foundation';
import rest from '~/lib/rest';
import events from '~/lib/events';
import P from '~/lib/promise';
import Vue from 'vue';
import Vuex from 'vuex';
import Notifications from 'vue-notification';
import { LRUCache } from 'lru-cache';
import Store from '~/store';
import router from '~/routing/router';
import routeGuard from '~/routing/guard';
import DataTables from '~/lib/vue/components/datatables';
import {Filesystem} from '~/lib/fs';
import {Clipboard} from '~/lib/clipboard';
import imaginary from '~/lib/imaginary';
import GmapVue from 'gmap-vue';
import Portal from 'portal-vue';
import { StorageWrapper } from '~/lib/storage';
import { bootSentry } from '~/lib/sentry';
import KonamiCode from 'vue-konami-code';

const debug = import.meta.env.NODE_ENV !== 'production';
const eventbus = new events.EventEmitter();

Vue.use(Vuex);
Vue.use(Notifications);
Vue.use(DataTables);
Vue.use(Portal);

Vue.use(GmapVue, {
  load: {
    key: import.meta.env.VITE_GOOGLE_MAPS_PUBLIC_API_KEY,
    libraries: 'places',
    v: '3.26',
  },
  installComponents: true,
});

Vue.use(KonamiCode, {
  callback: () => {
    eventbus.emit('konami');
  },
});

export default async () => {

  // application service container; available to all Vue and Vuex contexts
  let services = new foundation.Container();
  let noop = () => {};

  await P.all([
    services.set('api', () => {
      return new rest.Client({
        root: import.meta.env.VITE_API_ROOT,
        errors: true,
        authorization: () => {
          let grant = services.get('storage').get('auth');
          let {token} = (grant || {});
          return token ? `Bearer ${token}`: false;
        },
        unauthorized: () => {
          let {name, query} = router.currentRoute;
          let dest = {query};
          services.get('storage').set('auth:dest', dest);
          services.get('store').dispatch('auth/logout', null, {root: true});
        },
        fetchopts: {
          headers: {
            'Accept': 'application/json',
            'Accept-Language': 'en-US',
            'Content-Type': 'application/json',
          },
        },
      });
    }),
    services.set('storage', () => new StorageWrapper()),
    services.set('fs', () => new Filesystem()),
    services.set('cache', () => new LRUCache({ max: 1000, maxAge: 1_000 * 60 * 60 * 24 })),
    services.set('eventbus', () => eventbus),
    services.set('router', () => router),
    services.set('notifier', () => ({notify: Vue.prototype.$notify})),
  ]);

  await P.all([
    services.set('imaginary', () => {
      return new imaginary.Client({
        api: services.get('api'),
        url: '/imaginary',
      });
    }),
    services.set('clipboard', () => {
      let clipboard = new Clipboard({
        notifier: services.get('notifier'),
      });
      clipboard.watch(window.document);
      return clipboard;
    }),
  ]);

  await services.set('store', () => Store.create(services));

  router.beforeEach(routeGuard({
    storage: services.get('storage'),
    store: services.get('store'),
  }));

  if (debug) {
    window.services = services;
    window.store = services.get('store');
  }

  bootSentry(router);

  return services;
};
