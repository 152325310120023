<template>

  <div class="card">
    <div class="card-content">
      <div class="level">
        <div class="level-left">
          <p class="subtitle">
            {{ attributes.name }}
            <span class="tag">Business</span>
          </p>
        </div>
        <div class="level-right">
          <slot name="buttons"/>
        </div>
      </div>
      <div v-if="!compact">
        <table class="table is-fullwidth is-size-6">
          <tbody>
            <tr>
              <td><label>Representative</label></td>
              <td>{{ attributes.firstname }} {{ attributes.lastname}}</td>
            </tr>
            <tr>
              <td><label>Email</label></td>
              <td>{{ attributes.email || '-' }}</td>
            </tr>
            <tr>
              <td><label>Phone</label></td>
              <td>{{ attributes.tel || '-' }}</td>
            </tr>
            <tr>
              <td><label>Secondary Phone</label></td>
              <td>{{ attributes.tel_secondary || '-' }}</td>
            </tr>
            <tr>
              <td><label>Service Address</label></td>
              <td v-html="serviceAddress"/>
            </tr>
            <tr v-if="billingAddress">
              <td><label>Billing Address</label></td>
              <td v-html="billingAddress"/>
            </tr>
            <tr v-if="mailingAddress">
              <td><label>Mailing Address</label></td>
              <td v-html="mailingAddress"/>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</template>
<script>

  import CustomerBase from './CustomerBase.vue';
  import ObjectTag from '~/components/page/ObjectTag.vue';
  import Dropdown from '~/components/page/Dropdown.vue';
  import CustomerDropdownMenu from '~/components/customer/CustomerDropdownMenu.vue';
  import format from '~/lib/domain/format';
  import html from '~/lib/html';

  export default {
    mixins: [CustomerBase],
    props: {
      dropdown: {
        required: false,
        type: Boolean,
        default: true,
      },
      compact: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        cachedAddresses: {},
      };
    },
    components: {
      ObjectTag,
      Dropdown,
      CustomerDropdownMenu,
    },
    computed: {
      serviceAddress () {
        return this.createAddress('service');
      },
      billingAddress () {
        let addrstr = this.createAddress('billing');
        return addrstr;
      },
      mailingAddress () {
        let addrstr = this.createAddress('mailing');
        return addrstr;
      },
    },
    methods: {
      createAddress (type) {
        let {addresses} = this.attributes;
        let address = addresses[type];
        if (address) {
          let lines = format.address(address);
          if (lines.length) {
            return lines.map(html.encode).join('<br>');
          }
        }
        return null;
      },
    },
  };

</script>
