<template>

  <dropdown class="is-pulled-right is-right" style="margin-top: 1.5rem; margin-right: 1.5rem;">
    <div class="dropdown-content">
      <a
        class="dropdown-item"
        @click="$emit('edit')"
      >
        <span class="icon"><i class="fa fa-edit" /></span>
        Edit
      </a>
    </div>
  </dropdown>

 </div>

</template>
<script>

  import CustomerBase from './CustomerBase.vue';
  import Dropdown from '~/components/page/Dropdown.vue';

  export default {
    name: 'customer-dropdown-menu',
    mixins: [CustomerBase],
    components: {Dropdown},
  };

</script>
