import DataTableModule from '~/lib/vue/modules/datatable';
import rest from '~/lib/rest';
import constants from '~/lib/domain/constants';
import date from '~/lib/date';

/**
 * @param {~/lib.foundation.Container} services
 * @return {Object}
 */
export default (services, namespace='datatable') => {

  return DataTableModule.create({
    namespace,
    services,
    url: '/inquiries',
    columns: [
      {
        label: 'Received',
        value: row => {
          let now = Date.now();
          let timestamp = Date.parse(row.created_at);
          let seconds = (timestamp - now) / 1000;
          return date.duration(seconds, 'seconds').humanize(true);
        },
      },
      {
        label: 'Name',
        value: row => {
          let {name, company} = row;
          return company ? `${name} @ ${company}` : name;
        },
      },
    ],
  });
};
