<template>
  <form @submit="handleSubmit" @change="onChangeAttribute" novalidate>

    <div class="field is-horizontal">
      <div class="field-label">
        <label class="label" for="email">Property Address</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <textarea id="address" data-attr="address" class="textarea" :class="{'is-danger': errors.address}" :value="attributes.address" rows="3" placeholder="Street address"/>
          </div>
          <p class="help is-error" v-if="errors.address">
            {{ errors.address }}
          </p>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label">
        <label class="label" for="province_id"></label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input id="city" data-attr="city" class="input" :class="{'is-danger': errors.city}" type="text" :value="attributes.city" placeholder="City">
          </div>
          <p class="help is-error" v-if="errors.city">
            {{ errors.city }}
          </p>
        </div>
        <div class="field">
          <div class="control">
            <div class="select">
              <select @change="onChangeProvince" :class="{'is-danger': errors.province_id}">
                <option v-for="taxon in territories.provinces" :value="taxon.id" :selected="taxon.id === territories.province_id">{{ taxon.name }}</option>
              </select>
            </div>
          </div>
          <p class="help is-error" v-if="errors.province_id">
            {{ errors.province_id }}
          </p>
        </div>
        <div class="field">
          <div class="control">
            <input data-attr="postcode" class="input" :class="{'is-danger': errors.postcode}" type="text" :value="attributes.postcode" placeholder="Postal code">
          </div>
          <p class="help is-error" v-if="errors.postcode">
            {{ errors.postcode }}
          </p>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="firstname">Name</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input id="name" data-attr="name" class="input" :class="{'is-danger': errors.name}" type="text" :value="attributes.name" placeholder="Name of property">
          </div>
          <p class="help is-error" v-if="errors.firstname">
            {{ errors.name }}
          </p>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label">
        <label class="label" for="email">Contact Information</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input id="email" data-attr="email" class="input" :class="{'is-danger': errors.email}" type="email" :value="attributes.email" placeholder="Email">
          </div>
          <p class="help is-error" v-if="errors.email">
            {{ errors.email}}
          </p>
        </div>
        <div class="field">
          <div class="control">
            <input
              id="tel"
              data-attr="tel"
              class="input"
              :class="{'is-danger': errors.tel}"
              type="tel"
              :value="attributes.tel"
              placeholder="Phone"
              @change="prependDefaultCountryCode"
            />
          </div>
          <p class="help is-error" v-if="errors.tel">
            {{ errors.tel }}
          </p>
        </div>
        <div class="field">
          <div class="control">
            <input
              id="fax"
              data-attr="fax"
              class="input"
              :class="{'is-danger': errors.fax}"
              type="tel"
              :value="attributes.fax"
              placeholder="Fax"
              @change="prependDefaultCountryCode"
            />
          </div>
          <p class="help is-error" v-if="errors.fax">
            {{ errors.fax }}
          </p>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label">
        <label class="label"></label>
      </div>
      <div class="field-body">
        <div class="field">
          <span class="control">
            <btn type="submit" @click="handleSubmit">
              <icon slot="icon" :classes="['fa', 'fa-save']"/>
              Commit
            </btn>
          </span>
          <span class="control">
            <btn type="submit" @click="handleCancelButton">
              <icon slot="icon" :classes="['fa', 'fa-ban']"/>
              Cancel
            </btn>
          </span>
        </div>
      </div>
    </div>
    </div>

  </form>
</template>

<script>
  import Btn from '~/components/Btn.vue';
  import Icon from '~/components/Icon.vue';

  export default {
    name: 'property-form',

    components: {
      Btn,
      Icon,
    },

    props: {
      attributes: {
        type: Object,
        required: true,
      },
      errors: {
        type: Object,
        default: () => ({}),
      },
      territories: {
        type: Object,
        required: true,
        validator: (v) => {
          let {countries, provinces, province_id, country_id} = v;
          return Array.isArray(countries) && Array.isArray(provinces);
        },
      },
    },

    methods: {

      async onChangeAttribute (event) {
        let {dataset, value} = event.target;
        let {attr} = dataset;
        this.$emit('onChangeAttribute', {[attr]: value});
      },

      async handleSubmit (event) {
        event && event.preventDefault();
        this.$emit('onSubmit');
      },

      async handleCancelButton (event) {
        event && event.preventDefault();
        this.$emit('onCancel');
      },

      async onChangeCountry (event) {
        this.$emit('onChangeCountry', event.target.value);
      },

      async onChangeProvince (event) {
        this.$emit('onChangeProvince', event.target.value);
      },

      async prependDefaultCountryCode (event) {
        let {name, value} = event.target;
        if (value && !/^\+\d+\s/.test(value)) {
          event.target.value = `+1 ${value}`;
        }
      },

    },

  };

</script>
