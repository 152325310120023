import constants from '~/lib/domain/constants';

export const customer = {};

customer.line = (attributes) => {
  let line = '';
  if (attributes) {
    let {type, name, firstname, lastname, company} = attributes;
    if (type === constants.CUSTOMER_TYPE_COMPANY) {
      return name;
    }
    line = `${firstname} ${lastname}`;
    if (company) {
      line = `${line} (${company.name})`;
    }
  }
  return line;
};

/**
 * @param {Object} attributes
 * @return String[]
 */
customer.summary = (attributes) => {
  let {firstname, lastname, company, email, phone, fax, address, city, province, country, postcode} = attributes;
  let lines = [];
  let push = v => lines.push(v);
  push(`${firstname} ${lastname}`);
  company && push(company.name);
  address && address.split('\n').forEach(push);
  city && province && push(`${city}, ${province.abbrev} ${postcode}`);
  country && country.id !== constants.DEFAULT_TAXON_COUNTRY && push(country.name);
  return lines;
};

/**
 * @param {Object} attributes
 * @return String[]
 */
customer.summary.extended = (attributes) => {
  let {tel, tel_secondary, fax, email} = attributes;
  let lines = customer.summary(attributes);
  let push = v => lines.push(v);
  tel && lines.push(`Tel: ${tel}`);
  tel_secondary && lines.push(`Tel: ${tel_secondary}`);
  fax && lines.push(`Fax: ${fax}`);
  email && lines.push(`Email: ${email}`);
  return lines;
};

export const address = (attributes) => {
  let {address, city, province, country, postcode} = attributes;
  let lines = [];
  let push = v => lines.push(v);
  let line;
  address && address.split('\n').forEach(push);

  if (city && province) {
    line = `${city}, ${province.abbrev}`;
    if (postcode) {
      line = `${line} ${postcode}`;
    }
    push(line);
  }

  country && country.id !== constants.DEFAULT_TAXON_COUNTRY && push(country.name);
  return lines;
};

address.line = (attributes) => {
  let line = '';
  if (attributes) {
    let {name, address, city} = attributes;
    line = `${address.split(/\n+/).shift()}, ${city}`;
    if (name) {
      line = `${name} at ${line}`;
    }
  }
  return line;
};

export default {
  customer,
  address,
};
