<template>

  <page>

    <object-tag
      slot="page-title"
      type="Inquiry"
      :identifier="guessInquiryIdFromRoute()"
    />

    <div slot="page-buttons">
    </div>

    <div class="columns">
      <div class="column">
        <inquiry-card :attributes="inquiry.attributes">
          <div slot="buttons">
            <btn @click="handleDeleteInquiry" :flat="true" tooltip="Delete inquiry">
              <icon :classes="['fa', 'fa-trash']"/>
            </btn>
          </div>
        </inquiry-card>
      </div>
    </div>

  </page>
</template>

<script>
  import {mapState, mapGetters, mapActions} from 'vuex';
  import P from '~/lib/promise';
  import ObjectTag from '~/components/page/ObjectTag.vue';
  import InquiryContext from './mixins/InquiryContext.vue';
  import InquiryCard from '~/components/inquiry/InquiryCard.vue';
  import Page from '~/components/Page.vue';
  import Icon from '~/components/Icon.vue';
  import Btn from '~/components/Btn.vue';

  export default {
    mixins: [InquiryContext],

    components: {
      Btn,
      Icon,
      InquiryCard,
      ObjectTag,
      Page,
    },

    async mounted () {
      this.initializeInquiryContext();
    },
  };
</script>
