import Module from './module';
import exceptions from '~/lib/exceptions';
import rest from '~/lib/rest';
import lang from '~/lib/lang';
import {NullValidator} from '~/lib/domain/validation';

export class DataRecordModule extends Module {

  get hooks () {
    let noop = async () => {};
    return {
      afterInitialize: this.option('afterInitialize', noop),
      beforeSave: this.option('beforeSave', noop),
      afterSave: this.option('afterSave', noop),
      afterLoad: this.option('afterLoad', noop),
      afterDelete: this.option('afterDelete', noop),
      onValidationErrors: this.option('onValidationErrors', noop),
    };
  }

  get type () {
    return this.option('type');
  }

  get validator () {
    return new (this.option('validator', NullValidator));
  }

  /**
   * URL of the REST collection resource
   * @readonly
   * @property
   * @type {String}
   */
  get defaultResourcePath () {
    return this.option('resource', `/${this.type}/s`);
  }

  /**
   * Default attributes
   * @readonly
   * @property
   * @type {Object}
   */
  get defaultAttributes () {
    return {...this.option('attributes')};
  }

  /**
   * Default form attributes
   * @readonly
   * @property
   * @type {Object}
   */
  get defaultForm () {
    return {...this.option('form')};
  }

  /**
   * Function that maps record attributes to initial form values
   * Passthrough function by default
   * @readonly
   * @property
   * @type {Function}
   */
  map (attributes) {
    let passthru = attributes => attributes;
    return this.option('map', passthru)(lang.object.clone(attributes));
  }

  /**
   * Name of primary key attribute; "id" by default
   * @readonly
   * @property
   * @type {String}
   */
  get pk () {
    return this.option('pk', 'id');
  }

  /**
   * Human-readable string that describes the kind of record; for example,
   * "Customer", "Property".
   * @param {Object} payload
   * @return
   */
  describe (payload) {
    return this.option('describe')(payload);
  }

  /**
   * Notification payload factories
   * @readonly
   * @property
   * @type {Object}
   */
  get notifications () {
    let desc = (payload) => this.describe(payload);
    return {
      created: payload => ({type: 'success', title: 'Record saved', text: desc(payload)}),
      deleted: payload => ({type: 'warning', title: 'Record deleted', text: desc(payload)}),
      saved: payload => ({type: 'success', title: 'Record saved', text: desc(payload)}),
      failed: (e) => ({type: 'error', title: 'Oh snap! Something went wrong.', text: `HTTP ${e.response.status}; ${e.message}`}),
    };
  }

  get customActions () {
    return this.option('actions', {});
  }

  /**
   * @inheritdoc
   */
  get state () {
    return {
      attributes: this.defaultAttributes,
      form: this.defaultForm,
      errors: {},
      resource: this.defaultResourcePath,
      includes: [],
    };
  }

  /**
   * @inheritdoc
   */
  get getters () {
    return {
      ...this.option('getters', {}),
      attributes: state => state.attributes,
      form: state => state.form,
      errors: state => state.errors,
      urls: (state) => {
        let {resource, attributes} = state;
        let {id} = attributes;
        return {
          attachments: id ? `${resource}/${id}/attachments` : null,
        };
      }
    };
  }

  /**
   * @inheritdoc
   */
  get mutations () {
    return {
      attributes: (state, attributes) => state.attributes = attributes,
      form: (state, form) => state.form = form,
      errors: (state, errors) => state.errors = errors,
      resource: (state, resource) => state.resource = resource,
      includes: (state, includes) => state.includes = includes,
    };
  }

  /**
   * @inheritdoc
   */
  get actions () {
    return {
      ...this.customActions,

      /**
       * Lifecycle moment
       * @async
       * @param {vuex:Store} store
       * @return {Boolean}
       */
      initialize: async (store, {resource}={}) => {
        store.commit('attributes', this.defaultAttributes);
        store.dispatch('resetForm');
        store.commit('errors', {});
        resource !== undefined && store.commit('resource', resource);
        let {afterInitialize} = this.hooks;
        await afterInitialize(store);
      },

      /**
       * Load the record by its primary key. Return true if operation
       * succeeds.
       * @async
       * @param {vuex:Store} store
       * @param {Object} params
       * @param {String} params.id
       * @param {String[]} params.includes
       * @return {Boolean}
       */
      load: async (store, {id, includes}) => {
        this.beforeRequest(store);

        if (!id) {
          throw new exceptions.InvalidArgument('expected id parameter');
        }

        let {resource} = store.state;
        let url = `${resource}/${id}`;

        if (Array.isArray(includes) && includes.length) {
          store.commit('includes', includes);
          let query = new rest.Query({includes});
          let qs = String(query);
          url = `${url}?${qs}`;
        }

        let api = this.services.get('api');
        let response;
        let e;

        try {
          response = await api.get(url);
        }
        catch (e) {
          response = e.response;
        }

        let ok = await this.handleReadResponse(store, response, e);
        let {afterLoad} = this.hooks;

        if (ok) {
          await afterLoad.call(this, store);
        }

        return ok;
      },

      reload: async (store) => {
        let {attributes, includes} = store.state;
        let {id} = attributes;
        if (id) {
          return await store.dispatch('load', {id, includes});
        }
      },

      /**
       * Persist the record. Return true if operation succeeds.
       * succeeds.
       * @async
       * @param {vuex:Store} store
       * @return {Boolean}
       */
      save: async (store) => {
        let {beforeSave, afterSave} = this.hooks;

        beforeSave.call(this, store);

        this.beforeRequest(store);

        let {pk} = this;
        let attributes = {...store.state.attributes};
        let id = attributes[pk];
        let method = id ? 'put' : 'post';

        let {resource} = store.state;
        let url = id ? `${resource}/${id}` : resource;
        let api = this.services.get('api');

        if (method === 'put') {
          delete attributes[pk]
        }

        // trigger a marshalling operation
        store.dispatch('assignFormAttributes', {});

        let {form} = store.state;
        let body = JSON.stringify(form);
        let response;
        let e = null;

        try {
          response = await api[method](url, {body});
        }
        catch (exception) {
          e = exception;
          response = e.response;
        }

        let ok = await this.handleWriteResponse(store, response, e);
        if (ok) {
          await afterSave.call(this, store);
        }

        return ok;
      },

      /**
       * Delete the record. Return true if operation succeeds.
       * succeeds.
       * @async
       * @param {vuex:Store} store
       * @return {Boolean}
       */
      delete: async (store) => {
        this.beforeRequest(store);
        let {pk} = this;
        let {attributes} = store.state;
        let id = attributes[pk];

        if (!id) {
          throw new exceptions.InvalidState('cannot delete non-persistent record');
        }

        let api = this.services.get('api');
        let {resource} = store.state;
        let url = `${resource}/${id}`;
        let response;
        let e = null;
        let {afterDelete} = this.hooks;

        try {
          response = await api.delete(url);
          await afterDelete(store);
        }
        catch (exception) {
          e = exception;
          response = e.response;
        }

        return await this.handleDeleteResponse(store, response, e);
      },

      /**
       * Assign (i.e., merge) attributes into the record.
       * @param {vuex:Store} store
       * @param {Object} attributes
       * @return {Boolean}
       */
      assign: (store, attributes) => {
        let assignments = {...store.state.attributes, ...attributes};
        store.commit('attributes', assignments);
        let form = this.map(assignments);
        let {validator} = this;
        validator.validate(form);
        store.dispatch('assignFormAttributes', form);
      },

      /**
       * Stage form attributes in preparation for save()
       */
      assignFormAttributes: (store, attributes) => {
        let {errors} = store.state;
        let assignments = {};

        // only assign enumerable keys that exist in default form
        Object.keys(this.defaultForm).forEach((key) => {
          if (attributes.hasOwnProperty(key)) {
            delete errors[key];
            assignments[key] = attributes[key];
          }
        });

        let form = {...store.state.form, ...assignments};
        let marshal = this.option('marshal', (s, a) => a);

        store.commit('form', marshal(store, form));
        store.commit('errors', {...errors});
      },

      resetForm: (store) => {
        let {attributes} = store.state;
        store.dispatch('assignFormAttributes', this.map(attributes));
        store.commit('errors', {});
      },

      attach: async (store, {rel, sum, params}) => {
        let api = this.services.get('api');
        let id = store.state.attributes[this.pk];

        if (!id) {
          throw new exceptions.InvalidState('cannot attach to non-persistent record');
        }
        if (!rel || !sum) {
          throw new exceptions.InvalidArgument('rel and sum parameters are required');
        }

        let {resource} = store.state;
        let url = `${resource}/${id}/attachments/${rel}/${sum}`;
        let body = JSON.stringify(params || {});
        let response = await api.put(url, {body});

        if (!response.ok) {
          let {notifications} = this;
          let notifier = this.services.get('notifier');
          notifier.notify(notifications.failed(response.payload.message));
          return;
        }

        await store.dispatch('reload');
      },

      detach: async (store, {rel, sum}) => {
        let api = this.services.get('api');
        let id = store.state.attributes[this.pk];

        if (!id) {
          throw new exceptions.InvalidState('cannot attach to non-persistent record');
        }

        let {resource} = store.state;
        let url = `${resource}/${id}/attachments/${rel}/${sum}`;

        let response = await api.delete(url);

        if (!response.ok) {
          let {notifications} = this;
          let notifier = this.services.get('notifier');
          notifier.notify(notifications.failed(response.payload.message));
          return;
        }

        await store.dispatch('reload');
      },

      detachAll: async (store, {rel}) => {
        let api = this.services.get('api');
        let id = store.state.attributes[this.pk];

        if (!id) {
          throw new exceptions.InvalidState('cannot attach to non-persistent record');
        }

        let {resource} = store.state;
        let url = `${resource}/${id}/attachments/${rel}`;

        let response = await api.delete(url);

        if (!response.ok) {
          let {notifications} = this;
          let notifier = this.services.get('notifier');
          notifier.notify(notifications.failed(response.payload.message));
          return;
        }

        await store.dispatch('reload');
      },
    };
  }

  /**
   * Lifecycle moment
   * @param {vuex:Store} store
   * @return void
   */
  beforeRequest (store) {
    store.commit('errors', {});
  }

  /**
   * @param {vuex:Store} store
   * @param {~/lib.rest:Response} response
   * @param {Error} e
   * @return {Boolean}
   */
  handleReadResponse (store, response, e=null) {
    let notifier = this.services.get('notifier');
    let {notifications} = this;
    let {ok, status, payload} = response;

    if (!ok) {
      notifier.notify(notifications.failed(e));
    }
    else {
      payload && store.commit('attributes', payload);
      store.dispatch('assignFormAttributes', this.map(payload));
    }

    return ok;
  }

  /**
   * @param {vuex:Store} store
   * @param {~/lib.rest:Response} response
   * @param {Error} e
   * @return {Boolean}
   */
  handleWriteResponse (store, response, e=null) {
    let notifier = this.services.get('notifier');
    let {notifications} = this;
    let {ok, status, payload} = response;
    let {onValidationErrors} = this.hooks;

    if (!ok) {
      if (payload && payload.errors) {
        store.commit('errors', payload.errors);
        onValidationErrors(store, payload.errors);
      }
      else {
        notifier.notify(notifications.failed(e));
      }
    }
    else {
      store.commit('attributes', JSON.parse(JSON.stringify(payload)));
      store.dispatch('assignFormAttributes', this.map(payload));
      if (status === 201) {
        notifier.notify(notifications.created(payload));
      }
      else if (status === 200) {
        notifier.notify(notifications.saved(payload));
      }
    }

    return ok;
  }

  /**
   * @param {vuex:Store} store
   * @param {~/lib.rest:Response} response
   * @param {Error} e
   * @return {Boolean}
   */
  handleDeleteResponse (store, response, e=null) {
    let notifier = this.services.get('notifier');
    let {notifications} = this;
    let {ok, status, payload} = response;

    if (!ok) {
      notifier.notify(notifications.failed(e));
    }
    else {
      notifier.notify(notifications.deleted({...store.state.attributes}));
      store.dispatch('initialize');
    }

    return ok;
  }

}

export default DataRecordModule;
