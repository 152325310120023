import {Component} from '~/lib/foundation';
import lang from '~/lib/lang';
const {each} = lang.collection;

/**
 * Convert a an object to an ordered list of [key, value] tuples.
 * @example
 *
 *  itemize({foo: 1, bar: 'two'});
 *  >>> [['bar', 'two'], ['foo', 1]];
 *
 * @param {Object} obj
 * @param {Boolean} sort
 * @return {Array[]}
 */
const itemize = (obj, sort=true) => {
  const items = Object.keys(obj).map(key => [key, obj[key]]);
  return sort ? items.sort() : items;
};

/**
 * Sort query grammar abstraction
 */
export class SortBuilder extends Component {

  get defaults () {
    return {
      ...super.defaults,
      sep: ',', // token separator
    };
  }

  constructor (sorts={}, ...args) {
    super(...args);
    this.separator = this.option('sep');
    this.initialSorts = {...sorts};
    this.reset();
  }

  reset () {
    this.sorts = {...this.initialSorts};
    return this;
  }

  asc (key) {
    return this.add(key, 'asc');
  }

  desc (key) {
    return this.add(key, 'desc');
  }

  add (key, dir) {
    this.assertValidDirection(dir);
    this.sorts[key] = dir;
    return this;
  }

  toString () {
    const tokens = itemize(this.sorts).map(([key, dir]) => `${key}:${dir}`);
    return tokens.join(this.separator);
  }

  assertValidDirection (dir) {
    const ok = ['asc', 'desc'].indexOf(dir) > -1;
    if (!ok) {
      throw new Error(`invalid sort direction: ${dir}`);
    }
  }
}

/**
 * Filter query grammar abstraction
 */
export class FilterBuilder extends Component {

  get defaults () {
    return {
      ...super.defaults,
      esep: ';',
      psep: ',',
    };
  }

  constructor (filters={}, ...args) {
    super(...args);
    this.initialFilters = {...filters};
    this.reset();
  }

  reset () {
    this.filters = {...this.initialFilters};
    return this;
  }

  add (key, params) {
    this.filters[key] = params;
    return this;
  }

  toString () {
    let psep = this.option('psep');
    let esep = this.option('esep');

    let tokens = itemize(this.filters).map(([key, params]) => {
      let trim = str => String(str).trim();

      let paramstr;

      if (Array.isArray(params)) {
        paramstr = `[${params.map(trim).join(psep)}]`;
      }
      else {
        paramstr = String(params);
      }
      return `${key}:${paramstr}`;
    });

    return tokens.join(esep);
  }
}


/**
 * Paging query grammar abstraction
 */
export class PagingBuilder extends Component {

  constructor ({offset, limit}, ...args) {
    super(...args);
    this.initialOffset = offset || 0;
    this.initialLimit = limit || 10;
    this.reset();
  }

  reset () {
    this._offset = this.initialOffset;
    this._limit = this.initialLimit;
    return this;
  }

  offset (n) {
    this._offset = n;
    return this;
  }

  limit (n) {
    this._limit = n;
    return this;
  }

  items () {
    const limit = this._limit;
    const offset = this._offset;
    const str = String;
    const filter = ([k, v]) => Boolean(v);
    const map = ([k, v]) => [str(k), str(v)];

    return itemize({ limit, offset }).filter(filter).map(map);
  }
}

/**
 * Generic token container
 */
export class TokenBuilder extends Component {

  get defaults () {
    return {
      ...super.defaults,
      sep: ',',
    };
  }

  constructor (tokens=[], ...args) {
    super(...args);
    this.initialTokens = [...tokens];
    this.reset();
  }

  reset () {
    this.tokens = this.initialTokens;
    return this;
  }

  add (token) {
    this.tokens.indexOf(token) < 0 && this.tokens.push(token);
    return this;
  }

  toString () {
    let tokens = this.tokens.slice(0);
    return tokens.sort().join(this.option('sep'));
  }
}

/**
 * REST API query builder
 * @example
 *
 *  let query = new Query();
 *
 *  query.sort.asc('foo').desc('bar');
 *  query.filters.add('match_categories', ['a', 'b']).add('match_tags', ['c', 'd']);
 *  query.paging.offset(0).limit(25);
 *  query.includes.add('taxons').add('attachments');
 *
 *  let expected_items = [
 *    ['filter', 'match_categories:a,b;match_tags:c,d'],
 *    ['include', 'attachments,taxons'],
 *    ['limit', '25'],
 *    ['sort', 'bar:desc,foo:asc'],
 *  ];
 *
 *  let items = query.items();
 *
 *  expect(JSON.stringify(items)).to.equal(JSON.stringify(expected_items));
 *
 *  let expected_qs = 'filter=match_categories%3Aa%2Cb%3Bmatch_tags%3Ac%2Cd&include=attachments%2Ctaxons&limit=25&sort=bar%3Adesc%2Cfoo%3Aasc';
 *
 *  expect(Query.stringify(items)).to.equal(expected_qs);
 *  expect(query.toString()).to.equal(expected_qs);
 */
export class Query extends Component {

  static stringify (items) {
    const enc = window.encodeURIComponent;
    const tokens = items.map(([k, v]) => `${enc(k)}=${enc(v)}`);
    return tokens.join('&');
  }

  get defaults () {
    return {
      sorts: {},
      filters: {},
      includes: [],
      paging: {
        offset: 0,
        limit: 10,
      },
    };
  }

  constructor (...args) {
    super(...args);

    this.sort = new SortBuilder(this.option('sorts'));
    this.filters = new FilterBuilder(this.option('filters'));
    this.includes = new TokenBuilder(this.option('includes'));
    this.paging = new PagingBuilder(this.option('paging'));
  }

  items () {
    const items = itemize({
      sort: this.sort.toString(),
      filter: this.filters.toString(),
      include: this.includes.toString(),
    });

    const map = ([k, v]) => (k && v ? [k, v] : null);

    return items.concat(this.paging.items()).sort().map(map).filter(Boolean);
  }

  toString () {
    return Query.stringify(this.items());
  }
}

export default Query;
