<template>
  <page>

    <modal
      :open="modalOpen"
      @click="closeModal"
    >
      <div class="box" slot="body">
        <object-tag
          slot="header"
          type="Property" identifier="new"
        />
        <property-form
          ref="form"
          :attributes="property.form"
          :errors="property.errors"
          :territories="propertyTerritories"
          @onSubmit="commitPropertyEdit"
          @onCancel="cancelPropertyEdit"
          @onChangeAttribute="assignPropertyFormAttributes"
          @onChangeCountry="setPropertyCountry"
          @onChangeProvince="setPropertyProvince"
        />
      </div>
    </modal>

    <object-tag slot="page-title" type="Properties"/>

    <term-search-form
      slot="page-search"
      :terms="propertySearch.terms"
      :results="propertySearch.results"
      :error="propertySearch.error"
      :progress="propertySearch.progress"
      :valid="propertySearch.valid"
      :placeholder="propertySearch.placeholder"
      @change="handlePropertySearchTerm"
      @reset="resetPropertySearch"
    >
      <property-search-results
        slot="results"
        :valid="propertySearch.valid"
        :records="propertySearch.results"
        @choose="navigateToProperty"
      />
    </term-search-form>

    <div slot="page-buttons">
      <btn @click="openModal">
        <icon slot="icon" :classes="['fa', 'fa-plus-circle']" />
        New Property
      </btn>
    </div>

    <data-table
      class="table"
      :columns="propertyTable.columns"
      :rows="propertyTable.rows"
      :total="propertyTable.total"
      :limit="propertyTable.limit"
      @onClickRow="navigateToProperty"
    >
      <data-pager
        slot="footer"
        :total="propertyTable.total"
        :limit="propertyTable.limit"
        :page="propertyTable.page"
        @onChangePage="setPropertyTablePage"
        @onChangeLimit="setPropertyTableLimit"
      />
    </data-table>

  </page>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import {DataTable, DataPager} from '~/lib/vue/components/datatables';
  import {TermSearchForm} from '~/lib/vue/components/search';
  import PropertySearchResults from '~/components/property/PropertySearchResults.vue';
  import ObjectTag from '~/components/page/ObjectTag.vue';
  import PropertyContext from './mixins/PropertyContext.vue';
  import PropertyForm from '~/components/property/PropertyForm.vue';
  import Modal from '~/components/Modal.vue';
  import ModalContext from './mixins/ModalContext.vue';
  import Page from '~/components/Page.vue';
  import Icon from '~/components/Icon.vue';
  import Btn from '~/components/Btn.vue';

  export default {
    mixins: [PropertyContext, ModalContext],

    components: {
      DataTable,
      DataPager,
      TermSearchForm,
      PropertySearchResults,
      ObjectTag,
      PropertyForm,
      Modal,
      Page,
      Btn,
      Icon,
    },

    methods: {
      async onBeforeOpenModal () {
        this.initializeProperty();
      },
    },

    mounted () {
      (async () => {
        await this.initializePropertyContext();
        await this.loadPropertyTable();
      })();
    },
  };

</script>
