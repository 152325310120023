import Module from '~/lib/vue/modules/module';
import attachments from './attachments';
import datatable from './datatable';
import list from './list';
import notes from './notes';
import record from './record';
import search from './search';

export default (services, namespace='job') => {
  return Module.create({
    namespace,
    services,
    modules: {
      attachments,
      datatable,
      list,
      notes,
      record,
      search,
    },
  });
};
