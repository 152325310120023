<template>
  <div class="data-pager">
    <div class="columns">
      <div class="column">
        <div class="field">
          <div class="select">
            <div class="control">
              <select name="limit" autocomplete="off" @change="onChangeLimit" v-bind:value="limit">
                <option v-for="limit in (limits || defaults.limits)" v-bind:value="limit">{{ limit }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <div class="range-indicator has-text-centered" style="margin-top: 3px;">
            {{ messages ? messages.limit : defaults.messages.rows }}
            <span class="start">{{ range.start }}</span> - <span class="end">{{ range.end }}</span>
            of {{ total }}
          </div>
        </div>
      </div>
      <div class="column">
        <div class="is-pulled-right" v-if="pages.length > 1">
          <form @submit="onSubmit">
            <div class="field has-addons">
              <label class="label">Page</label>
              <div class="control">
                <button
                  type="submit"
                  class="button previous"
                  :disabled="!previousButtonEnabled"
                  @click="onClickPreviousButton"
                >
                  <span class="icon"><i class="fa fa-angle-left" /></span>
                  {{ messages ? messages.previous : defaults.messages.previous }}
                </button>
              </div>
              <div class="control">
                <input type="text" class="input" v-bind:value="page" @change="onChangePageInput" style="width: 3em; text-align: right;">
              </div>
              <div class="control">
                <button
                  type="submit"
                  class="button"
                  :disabled="!nextButtonEnabled"
                  @click="onClickNextButton"
                >
                  <span class="icon"><i class="fa fa-angle-right" /></span>
                  {{ messages ? messages.next : defaults.messages.next }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import lang from '~/lib/lang';

  export default {
    name: 'data-pager',

    props: {
      total: Number,
      limit: Number,
      page: Number,
      limits: {
        type: Array,
        validator (limits) {
          let reducer = (valid, limit) => {
            return valid ? Number.isInteger(limit) && limit > 0 : false;
          };
          return limits.reduce(reducer, true);
        },
      },
      messages: Object,
    },

    data () {
      return {
        selectedPage: this.page,
        selectedLimit: this.limit,
        defaults: {
          limits: [10, 25, 50, 100],
          limit: 10,
          messages: {
            rows: 'Displaying records ',
            previous: '',
            next: '',
            limit: 'Records per page',
          },
        },
      };
    },

    computed: {
      pages () {
        let max = Math.ceil(this.total / this.limit);
        return lang.array.range(1, max);
      },
      range () {
        let {total, limit, page} = this;
        let start = ((page - 1) * limit) + 1;
        let end = start + limit - 1;
        if (end > total) {
          end = total;
        }
        return {start, end};
      },
      nextButtonEnabled () {
        return this.page < this.pages.slice(0).pop();
      },
      previousButtonEnabled () {
        return this.page > this.pages.slice(0).shift();
      },
    },

    methods: {
      onClickPreviousButton () {
        this.onChangePage(this.page - 1);
      },
      onClickNextButton () {
        this.onChangePage(this.page + 1);
      },
      onChangePage (page) {
        this.$emit('onChangePage', page);
      },
      onChangePageInput (event) {
        this.onChangePage(event.target.value);
      },
      onChangeLimit (event) {
        let limit = event.target.value
        this.$emit('onChangeLimit', event.target.value);
      },
      onSubmit (event) {
        event.preventDefault();
        event.stopPropagation();
      },
    },

  };
</script>
