import Module from './module';
import exceptions from '~/lib/exceptions';

export class AttachmentsModule extends Module {

  get defaultState () {
    return {
      root: null,
      attachments: [],
    };
  }

  get state () {
    return this.defaultState;
  }

  get getters () {
    return {
      root: ({root}) => root,
      attachments: state => state.attachments,
    };
  }

  get mutations () {
    return {
      root: (state, root) => state.root = root,
      attachments: (state, attachments) => {
        state.attachments = [...(attachments || [])];
      },
    };
  }

  get actions () {
    let api = this.services.get('api');

    return {

      initialize: async (store) => {
        let {root, attachments} = this.defaultState;
        store.commit('root', root);
        store.commit('attachments', attachments);
      },

      setRootUrl : (store, root) => {
        store.commit('root', root);
      },

      receiveAttachments: (store, attachments) => {
        store.commit('attachments', attachments);
      },

      updateAttachment: async (store, {index, attributes}) => {
        let attachments = [...store.state.attachments];
        if (!attachments.hasOwnProperty(index)) {
          throw new exceptions.KeyError('array index not found');
        }
        let attachment = {...attachments[index], ...attributes};
        attachments.splice(index, 1, attachment);
        store.commit('attachments', attachments);

        let {root} = store.state;
        let {rel, blob} = attributes;
        let url = `${root}/${rel}/${blob.sum}`;

        let params = {};
        let whitelist = ['title', 'description', 'weight', 'tags'];

        whitelist.forEach((key) => {
          if (attributes.hasOwnProperty(key)) {
            params[key] = attributes[key];
          }
        });

        let {ok} = await api.put(url, {body: JSON.stringify(params)});
        if (!ok) {
          throw new exceptions.Exception('failed to update attachment');
        }
      },
    };
  }
}

export default AttachmentsModule;
