<template>
  <div class="container">
    <object-tag type="Property" :identifier="property.attributes.id" />
    <section class="section">
      <property-form
        :attributes="property.form"
        :errors="property.errors"
        :dropdowns="dropdowns"
        @onSubmit="commitPropertyEdit"
        @onCancel="cancelPropertyEdit"
        @onChangeAttribute="assignPropertyFormAttributes"
        @onChangeCountry="assignPropertyCountry"
        @onChangeProvince="assignPropertyProvince"
      />
    </section>
  </div>
</template>

<script>
  import PropertyContext from './mixins/PropertyContext.vue';
  import PropertyForm from '~/components/property/PropertyForm.vue';
  import ObjectTag from '~/components/page/ObjectTag.vue';
  export default {
    mixins: [PropertyContext],
    components: {PropertyForm, ObjectTag},
    mounted () {
      this.initializePropertyContext();
    },
  };
</script>
