import Module from './module';
import querystring from 'querystring';
import exceptions from '~/lib/exceptions';

export class ImaginaryModule extends Module {

  get api () {
    return this.services.get('api');
  }

  get state () {
    return {
      token: null,
    };
  }

  get getters () {
    return {};
  }

  get mutations () {
    return {
      token: (state, token) => state.token = token,
    };
  }

  get actions () {
    let {api} = this;

    return {

      initialize: async (store) => {
        let {token} = store.state;
        if (!token) {
          token = store.rootGetters['auth/keychain'].imaginary;
          store.commit('token', token);
        }
      },

      transform: async (store, {blob, operation, params}) => {
        return await store.dispatch('request', {
          method: 'POST',
          path: `/imaginary/${operation}`,
          query: JSON.stringify(params),
          blob,
        });
      },

      pipeline: async (store, {blob, transforms}) => {
        return await store.dispatch('request', {
          method: 'POST',
          path: `/imaginary/pipeline`,
          query: {
            operations: JSON.stringify(transforms.map(({operation, params}) => {
              return {operation, params: this.prepare(params)};
            })),
          },
          blob,
        });
      },

      request: async (store, {method, path, query, blob}) => {
        let {token} = store.state;
        if (!token) {
          throw new exceptions.UnexpectedOutcome('authorization token missing; did you forget to initialize?');
        }
        let url = path;
        if (query) {
          let qs = querystring.stringify(query);
          console.log(qs);
          url = `${url}?${qs}`;
        }
        let response = await this.api[method.toLowerCase()](url, {
          raw: true,
          body: blob,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': blob.type,
            'Content-Length': blob.size,
          },
        });
        return await response.blob();
      },
    };
  }

  prepare (augments={}) {
    return {
      type: 'webp',
      ...augments,
    };
  }

  assertImage (blob) {
    if (!blob || !blob.type || !blob.type.startsWith('image/')) {
      throw new exceptions.InvalidArgument('blob argument must be a Blob having MIME type image/*');
    }
  }
}

export default ImaginaryModule;
