<template>

  <form class="form" @submit.prevent="handleSubmit">
    <div class="level">
      <div class="level-left">
        <p>Total Valuation</p>
      </div>
      <div class="level-right">
        <p class="is-size-5">{{ totalValuation }}</p>
      </div>
    </div>
    <acceptance-form-item
      v-for="(item, index) in optionals"
      :key="index"
      :index="index"
      :item="item"
      @toggle="handleItemToggle"
    >
      <span slot="label" v-if="index === 0">Optional Work</span>
    </acceptance-form-item>
    <div class="level">
      <div class="level-item">
        <btn type="submit" @click="handleSubmit">
          <icon slot="icon" :classes="['fa', 'fa-save']"/>
          Commit
        </btn>
        <btn @click="$emit('cancel');">
          <icon slot="icon" :classes="['fa', 'fa-ban']"/>
          Cancel
        </btn>
      </div>
    </div>

  </form>
</template>

<script>
  import currency from '~/lib/currency';
  import Icon from '~/components/Icon.vue';
  import Btn from '~/components/Btn.vue';
  import AcceptanceFormItem from '~/components/document/AcceptanceFormItem.vue';

  export default {

    components: {
      Icon,
      Btn,
      AcceptanceFormItem,
    },

    props: {
      attributes: {
        required: true,
        type: Object,
      },
      optionals: {
        required: true,
        type: Array,
      },
    },

    computed: {

      totalValuation () {
        let {sum} = this.attributes;
        let reducer = (result, item) => {
          if (item.accepted) {
            result += item.sum || 0;
          }
          return result;
        };
        return currency.format(this.optionals.reduce(reducer, sum || 0));
      },

    },

    methods: {
      formatCurrency (value) {
        return currency.format(value).slice(1);
      },

      handleItemToggle (payload) {
        let {index, checked} = payload;
        let attrs = {accepted: checked};
        this.$emit('update', {index, attrs});
      },

      handleSubmit () {
        this.$emit('submit');
      },
    },

  };

</script>
