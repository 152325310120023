import Module from './module';
import P from '~/lib/promise';
import {File} from '~/lib/fs';
import {Queue} from '~/lib/queue';
import lang from '~/lib/lang';
import {Upload, Bucket} from '~/lib/uploads';
import exceptions from '~/lib/exceptions';

export class UploadModule extends Module {

  get defaults () {
    return {
      ...super.defaults,
      fileSizeLimit: 20 * 1024 * 1024, // bytes
      resolvers: {
        queue: () => new Queue({concurrency: 2}),
        upload: (...args) => new Upload(...args),
      },
    };
  }

  constructor (...args) {
    super(...args);
    this.queue = this.make('queue');
  }

  get api () {
    return this.option('api');
  }

  get preprocessors () {
    return this.option('preprocessors', []);
  }

  get filesystem () {
    return this.option('filesystem');
  }

  get defaultState () {
    return {
      buckets: {},
      uploads: {},
      panel: {
        open: false,
        blink: false,
        blinkTimeout: null,
      },
    };
  }

  get state () {
    return this.defaultState;
  }

  get getters () {
    return {
      buckets: (state) => lang.collection.map(state.buckets, (bucket) => bucket.properties),
      uploads: (state) => lang.collection.map(state.uploads, (upload) => upload),
      panel: (state) => state.panel,
    };
  }

  get mutations () {
    return {
      buckets: (state, buckets) => state.buckets = {...buckets},
      uploads: (state, uploads) => state.uploads = {...uploads},
      panel: (state, panel) => state.panel = {...panel},
    };
  }

  get actions () {
    let {api} = this;

    return {

      /**
       * @param {String} id - bucket id
       * @param {String} title
       * @return {uploads.Bucket}
       */
      getBucket: async (store, {id, title, route}) => {
        let buckets = {...store.state.buckets};
        let bucket = buckets[id];
        if (!bucket) {
          bucket = new Bucket({id, title});
          buckets[id] = bucket;
        }
        bucket.title = title || '';
        bucket.route = route || null;
        store.commit('buckets', buckets);
        return bucket;
      },

      /**
       * @param {String} id - bucket id
       * @return void
       */
      removeBucket: async (store, {id}) => {
        let buckets = {...store.state.buckets};
        let bucket = buckets[id];
        if (bucket) {
          await P.all(lang.collection.map(bucket.uploads, (upload) => upload.abort()));
          delete buckets[id];
          store.commit('buckets', buckets);
        }
      },

      /**
       * @param {String} id - upload id
       * @return void
       */
      removeUpload: async (store, {id}) => {
        let uploads = {...store.state.uploads};
        let buckets = store.state.buckets;
        let upload = uploads[id];

        if (!upload) {
          throw new Error('upload not found!');
        }

        delete uploads[id];
        store.commit('uploads', uploads);
        await upload.abort();

        let bucket = buckets[upload.bucket];
        bucket.remove(upload);

        if (bucket.empty) {
          await store.dispatch('removeBucket', {id: bucket.id});
        }
        else {
          store.commit('buckets', {...buckets});
        }
      },

      /**
       * @async
       * @param {uploads.Bucket} bucket
       * @param {Blob} blobs
       * @return {uploads.Upload}
       */
      upload: async (store, {bucket, blob}) => {
        this.assertBucket(bucket);
        let file = await File.from(blob);
        let upload = this.createUpload(store, bucket, file);

        this.queueUpload(store, bucket, upload);
        store.dispatch('startBlinking');

        return upload;
      },

      openPanel: (store) => {
        store.commit('panel', {...store.state.panel, open: true})
      },

      closePanel: (store) => {
        store.commit('panel', {...store.state.panel, open: false});
      },

      togglePanel: (store) => {
        let {open} = store.state.panel;
        store.commit('panel', {...store.state.panel, open: !open});
      },

      startBlinking: (store) => {
        let timeout = store.state.panel.blinkTimeout;
        clearTimeout(timeout);
        timeout = setTimeout(() => store.dispatch('stopBlinking'), 3000);
        store.commit('panel', {
          ...store.state.panel,
          blink: true,
          blinkTimeout: timeout,
        });
      },

      stopBlinking: (store) => {
        store.commit('panel', {
          ...store.state.panel,
          blink: false,
          blinkTimeout: null,
        });
      },
    };
  }

  createUpload (store, bucket, file) {
    let uploads = {...store.state.uploads};
    let upload = this.make('upload', {
      bucket: bucket.id,
      file,
      api: this.api,
      preprocessors: this.preprocessors,
    });
    uploads[upload.id] = upload;
    store.commit('uploads', uploads);
    bucket.add(upload);
    return upload;
  }

  findUploadByFile (store, file) {
    let {uploads} = store.state;
    return lang.collection.reduce(uploads, (result, upload) => {
      if (result) {
        return result;
      }
      let {sum} = upload.file;
      if (sum === file.sum) {
        return upload;  
      }
      return null;
    });
  }

  queueUpload (store, bucket, upload) {
    let task = this.createQueueTask(bucket, upload);
    let {queue} = this;
    let destroy = () => upload.destroy();

    upload.finished.then(destroy);
    upload.aborted.then(destroy);

    queue.push(task, {ttl: 300000}); // upload times out after 300 seconds
    queue.run();
  }

  createQueueTask (bucket, upload, options={}) {
    return async () => {
      try {
        upload.start();
        await upload.finished;
      }
      catch (e) {
        let aborted = e instanceof exceptions.Aborted;
        if (!aborted) {
          throw e;
        }
      }
    };
  }

  assertBucket (bucket) {
    let kosher = bucket instanceof Bucket;
    if (!kosher) {
      throw new exceptions.InvalidArgument('bucket parameter must be a Bucket instance');
    }
  }

}

export default UploadModule;
