import DataTableModule from '~/lib/vue/modules/datatable';
import rest from '~/lib/rest';

/**
 * @param {~/lib.foundation.Container} services
 * @return {Object}
 */
export default (services, namespace='list') => {

  return DataTableModule.create({
    namespace,
    services,
    resource: '/customers',
    query: {
      includes: ['property'],
    },
    columns: [
      {
        label: 'ID',
        value: row => row.id,
      },
      {
        label: 'Property',
        value: row => `${row.property.address.split('\n').shift()}, ${row.property.city}`,
      },
    ],
  });
};
