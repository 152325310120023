import DataTableModule from '~/lib/vue/modules/datatable';
import rest from '~/lib/rest';

/**
 * @param {~/lib.foundation.Container} services
 * @return {Object}
 */
export default (services, namespace='datatable') => {

  return DataTableModule.create({
    namespace,
    services,
    url: '/customers',
    columns: [
      {
        label: 'Name',
        value: row => `${row.firstname} ${row.lastname}`,
      },
      {
        label: 'Company',
        value: row => row.company ? row.company.name : '-',
      },
    ],
  });
};
