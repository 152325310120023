export class StorageWrapper {
    constructor(localStorage = window.localStorage) {
        this.localStorage = localStorage;
    }
    get(key) {
        var _a;
        const json = (_a = this.localStorage.getItem(key)) !== null && _a !== void 0 ? _a : null;
        if (json)
            try {
                return JSON.parse(json);
            }
            catch (e) {
                // parse error; bury it
            }
        return null;
    }
    set(key, value) {
        this.localStorage.setItem(key, JSON.stringify(value));
    }
    remove(key) {
        this.localStorage.removeItem(key);
    }
    clear() {
        this.localStorage.clear();
    }
}
