<template>

  <div class="media">
    <div class="media-left">
      <checkbox :checked="item.accepted" @toggle="handleToggle"/>
    </div>
    <div class="media-content">
      <div class="content">
        <p>{{ item.text }}</p>
      </div>
    </div>
    <div class="media-right">
      <p>{{ valuation }}</p>
    </div>
  </div>

</template>
<script>

  import Checkbox from '~/components/Checkbox.vue';
  import currency from '~/lib/currency';

  export default {
    components: {
      Checkbox,
    },
    props: {
      index: {
        type: Number,
        required: true,
      },
      item: {
        type: Object,
        required: true,
        validator: (item) => {
          let keys = ['text', 'accepted', 'sum'];
          let validate = (result, key) => result && item.hasOwnProperty(key);
          return keys.reduce(validate, true);
        },
      },
    },

    computed: {
      valuation () {
        return currency.format(this.item.sum);
      },
    },

    methods: {
      handleToggle (checked) {
        let {index} = this;
        let payload = {index, checked};
        this.$emit('toggle', payload);
      },
    },

  };
</script>
