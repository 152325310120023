import Vue from 'vue';
import Vuex from 'vuex';
import lang from '~/lib/lang';

import auth from '~/modules/auth';
import cms from '~/modules/cms';
import customer from '~/modules/customer';
import document from '~/modules/document';
import imaginary from '~/modules/imaginary';
import inquiry from '~/modules/inquiry';
import instrumentation from '~/modules/instrumentation';
import job from '~/modules/job';
import layout from '~/modules/layout';
import property from '~/modules/property';
import upload from '~/modules/upload';

Vue.use(Vuex);

const MODULES_ENABLED = {
  auth,
  cms,
  customer,
  document,
  imaginary,
  inquiry,
  instrumentation,
  job,
  layout,
  property,
  upload,
};

export default {

  create (services) {
    return new Vuex.Store({
      strict: false,
      modules: this.loadModules(services),
      plugins: [],
    });
  },

  loadModules (services) {
    let mods = {};

    lang.collection.each(MODULES_ENABLED, (resolver, namespace) => {
      let mod = resolver(services, namespace);
      mods[namespace] = mod;
    });

    return mods;
  },

};

