import Module from '~/lib/vue/modules/module';

export default (services, namespace='instrumentation') => {

  const defaults = () => {
    return {
      open: false,
    };
  };

  return Module.create({
    services,

    state: defaults(),

    getters: {
      open: (state) => state.open,
    },

    mutations: {
      open: (state, open) => state.open = Boolean(open),
    },

    actions: {
      openPanel: (store) => {
        store.commit('open', true);
      },
      closePanel: (store) => {
        store.commit('open', false);
      },
      togglePanel: (store) => {
        let {open} = store.state;
        store.commit('open', !open);
      },
    },
 
  });
};
