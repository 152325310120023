<template>
  <div class="card">
    <div class="card-content">
      <div class="level">
        <div class="level-left">
          <p class="subtitle">{{ attributes.name }}</p>
        </div>
        <div class="level-right">
          <slot name="buttons"/>
        </div>
      </div>
      <table class="table is-fullwidth is-size-6">
        <tbody>
          <tr>
            <td style="min-width: 8rem;"><label>Received at</label></td>
            <td>{{ timestamp  }}</td>
          </tr>
          <tr>
            <td><label>Name</label></td>
            <td>{{ attributes.name}}</td>
          </tr>
          <tr>
            <td><label>Company</label></td>
            <td>{{ attributes.company || '-'  }}</td>
          </tr>
          <tr>
            <td><label>Email</label></td>
            <td>{{ attributes.email || '-'  }}</td>
          </tr>
          <tr>
            <td><label>Tel</label></td>
            <td>{{ tel }}</td>
          </tr>
          <tr>
            <td><label>Message</label></td>
            <td>{{ attributes.message }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import date from '~/lib/date';

export default {
  props: {
    attributes: {
      required: true,
      type: Object,
    },
  },
  computed: {
    timestamp () {
      return date(this.attributes.created_at).format('MMMM D YYYY, h:mm A');
    },
    tel () {
      let {tel} = this.attributes;
      if (tel) {
        return tel.replace(/\./g, '-');
      }
      return '-';
    },
  },
};
</script>
