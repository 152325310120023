<template>
  <div class="field has-addons is-horizontal">
    <label v-if="$slots.label" :for="id" class="label">
      <slot name="label"/>
    </label>
    <div class="control" v-for="(button, i) in buttons">
      <radio-button
        :id="id"
        :key="i"
        :name="name"
        :value="button.value"
        :checked="button.value == value"
        :color="computeButtonColor(button.value === value)"
        :disabled="button.disabled"
        :tooltip="button.tooltip"
        @choose="handleClickRadioButton"
      >
        {{ button.label || button.value }}
      </radio-button>
    </div>
  </div>
</template>
<script>

  import uuid from '~/lib/uuid';
  import RadioButton from '~/components/RadioButton.vue';

  export default {

    props: {
      value: {
        required: true,
      },
      id: {
        type: String,
        required: false,
        default: uuid.v4(),
      },
      name: {
        type: String,
        required: false,
        default: uuid.v4(),
      },
      buttons: {
        type: Array,
        validator: (buttons) => {
          let validate = (valid, def) => valid && def.hasOwnProperty('value');
          return buttons.reduce(validate, true);
        },
      },
      colors: {
        type: Object,
        required: false,
        default: () => {
          return {
            checked: 'is-dark',
          };
        },
      },
    },

    components: {
      RadioButton,
    },

    methods: {
      computeButtonColor (checked) {
        let {colors} = this;
        let key = checked ? 'checked' : null;
        return key && colors[key] ? colors[key] : 'is-light';
      },
      handleClickRadioButton (value) {
        this.$emit('change', value);
      },
    },
  };


</script>
