import Module from './module';
import rest from '~/lib/rest';
import {InvalidArgument} from '~/lib/exceptions';

export class NotesModule extends Module {

  get defaultState () {
    return {
      subject: null,
      notes: [],
    };
  }

  get rel () {
    return this.option('rel');
  }

  /**
   * function (
   *
   */
  get urlResolver () {
    return this.option('url_resolver');
  }

  get state () {
    return this.defaultState;
  }

  get getters () {
    return {
      subject: state => state.subject,
      notes: state => state.notes,
    };
  }

  get mutations () {
    return {
      subject: (state, subject) => state.subject = subject,
      notes: (state, notes) => state.notes = [...notes],
    };
  }

  get actions () {
    return {

      initialize: async (store, {subject}) => {
        let {notes} = this.defaultState;
        store.commit('subject', subject);
        store.commit('notes', notes);
      },

      loadNotes: async (store) => {
        let {rel} = this;
        let {subject} = store.state
        let api = this.services.get('api');
        let url_resolver = this.option('url_resolver');
        let url = url_resolver(subject);
        let query = new rest.Query({
          filters: {match_rel: rel},
        });
        let {ok, payload} = await api.get(`${url}?${query.toString()}`);

        if (ok) {
          store.commit('notes', payload);
        }
      },

      addNote: async (store, text) => {
        if (typeof text !== 'string' || text.trim().length === 0) {
          throw new InvalidArgument('expected non-empty string text argument');
        }
        let api = this.services.get('api');
        let url_resolver = this.option('url_resolver');
        let {rel} = this;
        let {subject} = store.state;
        let notes = [...store.state.notes];
        let url = url_resolver(subject);
        let body = JSON.stringify({text, rel});
        let {ok, payload} = await api.post(url, {body});
        if (ok) {
          notes.push(payload);
          store.commit('notes', notes);
        }
      },

      deleteNote: async (store, id) => {
        let api = this.services.get('api');
        let notes = [...store.state.notes];
        let {subject} = store.state;
        let url_resolver = this.option('url_resolver');
        let url = url_resolver(subject, id);
        let {ok} = await api.delete(url);
        if (ok) {
          store.commit('notes', notes.filter(note => note.id != id));
        }
      },
    };
  }
}

export default NotesModule;
