<template>
  <li class="field has-addons">
    <div class="control is-expanded">
      <textarea
        :class="{'is-danger': errors.text}"
        :value="attributes.text"
        @keyup="handleAttributeChange"
        data-attr="text"
        type="text"
        rows="2"
        class="textarea"
        placeholder="Install product on..."
      />
      <p v-if="errors.text" class="help is-error">
        {{ errors.text }}
      </p>
      <p v-else v-html="'&nbsp;'"/>
    </div>
    <div class="control has-icons-left">
      <input
        :class="{'is-danger': errors.sum}"
        :value="attributes.sum"
        @keyup="handleAttributeChange"
        @focus="handleFocus"
        data-attr="sum"
        type="number"
        class="input"
        min="0"
        step="10"
        lang="en-150"
      />
      <span class="icon is-small is-left"><i class="fa fa-dollar-sign"/></span>
    </div>
    <div class="control">
      <button
        class="delete"
        v-on:click.prevent.stop="handleDeleteButtonClick"
        style="border: none; cursor: pointer; margin-left: 1rem"
        :style="{visibility: deletable ? 'visible' : 'hidden'}"
      />
      <slot name="handle"/>
    </div>
  </li>
</template>

<script>

  export default {
    name: 'document-form-optional-item',

    props: {
      index: {
        type: Number,
        required: true,
      },
      deletable: {
        type: Boolean,
        required: true,
      },
      attributes: {
        type: Object,
        required: true,
      },
      errors: {
        type: Object,
        default: () => ({}),
      },
    },

    methods: {
      handleAttributeChange (event) {
        let el = event.target;
        let {index} = this;
        let {value} = el;
        let {attr} = el.dataset;
        let attrs = {[attr]: value};
        this.$emit('update', {index, attrs});
      },
      handleDeleteButtonClick (event) {
        this.$emit('delete', this.index);
      },
      handleFocus (event) {
        event.target.select();
      },
    },

  };

</script>
