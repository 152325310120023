import DataRecordModule from '~/lib/vue/modules/datarecord';
import P from '~/lib/promise';
import lang from '~/lib/lang';
import constants from '~/lib/domain/constants';

export default (services, namespace='record') => {

  return DataRecordModule.create({
    namespace,
    services,
    type: 'inquiry',
    resource: '/inquiries',
    describe: (payload) => {
      let {name, company} = payload;
      let msg = `Inquiry from ${name}`;
      if (company) {
        return `${msg} @ ${company}`;
      }
      return msg;
    },

    /**
     * Representation of the record from server to client
     */
    attributes: {
      id: null,
      name: null,
      company: null,
      email: null,
      tel: null,
      message: null,
    },

    /**
     * Blank representation of the record from client to server
     */
    form: {},

    /**
     * Map attributes to the form; used to assign initial form values
     * @param {Object} attributes
     * @return {Object}
     */
    map: (attributes) => {
      return {...attributes};
    },

    /**
     * Collect attributes from external modules and assign them to the form
     * @param {vuex.Store} store
     * @param {Object} form
     * @return {Object}
     */
    marshal: ({rootGetters}, form) => {
      return {...form};
    },

  });
};
