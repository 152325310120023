<template>
  <form @submit.prevent @change="handleChange">
    <label class="heading">Asset ID</label>
    <div :class="{field: true, 'has-addons': !!clipboard}">
      <div class="control is-expanded">
        <input data-attr="title" class="input" type="text" :value="attributes.blob.sum" readonly disabled />
      </div>
			<div v-if="clipboard" class="control">
				<btn @click="handleCopyBlobSum">
          <icon :classes="['fa', 'fa-clipboard']"/>
				</btn>
			</div>
    </div>
    <div class="field">
      <label class="heading">Title</label>
      <div class="control">
        <input data-attr="title" class="input" type="text" :value="attributes.title" maxlength="255" />
      </div>
    </div>
    <div class="field">
      <label class="heading">Description</label>
      <div class="control">
        <textarea data-attr="description" class="textarea" :value="attributes.description" rows="3" />
      </div>
    </div>
  </form>
</template>

<script>
	import Btn from '~/components/Btn.vue';
  import Icon from '~/components/Icon.vue';
  import {Clipboard} from '~/lib/clipboard';

  export default {
    components: {
      Btn,
      Icon,
    },
    props: {
      attributes: {
        required: true,
        type: Object,
      },
      index: {
        required: true,
        type: Number,
      },
      clipboard: {
        required: false,
        default: null,
      },
    },

    methods: {
      handleChange (event) {
        let {dataset, value} = event.target;
        let {attr} = dataset;
        let {index, attributes} = this;
        this.$emit('change', {
          index,
          attributes: {
            ...attributes,
            [attr]: value,
          },
        });
      },

      async handleCopyBlobSum () {
        if (this.clipboard) {
          await this.clipboard.put(this.attributes.blob.sum);
        }
      },
    },

    async mounted () {
    },
  };
</script>
