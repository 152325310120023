<template>
  <section class="section">
    <div class="columns">
      <div class="column">
        <div class="level">
          <div class="level-right">
            <div class="level-item">
              <p>Customer</p>
            </div>
          </div>
        </div>
        <div v-if="!customer.attributes.id">
          <term-search-form
            v-if="!customer.attributes.id"
            :terms="customerSearch.terms"
            :results="customerSearch.results"
            :error="customerSearch.error"
            :progress="customerSearch.progress"
            :valid="customerSearch.valid"
            :placeholder="customerSearch.placeholder"
            @change="onChangeCustomerSearchTerms"
            @reset="onClearCustomerSearchTerms"
          >
            <div slot="results">
              <customer-search-results
                :records="customerSearch.results"
                :valid="customerSearch.valid"
                @choose="onClickCustomerSearchResult"
              />
            </div>
            <btn slot="button" @click="$emit('customer:new')">
              <icon slot="icon" :classes="['fa', 'fa-plus-circle']" :tooltip="'New Customer'" />
            </btn>
          </term-search-form>
          <slot name="customer-table"/>
        </div>
        <customer-card
          v-if="customer.attributes.id"
          :attributes="customer.attributes"
          @clipboard="$emit('customerClipboard')"
        />
      </div>
      <div class="column">
        <div class="level">
          <div class="level-right">
            <div class="level-item">
              <p>Property</p>
            </div>
          </div>
        </div>
        <div v-if="!property.attributes.id">
          <term-search-form
            v-if="!property.attributes.id"
            :terms="propertySearch.terms"
            :results="propertySearch.results"
            :error="propertySearch.error"
            :progress="propertySearch.progress"
            :valid="propertySearch.valid"
            :placeholder="propertySearch.placeholder"
            @change="onChangePropertySearchTerms"
            @reset="onClearPropertySearchTerms"
          >
            <div class="dropdown-content" slot="results">
              <property-search-results
                :records="propertySearch.results"
                :valid="propertySearch.valid"
                @choose="onClickPropertySearchResult"
              />
            </div>
            <btn slot="button" @click="$emit('property:new')">
              <icon slot="icon" :classes="['fa', 'fa-plus-circle']" :tooltip="'New Property'" />
            </btn>
          </term-search-form>
          <slot name="property-table"/>
        </div>
        <property-card
          v-else-if="property.attributes.id"
          :attributes="property.attributes"
          @clipboard="$emit('propertyClipboard')"
        />
      </div>
    </div>
  </section>
</template>

<script>
  import PropertyCard from '~/components/property/PropertyCard.vue';
  import PropertySearchResults from '~/components/property/PropertySearchResults.vue';
  import CustomerCard from '~/components/customer/CustomerCard.vue';
  import CustomerSearchResults from '~/components/customer/CustomerSearchResults.vue';
  import TermSearchForm from '~/lib/vue/components/search/TermSearchForm.vue';
  import Icon from '~/components/Icon.vue';
  import Btn from '~/components/Btn.vue';
  import ObjectTag from '~/components/page/ObjectTag.vue';

  const noop = () => {};

  export default {

    name: 'job-details',

    components: {
      PropertyCard,
      PropertySearchResults,
      CustomerCard,
      CustomerSearchResults,
      TermSearchForm,
      Icon,
      Btn,
    },

    props: {
      customer: {
        required: true,
      },
      customerSearch: {
        required: false,
      },
      resetCustomerSearch: {
        type: Function,
        required: true,
      },
      onChangeCustomerSearchTerms: {
        required: false,
        type: Function,
        default: noop,
      },
      onClearCustomerSearchTerms: {
        required: false,
        type: Function,
        default: noop,
      },
      onClickCustomerSearchResult: {
        required: false,
        type: Function,
        default: noop,
      },
      property: {
        required: true,
      },
      propertySearch: {
        required: false,
      },
      resetPropertySearch: {
        type: Function,
        required: true,
      },
      onChangePropertySearchTerms: {
        required: false,
        type: Function,
        default: noop,
      },
      onClearPropertySearchTerms: {
        required: false,
        type: Function,
        default: noop,
      },
      onClickPropertySearchResult: {
        required: false,
        type: Function,
        default: noop,
      },
      job: {
        required: false,
      },
    },

  };

</script>
