<template>

  <div class="container">
    <h4 class="subtitle is-size-4">
      {{ documentEditPageTitle }} {{ document.attributes.id }}
    </h4>
    <document-form
      v-if="job.attributes.id"
      :job="job"
      :customer="job.attributes.customer"
      :property="job.attributes.property"
      :attributes="document.form"
      :types="docTypes"
      @onChangeAttribute="assignDocumentFormAttributes"
      @save="commitDocumentEdit"
    >
      <playbook slot="standard-playbook"
        :buckets="playbooks.standard.buckets"
        :plays="playbooks.standard.plays"
        @choose="handleStandardPlaybookPlays"
      />
      <playbook slot="optionals-playbook"
        :buckets="playbooks.optionals.buckets"
        :plays="playbooks.optionals.plays"
        @choose="handleOptionalPlaybookPlays"
      />
      <document-form-item-list
        slot="items"
        :items="documentItems"
        @add="addDocumentItem"
        @delete="deleteDocumentItem"
        @update="updateDocumentItem"
        @sort="sortDocumentItems"
      />
      <document-form-item-list
        slot="optionals"
        :optionals="documentOptionals"
        @add="addDocumentOptional"
        @delete="deleteDocumentOptional"
        @update="updateDocumentOptional"
        @sort="sortDocumentOptionals"
      />
      <document-form-item-list
        slot="terms"
        :terms="documentTerms"
        @add="addDocumentTerm"
        @delete="deleteDocumentTerm"
        @update="updateDocumentTerm"
        @sort="sortDocumentTerms"
      />
      <warranty-form
        slot="warranties"
        :warranties="documentWarranties.warranties"
        @add="addWarranty"
        @delete="removeWarranty"
        @update="updateWarranty"
      />

      <div slot="buttons">
        <btn @click="commitDocumentEdit">
          <icon slot="icon" :classes="['fa', 'fa-check']"/>
          Commit
        </btn>
        <btn @click="cancelDocumentEdit">
          <icon slot="icon" :classes="['fa', 'fa-ban']"/>
          Cancel
        </btn>
      </div>

    </document-form>
  </div>

</template>
<script>

  import JobContext from './mixins/JobContext.vue';
  import DocumentContext from './mixins/DocumentContext.vue';
  import DocumentForm from '~/components/document/DocumentForm.vue';
  import DocumentFormItemList from '~/components/document/DocumentFormItemList.vue';
  import WarrantyForm from '~/components/warranty/WarrantyForm.vue';
  import playbooks from '~/fixtures/playbooks';
  import Playbook from '~/components/playbook/Playbook.vue';
  import Btn from '~/components/Btn.vue';
  import Icon from '~/components/Icon.vue';

  export default {
    mixins: [JobContext, DocumentContext],
    components: {
      Btn,
      Icon,
      DocumentForm,
      DocumentFormItemList,
      WarrantyForm,
      Playbook,
    },
    data () {
      return {
        playbooks,
      };
    },
    methods: {
      handleStandardPlaybookPlays (items) {
        this.pushDocumentItems(items);
      },
      handleOptionalPlaybookPlays (items) {
        this.pushDocumentOptionals(items);
      },
    },
    mounted () {
      (async () => {
        await this.initializeJobContext();
        await this.initializeDocumentContext();
      })();
    },
  };

</script>
