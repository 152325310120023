import form from './TermSearchForm.vue';
import input from './TermSearchInput.vue';

export const TermSearchForm = form;
export const TermSearchInput = input;

export default {
  TermSearchForm,
  TermSearchInput,
};
