<template>

  <div class="container">
    <h4 class="subtitle">Sign In</h4>
    <div class="columns">
      <div class="column">
        <p v-if="auth.error" class="help is-danger">{{auth.error}}</p>
        <form class="form" @submit.prevent="handleFormSubmission">
          <div class="field">
            <p class="control has-icons-left has-icons-right">
              <input v-model="email" class="input" type="email" placeholder="Email">
              <span class="icon is-small is-left">
                <i class="fas fa-envelope"></i>
              </span>
            </p>
          </div>
          <div class="field">
            <p class="control has-icons-left">
              <input v-model="password" class="input" type="password" placeholder="Password">
              <span class="icon is-small is-left">
                <i class="fas fa-lock"></i>
              </span>
            </p>
          </div>
          <div class="field">
            <p class="control">
              <button class="button is-dark">
                Submit
              </button>
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>

</template>
<script>
  import {mapState, mapGetters, mapActions} from 'vuex';
  import format from '~/lib/domain/format';
  import P from '~/lib/promise';

  export default {

    data () {
      return {
        email: '',
        password: '',
      };
    },

    computed: {

      ...mapGetters('auth', {
        'auth': 'props',
      }),
    },

    methods: {

      ...mapActions('auth', ['login', 'logout']),

      async handleFormSubmission (event) {
        event.preventDefault();
        let {email, password} = this;
        await this.login({email, password});
      },
    },

  };

</script>
