import TerritoryModule from '~/lib/vue/modules/territory';

/**
 * @param {~/lib.foundation.Container} services
 * @return {Object}
 */
export default (services, namespace='mailingAddressTerritories') => {
  return TerritoryModule.create({
    namespace,
    services,
  });
};
