<template>

  <transition name="modal">
    <portal to="modal">
      <div class="modal" :class="{'is-active': open}">
        <div class="modal-background"></div>
        <div class="modal-content">
          <slot name="body"></slot>
        </div>
        <button class="modal-close is-large" aria-label="close" @click="$emit('close')"></button>
      </div>
    </portal>
  </transition>

</template>
<script>

  export default {
    props: {
      open: {
        required: true,
        type: Boolean,
      },
    },
  };

</script>
