<template>
  <customer-form
    :attributes="customer.form"
    :errors="customer.errors"
    :serviceAddressTerritories="customerServiceAddressTerritories"
    :billingAddressTerritories="customerBillingAddressTerritories"
    :mailingAddressTerritories="customerMailingAddressTerritories"
    @onSubmit="commit"
    @onCancel="cancel"
    @onChangeAttribute="assignCustomerFormAttributes"
    @onChangeServiceCountry="setCustomerServiceAddressCountry"
    @onChangeServiceProvince="setCustomerServiceAddressProvince"
    @onChangeBillingCountry="setCustomerBillingAddressCountry"
    @onChangeBillingProvince="setCustomerBillingAddressProvince"
    @onChangeMailingCountry="setCustomerMailingAddressCountry"
    @onChangeMailingProvince="setCustomerMailingAddressProvince"
    @clearBillingAddress="clearCustomerBillingAddress"
    @clearMailingAddress="clearCustomerMailingAddress"
  >
    <company-card
      v-if="customer.form.company_id && customer.form.company"
      slot="company-search"
      :attributes="customer.form.company"
    >
      <div slot="buttons">
        <btn @click="clearCustomerFormCompany" :flat="true">
          <icon :classes="['fa', 'fa-ban']" :tooltip="'Clear Company'" />
        </btn>
      </div>
    </company-card>
    <term-search-input
      v-else
      slot="company-search"
      :terms="companySearch.terms"
      :results="companySearch.results"
      :error="companySearch.error"
      :progress="companySearch.progress"
      :valid="companySearch.valid"
      :placeholder="companySearch.placeholder"
      @change="handleCompanySearchTerm"
      @reset="resetCompanySearch"
    >
      <customer-search-results
        slot="results"
        :valid="companySearch.valid"
        :records="companySearch.results"
        @choose="handleCompanySearchSelection"
      />
    </term-search-input>
  </customer-form>
</template>

<script>
  import {TermSearchInput} from '~/lib/vue/components/search';
  import CustomerContext from '~/pages/mixins/CustomerContext.vue';
  import CustomerForm from '~/components/customer/CustomerForm.vue';
  import CustomerSearchResults from '~/components/customer/CustomerSearchResults.vue';
  import CompanyCard from '~/components/customer/CompanyCard.vue';
  import Icon from '~/components/Icon.vue';
  import Btn from '~/components/Btn.vue';

  export default {
    mixins: [CustomerContext],
    components: {
      Btn,
      CompanyCard,
      CustomerForm,
      CustomerSearchResults,
      Icon,
      TermSearchInput,
    },

    methods: {

      async commit () {
        let ok = await this.saveCustomer();
        if (ok) {
          this.$emit('commit');
          this.resetCustomerForm();
        }
      },

      async cancel () {
        this.$emit('cancel');
        this.resetCustomerForm();
      },
    },
  };

</script>
