<template>

  <div>
    <ul v-if="items" ref="list">
      <document-form-item
        class="item"
        v-for="(item, i) in items"
        :key="i"
        :index="i"
        :data-index="i"
        :attributes="item"
        :optional="false"
        :deletable="true"
        @delete="$emit('delete', i)"
        @update="handleItemUpdateEvent"
      >
        <icon slot="handle" class="handle" :classes="['fa', 'fa-arrows-alt-v']"/>
      </document-form-item>
    </ul>
    <ul v-else-if="terms" ref="list">
      <document-form-item
        class="item"
        v-for="(item, i) in terms"
        :key="i"
        :index="i"
        :attributes="item"
        :optional="false"
        :deletable="true"
        @delete="$emit('delete', i)"
        @update="handleItemUpdateEvent"
      >
        <icon slot="handle" class="handle" :classes="['fa', 'fa-arrows-alt-v']"/>
      </document-form-item>
    </ul>
    <ul v-else-if="optionals" ref="list">
      <document-form-optional-item
        class="item"
        v-for="(item, i) in optionals"
        :key="i"
        :index="i"
        :attributes="item"
        :optional="true"
        :deletable="true"
        @delete="$emit('delete', i)"
        @update="handleItemUpdateEvent"
      >
        <icon slot="handle" class="handle" :classes="['fa', 'fa-arrows-alt-v']"/>
      </document-form-optional-item>
    </ul>
    <p v-else>RENDERING ERROR</p>
    <div class="control">
      <button class="button is-small" @click.prevent="$emit('add')">
        <span class="icon"><i class="fa fa-plus" /></span>
        <span>Add</span>
      </button>
    </div>
  </div>

</template>
<script>

  import DocumentFormItem from './DocumentFormItem.vue';
  import DocumentFormOptionalItem from './DocumentFormOptionalItem.vue';
  import Sortable from 'sortablejs';
  import Icon from '~/components/Icon.vue';

  export default {
    name: 'document-form-item-list',

    components: {
      DocumentFormItem,
      DocumentFormOptionalItem,
      Icon,
    },

    props: {
      items: {
        type: Array,
        required: false,
        default: () => null,
      },
      optionals: {
        type: Array,
        required: false,
        default: () => null,
      },
      terms: {
        type : Array,
        required: false,
        default: () => null,
      },
    },

    methods: {
      handleItemUpdateEvent (...args) {
        this.$emit('update', ...args);
      },
      handleListSortEvent (event) {
        let {to} = event;
        let indexes = [].slice.call(to.querySelectorAll('.item'), 0).map(el => el.dataset.index);
        this.revertDomMutation(event)
        this.$emit('sort', indexes);
      },

      revertDomMutation (event) {
        let {oldIndex, newIndex} = event;
        let list = this.$el.querySelector('ul');
        let items = list.querySelectorAll('.item')
        if (oldIndex > newIndex) {
          list.insertBefore(items[newIndex], items[oldIndex + 1])
        }
        else {
          list.insertBefore(items[newIndex], items[oldIndex])
        }
      }
    },

    mounted () {
      let {list} = this.$refs;
      this.sortable = new Sortable(list, {
        draggable: '.item',
        handle: '.handle',
        onEnd: (event) => this.handleListSortEvent(event),
      });
    },

    beforeDestroy () {
      this.sortable.destroy();
    },
  };

</script>
