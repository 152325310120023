import DocumentItemsModule from '~/lib/vue/modules/document/items';

export default (services, namespace='optionals') => {
  return DocumentItemsModule.create({
    normalize: (attrs) => {
      return {
        ...attrs,
        sum: attrs.sum ? Number(attrs.sum) : 0,
      };
    },
    defaultItem: {
      text: '',
      sum: 0,
      accepted: false, 
    },
  });
};
