<template>

  <div>
    <div>
      <warranty-field
        v-for="(warranty, i) in warranties"
        :key="i"
        :attributes="warranty"
        :index="i"
        @update="handleFieldUpdate"
        @delete="$emit('delete', i)"
      />
    </div>
    <div class="field is-grouped">
      <div class="control">
        <button class="button" @click.prevent="$emit('add')">
          <span class="icon">
            <i class="fa fa-plus"/>
          </span>
          <span>Add Warranty</span>
        </button>
      </div>
    </div>
  </div>

</template>

<script>
  import WarrantyField from './WarrantyField.vue';

  export default {
    components: {
      WarrantyField,
    },
    props: {
      warranties: {
        type: Array,
        required: true,
      },
    },

    methods: {

      handleFieldUpdate ({index, attributes}) {
        this.$emit('update', {index, attributes});
      },
    },
  };

</script>
