<template>

  <div class="playbook" style="margin-bottom: 1rem;">
    <div class="tabs" style="margin-bottom: 1rem;">
      <ul>
        <li
          v-for="bucket in buckets"
          :class="{'is-active': bucket.id == currentBucketId}"
          @click="handleBucketClick(bucket.id)"
        >
          <a>{{ bucket.label }}</a>
        </li>
      </ul>
    </div>
    <div class="panel">
      <tag
        v-for="(play, i) in currentPlays"
        :key="i"
        @click.native.prevent="handlePlayClick(play)"
        style="cursor: pointer;"
      >
          {{ play.label }}
      </tag>
    </div>
  </div>

</template>
<script>

  import Tag from '~/components/Tag.vue';

  export default {
    components: {
      Tag,
    },
    props: {
      buckets: {
        type: Array,
        required: true,
      },
      plays: {
        type: Array,
        required: true,
      },
    },

    data () {
      return {
        currentBucketId: this.buckets[0].id,
      };
    },

    computed: {

      currentPlays () {
        let {currentBucketId} = this;
        let regex = new RegExp(`^${currentBucketId}:`);
        return this.plays.filter(play => play.bucket === currentBucketId);
      },
    },

    methods: {
      handleBucketClick (id) {
        this.currentBucketId = id;
      },

      /**
       * Emit an array of DocumentItem payloads
       * ~/lib/vue/modules/document/items
       * @param {Object} play
       */
      handlePlayClick (play) {
        this.$emit('choose', play.items.map((item) => {
          return {text: item};
        }));
      },
    },
  };

</script>
