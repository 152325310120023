<template>

  <article class="media">
    <div class="media-left">
      <p class="is-size-7">
        {{ author }}<br>
        <abbr :title="timestamp">{{ age }}</abbr>
      </p>
    </div>
    <div class="media-content">
      {{ attributes.text }}
    </div>
    <div class="media-right">
      <a class="delete" @click.prevent="$emit('delete')"></a>
    </div>
  </article>

</template>
<script>

  import date from '~/lib/date';

  export default {
    props: {
      attributes: {
        type: Object,
      },
    },

    computed: {

      timestamp () {
        let {created_at} = this.attributes;
        return date(created_at).format('dddd, MMMM Do YYYY, h:mm:ss A');
      },

      age () {
        let {created_at} = this.attributes;
        return date(created_at).fromNow();
      },

      author () {
        let {author} = this.attributes;
        if (author) {
          return `${author.firstname} ${author.lastname}`;
        }
        return '';
      },
    },
  };

</script>
