<template>
	<article class="upload-bucket media">
		<div class="media-content">
			<p>{{ bucket.title }}</p>
			<ul class="uploads">
				<upload tag="li" v-for="(upload, i) in bucket.uploads" :key="i" :upload="upload" :eventbus="eventbus" />
			</ul>
		</div>
		<div class="media-right">
    	<a class="delete" @click="handleClose"/>
		</div>
	</article>
</template>

<script>
  import Upload from './Upload.vue';
  import Icon from '~/components/Icon.vue';

  export default {
    components: {
      Icon,
      Upload,
    },
    props: {
      bucket: {
        required: true,
        type: Object,
      },
      eventbus: {
        required: true,
      },
    },

    methods: {
      handleClose () {
        this.eventbus.$emit('bucket:remove', this.bucket.id);
      },
    },
  };

</script>
