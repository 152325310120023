<template>
  <page>

    <modal
      :open="modalOpen"
      @close="closeModal"
    >
      <div class="box" slot="body">
        <object-tag
          slot="header"
          type="Customer" identifier="new"
        />
        <customer-form-container
          @commit="afterCommitCustomerForm"
          @cancel="afterCancelCustomerForm"
        />
      </div>
    </modal>

    <object-tag type="Customers" slot="page-title" />
    <div slot="page-buttons">
      <btn @click="openModal">
        <icon slot="icon" :classes="['fa', 'fa-plus-circle']"/>
        New Customer
      </btn>
    </div>

    <term-search-form
      slot="page-search"
      :terms="customerSearch.terms"
      :results="customerSearch.results"
      :error="customerSearch.error"
      :progress="customerSearch.progress"
      :valid="customerSearch.valid"
      :placeholder="customerSearch.placeholder"
      @change="handleCustomerSearchTerm"
      @reset="resetCustomerSearch"
    >
      <customer-search-results
        slot="results"
        :valid="customerSearch.valid"
        :records="customerSearch.results"
        @choose="navigateToCustomer"
      />
    </term-search-form>

    <p class="subtitle">Individuals</p>
    <div class="level">
      <div class="level-item">
        <data-table
          class="table"
          :columns="customerTable.columns"
          :rows="customerTable.rows"
          :limit="customerTable.limit"
          :total="customerTable.total"
          @onClickRow="navigateToCustomer"
        >
          <data-pager
            slot="footer"
            :total="customerTable.total"
            :limit="customerTable.limit"
            :page="customerTable.page"
            @onChangePage="setCustomerTablePage"
            @onChangeLimit="setCustomerTableLimit"
          />
        </data-table>
      </div>
    </div>

    <p class="subtitle">Businesses</p>
    <div class="level">
      <div class="level-item">
        <data-table
          class="table"
          :columns="companyTable.columns"
          :rows="companyTable.rows"
          :limit="companyTable.limit"
          :total="companyTable.total"
          @onClickRow="navigateToCustomer"
        >
          <data-pager
            slot="footer"
            :total="companyTable.total"
            :limit="companyTable.limit"
            :page="companyTable.page"
            @onChangePage="setCompanyTablePage"
            @onChangeLimit="setCompanyTableLimit"
          />
        </data-table>
      </div>
    </div>

  </page>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import P from '~/lib/promise';
  import {DataTable, DataPager} from '~/lib/vue/components/datatables';
  import {TermSearchForm, TermSearchInput} from '~/lib/vue/components/search';
  import CustomerContext from './mixins/CustomerContext.vue';
  import ModalContext from './mixins/ModalContext.vue';
  import CustomerSearchResults from '~/components/customer/CustomerSearchResults.vue';
  import ObjectTag from '~/components/page/ObjectTag.vue';
  import Modal from '~/components/Modal.vue';
  import CustomerFormContainer from '~/containers/CustomerFormContainer.vue';
  import Page from '~/components/Page.vue';
  import Icon from '~/components/Icon.vue';
  import Btn from '~/components/Btn.vue';
  import constants from '~/lib/domain/constants';

  export default {

    mixins: [CustomerContext, ModalContext],

    components: {
      Btn,
      CustomerFormContainer,
      CustomerSearchResults,
      DataPager,
      DataTable,
      Icon,
      Modal,
      ObjectTag,
      Page,
      TermSearchForm,
    },

    methods: {
      async onBeforeOpenModal () {
        this.initializeCustomer();
        this.initializeCompany();
        this.initializeCompanySearch();
      },
    },

    mounted () {
      (async () => {
        await P.all([
          this.initializeCustomerContext(),
          this.initializeCustomerTable({
            query: {
              filters: {
                match_type: constants.CUSTOMER_TYPE_INDIVIDUAL,
              },
            },
          }),
        ]);

        this.loadCustomerTable();
        this.loadCompanyTable();
      })();
    },
  };

</script>
