export class Exception extends Error {}
export class Conflict extends Exception {}
export class Aborted extends Exception {}
export class Expired extends Exception {}
export class Forbidden extends Exception {}
export class BadMethodCall extends Exception {}
export class InvalidArgument extends BadMethodCall {}
export class InvalidState extends Exception {}
export class KeyError extends Exception {}
export class NotImplemented extends Exception {}
export class NotFound extends Exception {}
export class ParseError extends Exception {}
export class Unauthorized extends Exception {}
export class UnexpectedOutcome extends Exception {}

export class Invalid extends Exception {

  constructor (message, errors=[]) {
    super(message || 'Validation failed');
    this.errors = errors;
  }
}

export default {
  Aborted,
  Exception,
  Conflict,
  Expired,
  Forbidden,
  BadMethodCall,
  Invalid,
  InvalidArgument,
  InvalidState,
  KeyError,
  NotImplemented,
  NotFound,
  ParseError,
  Unauthorized,
  UnexpectedOutcome,
};
