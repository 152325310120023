<template>
  <page>

    <object-tag slot="page-title" type="Inquiries"/>

    <data-table
      class="table"
      :columns="inquiryTable.columns"
      :rows="inquiryTable.rows"
      :total="inquiryTable.total"
      :limit="inquiryTable.limit"
      @onClickRow="navigateToInquiry"
    >
      <data-pager
        slot="footer"
        :total="inquiryTable.total"
        :limit="inquiryTable.limit"
        :page="inquiryTable.page"
        @onChangePage="setInquiryTablePage"
        @onChangeLimit="setInquiryTableLimit"
      />
    </data-table>

  </page>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import {DataTable, DataPager} from '~/lib/vue/components/datatables';
  import ObjectTag from '~/components/page/ObjectTag.vue';
  import InquiryContext from './mixins/InquiryContext.vue';
  import Page from '~/components/Page.vue';

  export default {
    mixins: [InquiryContext],

    components: {
      DataTable,
      DataPager,
      ObjectTag,
      Page,
    },

    mounted () {
      (async () => {
        await this.initializeInquiryContext();
        await this.loadInquiryTable();
      })();
    },
  };

</script>
