<template>

  <div class="dropdown-menu" role="menu">
    <div v-if="records.length || buttonSlotFilled" class="dropdown-content">
      <a
        v-for="record in records"
        :key="record.id"
        class="dropdown-item"
        @click="$emit('choose', record.id)"
      >
        <p v-if="record.type === 'company'">
          {{ record.name }}
          <span class="tag">Business</span>
        </p>
        <p v-else>
          {{ record.firstname }} {{ record.lastname }}
          <span v-if="record.company">({{ record.company.name }})</span>
        </p>
      </a>
      <slot v-if="buttonSlotFilled" name="buttons" class="dropdown-item"/>
    </div>
    <div v-else-if="progress" class="dropdown-item">
      <spinner />
    </div>
    <div v-else-if="valid" class="dropdown-item">
      <p>No matches.</p>
    </div>
  </div>

</template>
<script>
  import constants from '~/lib/domain/constants';

  export default {
    name: 'customer-search-results',
    props: {
      records: {
        type: Array,
        required: true,
      },
      progress: {
        type: Boolean,
        required: false,
      },
      valid: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      buttonSlotFilled () {
        return !!this.$slots.buttons;
      },
    },
    methods: {
      getSearchResultText (record) {
        let {type, company, name, firstname, lastname} = record;
        if (type === constants.CUSTOMER_TYPE_COMPANY) {
          return name;
        }
        let result = `${firstname} ${lastname}`;
        if (company) {
          result = `${result} (${company.name})`;
        }
        return result;
      },
    },
  };
</script>

