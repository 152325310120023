import Module from '~/lib/vue/modules/module';
import datatable from './datatable';
import record from './record';

export default (services, namespace='inquiry') => {
  return Module.create({
    namespace,
    services,
    modules: {
      datatable,
      record,
    },
  });
};
