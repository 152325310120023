<script>
  import {mapState, mapGetters, mapActions} from 'vuex';
  import P from '~/lib/promise';

  export default {

    computed: {

      ...mapGetters('property/datatable', {
        'propertyTable': 'props',
      }),

      ...mapGetters('property/list', {
        'propertyList': 'props',
      }),

      ...mapGetters('property/record', {
        'property': 'props',
      }),

      ...mapGetters('property/search', {
        'propertySearch': 'props',
      }),

      ...mapGetters('property/territories', {
        'propertyTerritories': 'props',
      }),

      propertyUrls () {
        let {id} = this.property.attributes;
        let resolve = (...args) => this.$router.resolve(...args);
        if (id) {
          return {
            detail: resolve({name: 'property:detail', params: {id}}),
            edit: resolve({name: 'property:edit', params: {id}}),
          };
        }
        return {};
      },
    },

    methods: {

      ...mapActions('property/datatable', {
        initializePropertyTable: 'initialize',
        loadPropertyTable: 'load',
        setPropertyTablePage: 'setPage',
        setPropertyTableLimit: 'setLimit',
        setPropertyTableQuery: 'setQuery',
      }),

      ...mapActions('property/list', {
        initializePropertyList: 'initialize',
        loadPropertyList: 'load',
        setPropertyListPage: 'setPage',
        setPropertyListLimit: 'setLimit',
      }),

      ...mapActions('property/record', {
        initializeProperty: 'initialize',
        assignPropertyAttributes: 'assign',
        loadProperty: 'load',
        saveProperty: 'save',
        deleteProperty: 'delete',
        assignPropertyFormAttributes: 'assignFormAttributes',
        resetPropertyForm: 'resetForm',
      }),

      ...mapActions('property/search', {
        initializePropertySearch: 'initialize',
        resetPropertySearch: 'reset',
        queryPropertySearch: 'query',
      }),

      ...mapActions('property/territories', {
        initializePropertyTerritories: 'initialize',
        setPropertyCountry: 'setCountry',
        setPropertyProvince: 'setProvince',
      }),

      async initializePropertyContext () {
        await P.all([
          this.initializeProperty(),
          this.initializePropertyTerritories(),
          this.initializePropertyTable({url: '/properties'}),
          this.initializePropertySearch(),
        ]);

        let id = this.guessPropertyIdFromRoute();
        if (id) {
          await this.loadProperty({id, includes: ['stats']});
        }
      },

      guessPropertyIdFromRoute () {
        let {name, params, query} = this.$route;
        if (name.match(/^property:(detail|edit)/)) {
          return params.id;
        }
        if (query.property) {
          return query.property;
        }
        return null;
      },

      async assignPropertyAttribute (name, value) {
        this.assignPropertyFormAttributes({[name]: value});
      },

      async commitPropertyEdit () {
        let ok = await this.saveProperty();
        if (ok) {
          this.closeModal && this.closeModal();
          this.loadPropertyTable();
        }
      },

      async cancelPropertyEdit () {
        this.closeModal ? this.closeModal() : this.$router.go(-1);
      },

      async copyPropertyToClipboard (property) {
        let {attributes} = property || this.property;
        let text = format.address(attributes);
        await this.$service('clipboard').put(text);
      },

      async navigateToProperty (id) {
        if (id) {
          this.$router.push({name: 'property:detail', params: {id}});
        }
      },

      async loadPropertySearchResult (id) {
        if (id) {
          await this.loadProperty({id});
        }
      },

      async handlePropertySearchTerm (terms) {
        await this.queryPropertySearch({terms});
      },

      async handleDeleteProperty () {
        await this.deleteProperty();
        this.$router.replace({name: 'property:index'});
      },
    },

  };

</script>

