import Module from './module';
import lang from '~/lib/lang';

export class TermSearchModule extends Module {

  get url () {
    return this.option('url');
  }

  get filters () {
    return this.option('filters', {});
  }

  validate (terms) {
    if (!terms || typeof terms !== 'string') {
      return false;
    }
    return terms.length >= this.option('threshold', 2);
  }

  get state () {
    return {

      // search query terms
      terms: '',

      // search result payloads
      results: [],

      // error message
      error: '',

      // is a search query in progress?
      progress: false,

      // are the search terms valid?
      valid: false,

      // search term input placeholder text
      placeholder: this.option('placeholder', ''),
    };
  }

  get getters () {
    return {
      terms: state => state.terms,
      results: state => state.results,
      error: state => state.error,
      progress: state => state.progress,
      valid: state => state.valid,
      placeholder: state => state.placeholder,
    };
  }

  get mutations () {
    return {
      terms: (state, terms) => state.terms = terms ? String(terms) : '',
      results: (state, results) => state.results = [...results],
      error: (state, error) => state.error = error,
      progress: (state, progress) => state.progress = progress,
      valid: (state, valid) => state.valid = valid,
    };
  }

  get actions () {

    return {

      initialize: async (store) => {
        store.dispatch('reset');
      },

      reset: (store) => {
        store.commit('terms', '');
        store.commit('results', []);
        store.commit('error', '');
        store.commit('valid', false);
        store.commit('progress', false);
      },

      query: async (store, {terms, filters}) => {
        terms = terms || '';
        filters = lang.object.merge(this.filters, filters || {});

        store.dispatch('reset');

        let api = this.services.get('api');
        let valid = this.validate(terms);

        store.commit('terms', terms);
        store.commit('valid', valid);

        if (!valid) {
          return;
        }

        store.commit('results', []);

        this.debounce(async () => {
          store.commit('progress', true);

          let body = JSON.stringify({terms, filters});
          let response;

          try {
            response = await api.post(this.url, {body});
          }
          catch (e) {
            if (!e.response) {
              throw e;
            }
            response = e.response;
          }

          store.commit('progress', false);

          let {ok, payload} = response;

          if (ok) {
            store.commit('results', payload);
          }
          else {
            store.commit('error', payload.message);
          }
        });
      },
    };
  }

}

export default TermSearchModule;
