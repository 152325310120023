import Module from '~/lib/vue/modules/module';
import {InvalidArgument} from '~/lib/exceptions';

export const DEFAULT_LAYOUT = 'default';
export const PRINT_LAYOUT = 'print';
export const ALLOWED_LAYOUTS = [DEFAULT_LAYOUT, PRINT_LAYOUT];

export default (services, namespace="layout") => {

  return Module.create({

    state: {
      layoutName: DEFAULT_LAYOUT,
      notificationsEnabled: true,
      modalsEnabled: true,
    },

    getters: {
      layoutName: (state) => state.layoutName,
      notificationsEnabled: (state) => state.notificationsEnabled,
      modalsEnabled: (state) => state.modalsEnabled,
    },

    mutations: {
      layoutName: (state, name) => state.layoutName = String(name),
      notificationsEnabled: (state, value) => state.notificationsEnabled = Boolean(value),
      modalsEnabled: (state, value) => state.modalsEnabled = Boolean(value),
    },

    actions: {

      setLayout: (store, name) => {
        if (!ALLOWED_LAYOUTS.includes(name)) {
          throw new InvalidArgument(`invalid layout name: ${name}`);
        }
        store.commit('layoutName', name);
        if (name === 'print') {
          store.commit('notificationsEnabled', false);
          store.commit('modalsEnabled', false);
        }
        else {
          store.commit('notificationsEnabled', true);
          store.commit('modalsEnabled', true);
        }
      },
    },
  });
};
