<template>
  <div class="dropdown" :class="{'is-active': results.length}">
    <div class="dropdown-trigger">
      <div class="field has-addons">
        <div class="control has-icons-left" :class="{'is-loading': progress}">
          <input
            type="search"
            class="input"
            :placeholder="placeholder"
            @search="handleReset"
            @keyup="handleKeypress"
            @blur="$emit('blur')"
          />
          <icon class="is-left" :classes="['fa', 'fa-search']"/>
        </div>
        <div class="control" v-if="$slots.button">
          <slot name="button"/>
        </div>
      </div>
    </div>
    <slot name="results"/>
  </div>
</template>

<script>
  import Icon from '~/components/Icon.vue';

  export default {
    components: {
      Icon,
    },

    props: {
      terms: {
        type: String,
        required: true,
      },
      results: {
        type: Array,
        required: true,
      },
      error: {
        type: String,
        required: true,
      },
      progress: {
        type: Boolean,
        required: true,
      },
      valid: {
        type: Boolean,
        required: true,
      },
      placeholder: {
        type: String,
        default: 'Enter search terms',
      },
    },

    methods: {

      async handleKeypress (event) {
        let {value} = event.target;
        this.handleChange(value);
      },

      async handleChange (terms) {
        this.$emit('change', terms || '');
      },

      async handleReset () {
        this.$emit('reset');
      },
    },
  };

</script>
