/**
 * Strip leading and trailing character `char` from the supplied `input`
 */
export function strip(input, pattern = '/') {
    let output = input !== null && input !== void 0 ? input : '';
    if (!output) {
        return output;
    }
    if (typeof pattern === 'string') {
        if (output.startsWith(pattern)) {
            output = output.slice(1);
        }
        if (output.endsWith(pattern)) {
            output = output.slice(0, -1);
        }
    }
    else if (pattern instanceof RegExp) {
        output = input.replace(pattern, '');
    }
    else {
        throw new Error('Expected `pattern` argument to be of type "string" or RegExp');
    }
    return output;
}
;
/**
 * Works kinda like Node.js' Path.join()
 */
export const join = (...tokens) => tokens.map(token => strip(token)).join('/');
/**
 * Copypasta from path-normalize
 * https://github.com/jonschlinkert/normalize-path/blob/master/index.js
 */
export const normalize = function (path, stripTrailing = true) {
    if (typeof path !== 'string') {
        throw new TypeError('expected path to be a string');
    }
    if (path === '\\' || path === '/')
        return '/';
    var len = path.length;
    if (len <= 1)
        return path;
    // ensure that win32 namespaces has two leading slashes, so that the path is
    // handled properly by the win32 version of path.parse() after being normalized
    // https://msdn.microsoft.com/library/windows/desktop/aa365247(v=vs.85).aspx#namespaces
    var prefix = '';
    if (len > 4 && path[3] === '\\') {
        var ch = path[2];
        if ((ch === '?' || ch === '.') && path.slice(0, 2) === '\\\\') {
            path = path.slice(2);
            prefix = '//';
        }
    }
    var segs = path.split(/[/\\]+/);
    if (stripTrailing !== false && segs[segs.length - 1] === '') {
        segs.pop();
    }
    return prefix + segs.join('/');
};
