import DataTable from './DataTable.vue';
import DataPager from './DataPager.vue';

export {DataTable, DataPager};

export default {
  install (Vue, options) {
    Vue.component('data-table', DataTable);
  },
};
