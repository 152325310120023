<script>
  import {mapState, mapGetters, mapActions} from 'vuex';
  import format from '~/lib/domain/format';
  import P from '~/lib/promise';
  import constants from '~/lib/domain/constants';

  export default {

    computed: {

      ...mapGetters('customer/datatable', {
        'customerTable': 'props',
      }),

      ...mapGetters('customer/companies', {
        'companyTable': 'props',
      }),

      ...mapGetters('customer/record', {
        'customer': 'props',
      }),

      ...mapGetters('customer/search', {
        'customerSearch': 'props',
      }),

      ...mapGetters('customer/company', {
        'company': 'props',
      }),

      ...mapGetters('customer/companySearch', {
        'companySearch': 'props',
      }),

      ...mapGetters('customer/list', {
        'customerList': 'props',
      }),

      ...mapGetters('customer/notes', {
        'customerNotes': 'props',
      }),

      ...mapGetters('customer/serviceAddressTerritories', {
        'customerServiceAddressTerritories': 'props',
      }),

      ...mapGetters('customer/billingAddressTerritories', {
        'customerBillingAddressTerritories': 'props',
      }),

      ...mapGetters('customer/mailingAddressTerritories', {
        'customerMailingAddressTerritories': 'props',
      }),

      objectTagId () {
        let {id} = this.customer.attributes;
        return id || 'new';
      },

      customerHasServiceAddress () {
        let {addresses} = this.customer.attributes;
        return !!addresses.service;
      },
    },

    methods: {

      ...mapActions('customer/datatable', {
        initializeCustomerTable: 'initialize',
        setCustomerTablePage: 'setPage',
        setCustomerTableLimit: 'setLimit',
        setCustomerTableQuery: 'setQuery',
        loadCustomerTable: 'load',
      }),

      ...mapActions('customer/companies', {
        initializeCompanyTable: 'initialize',
        setCompanyTablePage: 'setPage',
        setCompanyTableLimit: 'setLimit',
        setCompanyTableQuery: 'setQuery',
        loadCompanyTable: 'load',
      }),

      ...mapActions('customer/list', {
        initializeCustomerList: 'initialize',
        setCustomerListPage: 'setCustomerTablePage',
        setCustomerListLimit: 'setLimit',
        loadCustomerList: 'load',
      }),

      ...mapActions('customer/record', {
        initializeCustomer: 'initialize',
        assignCustomerAttributes: 'assign',
        loadCustomer: 'load',
        saveCustomer: 'save',
        deleteCustomer: 'delete',
        assignCustomerFormAttributes: 'assignFormAttributes',
        resetCustomerForm: 'resetForm',
        clearCustomerBillingAddress: 'clearBillingAddress',
        clearCustomerMailingAddress: 'clearMailingAddress',
      }),

      ...mapActions('customer/search', {
        initializeCustomerSearch: 'initialize',
        resetCustomerSearch: 'reset',
        queryCustomerSearch: 'query',
      }),

      ...mapActions('customer/company', {
        initializeCompany: 'initialize',
        assignCompanyAttributes: 'assign',
        loadCompany: 'load',
        saveCompany: 'save',
        deleteCompany: 'delete',
        assignCompanyFormAttributes: 'assignFormAttributes',
        resetCompanyForm: 'resetForm',
        clearCompanyBillingAddress: 'clearBillingAddress',
        clearCompanyMailingAddress: 'clearMailingAddress',
      }),

      ...mapActions('customer/companySearch', {
        initializeCompanySearch: 'initialize',
        resetCompanySearch: 'reset',
        queryCompanySearch: 'query',
      }),

      ...mapActions('customer/serviceAddressTerritories', {
        initializeCustomerServiceAddressTerritories: 'initialize',
        setCustomerServiceAddressCountry: 'setCountry',
        setCustomerServiceAddressProvince: 'setProvince',
      }),

      ...mapActions('customer/billingAddressTerritories', {
        initializeCustomerBillingAddressTerritories: 'initialize',
        setCustomerBillingAddressCountry: 'setCountry',
        setCustomerBillingAddressProvince: 'setProvince',
      }),

      ...mapActions('customer/mailingAddressTerritories', {
        initializeCustomerMailingAddressTerritories: 'initialize',
        setCustomerMailingAddressCountry: 'setCountry',
        setCustomerMailingAddressProvince: 'setProvince',
      }),

      ...mapActions('customer/notes', {
        initializeCustomerNotes: 'initialize',
        loadCustomerNotes: 'loadNotes',
        addCustomerNote: 'addNote',
        deleteCustomerNote: 'deleteNote',
      }),

      async initializeCustomerContext () {
        let id = this.guessCustomerIdFromRoute();
        if (id) {
          this.initializeCustomerNotes({subject: id});
        }

        await P.all([
          this.initializeCustomerTable(),
          this.initializeCustomer(),
          this.initializeCustomerServiceAddressTerritories(),
          this.initializeCustomerBillingAddressTerritories(),
          this.initializeCustomerMailingAddressTerritories(),
          this.initializeCustomerSearch(),
          this.initializeCompany(),
          this.initializeCompanyTable(),
          this.initializeCompanySearch(),
        ]);

        if (id) {
          await P.all([
            this.loadCustomer({id, includes: ['addresses', 'stats']}),
            this.loadCustomerNotes(),
          ]);
        }
      },

      guessCustomerIdFromRoute () {
        let {name, params, query} = this.$route;
        if (!name || !params || !query) {
          throw new Error('route appears to be invalid');
        }
        if (name.match(/^customer:(detail|edit)/)) {
          return params.id;
        }
        if (query.customer) {
          return query.customer;
        }
        return null;
      },

      async afterCommitCustomerForm () {
        this.closeModal();
        this.loadCustomerTable();
        this.loadCompanyTable();
      },

      async afterCancelCustomerForm () {
        this.closeModal();
      },

      async navigateToCustomer (id) {
        if (id) {
          this.$router.push({name: 'customer:detail', params: {id}});
        }
      },

      async loadCustomerSearchResult (id) {
        if (id) {
          await this.loadCustomer({id}),
          this.resetCustomerSearch();
        }
      },

      async handleCustomerSearchTerm (terms) {
        await this.queryCustomerSearch({terms});
      },

      async handleCompanySearchTerm (terms) {
        await this.queryCompanySearch({terms});
      },

      async handleCompanySearchSelection (company_id) {
        let company = this.companySearch.results.filter(company => company.id === company_id).pop();
        this.assignCustomerFormAttributes({company_id, company});
      },

      async clearCustomerFormCompany () {
        this.initializeCompany();
        this.initializeCompanySearch();
        this.assignCustomerFormAttributes({company_id: null});
      },

      async handleDeleteCustomer () {
        await this.deleteCustomer();
        this.$router.replace({name: 'customer:index'});
      },
    },
  };

</script>
