<template>
  <form @submit="handleSubmit" @change="handleAttributeChange" novalidate>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="contact_firstname">Name</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input id="contact_firstname" data-attr="contact_firstname" class="input" :class="{'is-danger': errors.contact_firstname}" type="text" :value="attributes.contact_firstname" placeholder="First name">
          </div>
          <p v-if="errors.contact_firstname" class="help is-error">
            {{ errors.contact_firstname }}
          </p>
          <p v-else v-html="'&nbsp;'"/>
        </div>
        <div class="field">
          <div class="control">
            <input id="contact_lastname" data-attr="contact_lastname" class="input" :class="{'is-danger': errors.contact_lastname}" type="text" :value="attributes.contact_lastname" placeholder="Last name">
          </div>
          <p class="help is-error" v-if="errors.contact_lastname">
            {{ errors.contact_lastname}}
          </p>
          <p v-else v-html="'&nbsp;'"/>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="contact_description">Description</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input id="contact_description" data-attr="contact_description" class="input" :class="{'is-danger': errors.contact_description}" type="text" :value="attributes.contact_description" placeholder="First name">
          </div>
          <p v-if="errors.contact_description" class="help is-error">
            {{ errors.contact_description }}
          </p>
          <p v-else v-html="'&nbsp;'"/>
        </div>
      </div>
    </div>


    <div class="field is-horizontal">
      <div class="field-label">
        <label class="label" for="email">Contact Information</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input id="email" data-attr="contact_email" class="input" :class="{'is-danger': errors.contact_email}" type="email" :value="attributes.contact_email" placeholder="Email">
          </div>
          <p class="help is-error" v-if="errors.contact_email">
            {{ errors.email}}
          </p>
          <p v-else v-html="'&nbsp;'"/>
        </div>
        <div class="field">
          <div class="control">
            <input
              id="tel"
              data-attr="contact_tel"
              class="input"
              :class="{'is-danger': errors.contact_tel}"
              type="tel"
              :value="attributes.contact_tel"
              @change="prependDefaultCountryCode"
              placeholder="Phone"
            />
          </div>
          <p class="help is-error" v-if="errors.contact_tel">
            {{ errors.contact_tel }}
          </p>
          <p v-else v-html="'&nbsp;'"/>
        </div>
        <div class="field">
          <div class="control">
            <input
              id="tel_secondary"
              data-attr="contact_tel_secondary"
              class="input"
              :class="{'is-danger': errors.contact_tel_secondary}"
              type="tel"
              :value="attributes.contact_tel_secondary"
              placeholder="Secondary phone"
              @change="prependDefaultCountryCode"
            />
          </div>
          <p class="help is-error" v-if="errors.contact_tel_secondary">
            {{ errors.fax }}
          </p>
          <p v-else v-html="'&nbsp;'"/>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label">
        <label class="label"></label>
      </div>
      <div class="field-body">
        <div class="field">
          <span class="control">
            <button type="submit" class="button" @submit="handleSubmit">
              <span class="icon">
                <span class="fa fa-save" />
              </span>
              <span class="button-text">Commit</span>
            </button>
          </span>
          <span class="control">
            <button class="button" @click.prevent="handleCancel">
              <span class="icon">
                <span class="fa fa-times" />
              </span>
              <span class="button-text">Cancel</span>
            </button>
          </span>
        </div>
      </div>
    </div>

  </form>
</template>

<script>

  export default {
    name: 'contact-form',

    props: {
      attributes: {
        type: Object,
        required: true,
      },
      errors: {
        type: Object,
        default: () => ({}),
      },
    },

    methods: {

      async handleAttributeChange (event) {
        let {value, dataset} = event.target;
        let {attr} = dataset;
        attr && this.$emit('onChangeAttribute', {[attr]: value});
      },

      async handleSubmit (event) {
        event.preventDefault();
        this.$emit('onSubmit');
      },

      async handleCancel (event) {
        event.preventDefault();
        this.$emit('onCancel');
      },

      async prependDefaultCountryCode (event) {
        let {name, value} = event.target;
        if (value && !/^\+\d+\s/.test(value)) {
          event.target.value = `+1 ${value}`;
        }
      },
    },

  };

</script>
