<template>

  <div class="dropdown-menu" role="menu">
    <div v-if="records.length || buttonSlotFilled" class="dropdown-content">
      <a
        v-for="attributes in records"
        :key="attributes.id"
        class="dropdown-item"
        @click="$emit('choose', attributes.id)"
      >
        <address v-html="formatJob(attributes)" />
      </a>
      <slot v-if="buttonSlotFilled" name="buttons" class="dropdown-item"/>
    </div>

    <div v-else-if="progress" class="dropdown-item">
      <spinner />
    </div>
    <p v-else-if="valid" class="dropdown-item">No matches.</p>

  </div>

</template>
<script>

  import format from '~/lib/domain/format';
  import Spinner from 'vue-spinner/src/PulseLoader.vue';
  import html from '~/lib/html';

  export default {
    name: 'job-search-results',
    props: {
      records: {
        type: Array,
        required: true,
      },
      progress: {
        type: Boolean,
        required: false,
      },
      valid: {
        type: Boolean,
        required: true,
      },
    },
    components: {
      'spinner': Spinner,
    },
    computed: {
      buttonSlotFilled () {
        return !!this.$slots.buttons;
      },
    },
    methods: {
      formatJob (attributes) {
        let {id, customer, property} = attributes;
        let customer_desc = customer.name || `${customer.firstname} ${customer.lastname}`;
        let property_desc = format.address(property).map(html.encode).join('; ');
        return `[${id}] ${customer_desc} @ ${property_desc}`;
      },
    },
  };
</script>
