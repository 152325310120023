import Module from '~/lib/vue/modules/module';
import {Warranty} from '~/lib/domain/warranty';
import {WARRANTY_TERM_UNIT_YEAR, WARRANTY_TYPE_WORKMANSHIP, WARRANTY_TYPE_MANUFACTURER} from '~/lib/domain/constants';
import {InvalidArgument, ParseError, KeyError} from '~/lib/exceptions';
import {duration} from '~/lib/date';
import lang from '~/lib/lang';

export class WarrantyModule extends Module {

  get defaultState () {
    return {
      warranties: [],
    };
  }

  get defaultWarranty () {
    return {
      type: WARRANTY_TYPE_MANUFACTURER,
      lifetime: false,
      exclusion: false,
      unit: WARRANTY_TERM_UNIT_YEAR,
      length: 3,
      description: '',
    };
  }

  get defaultErrorDictionary () {
    return {
      type: null,
      lifetime: null,
      exclusion: null,
      unit: null,
      length: null,
      description: null,
    };
  }

  get state () {
    return {...this.defaultState};
  }

  get getters () {
    return {
      warranties: (state) => state.warranties,
      params: (state) => state.warranties.map(w => Warranty.params(w)),
    };
  }

  get mutations () {
    return {
      warranties: (state, warranties) => state.warranties = ([...warranties]),
    };
  }

  get actions () {
    return {

      initialize: async (store) => {
        let {warranties} = this.defaultState;
        store.dispatch('setWarranties', warranties);
      },

      receiveWarranties: (store, warranties) => {
        if (!Array.isArray(warranties)) {
          throw new InvalidArgument('expected warranties arg to be an array');
        }

        let normalized = warranties.map((w) => {
          let {type, exclusion, lifetime, term, description, starts_at, expires_at} = w;
          let unit = null;
          let length = null;

          if (term) {
            let tokens = duration.tokenize(term);
            unit = tokens.unit;
            length = tokens.length;
          }

          return {type, exclusion, lifetime, unit, length, description, starts_at, expires_at};
        });

        store.dispatch('setWarranties', normalized);
      },

      receiveErrors: (store, errors) => {
        if (!Array.isArray(errors)) {
          throw new InvalidArgument('expected errors arg to be an array');
        }
        let normalized = errors.reduce((result, error) => {
          let [stem, index, attribute] = error.field.split('.');
          if (stem === 'warranties') {
            if (result[index] === undefined) {
              result[index] = this.defaultErrorDictionary;
            }
            result[index][attribute] = error.message;
          }
        }, {});
        store.dispatch('setErrors', normalized);
      },

      setWarranties: (store, warranties) => {
        if (!Array.isArray(warranties)) {
          throw new InvalidArgument('expected warranties arg to be an array');
        }
        store.commit('warranties', warranties);
      },

      addWarranty: (store) => {
        let {warranties} = store.state;
        let warranty = this.defaultWarranty;
        if (warranties.length === 0) {
          warranty.type = WARRANTY_TYPE_WORKMANSHIP;
        }
        warranties.push(warranty);
        store.dispatch('setWarranties', warranties);
      },

      updateWarranty: (store, {index, attributes}) => {
        this.assertValidIndex(store, index);
        let warranties = [...store.state.warranties];
        let warranty = {...warranties.splice(index, 1).pop()};

        lang.object.merge(warranty, attributes);

        let {defaultWarranty} = this;

        if (!warranty.unit) {
          warranty.unit = defaultWarranty.unit;
        }
        if (!warranty.length) {
          warranty.length = defaultWarranty.length;
        }

        warranties.splice(index, 0, warranty);
        store.dispatch('setWarranties', warranties);
      },

      removeWarranty: async (store, index) => {
        this.assertValidIndex(store, index);
        let warranties = [...store.state.warranties];
        warranties.splice(index, 1);
        store.dispatch('setWarranties', warranties);
      },

      setErrors: (store, errors) => {
        if (!Array.isArray(errors)) {
          throw new InvalidArgument('expected errors arg to be an array');
        }
        store.commit('errors', errors);
      },

      clearErrors: (store) => {
        store.commit('errors', []);
      },
    };
  }

  assertValidIndex (store, index) {
    let {warranties} = store.state;
    if (typeof index !== 'number' || index < 0) {
      throw new InvalidArgument('expected unsigned integer index argument');
    }
    if (index >= warranties.length) {
      throw new KeyError('invalid array index');
    }
  }

}

export default WarrantyModule;
