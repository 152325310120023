<template>

  <page>

    <modal
      :open="modalOpen"
      @close="closeModal"
    >
      <div class="box" slot="body">
        <p class="subtitle">Accept Optional Work</p>
        <acceptance-form
          :attributes="document.attributes"
          :optionals="documentOptionals"
          @update="updateDocumentOptional"
          @submit="handleSubmitAcceptanceForm"
          @cancel="cancelDocumentEdit"
        />
      </div>
    </modal>

    <object-tag slot="page-title" type="Document" :identifier="guessDocumentIdFromRoute()" />

    <radio
      slot="page-search"
      :value="currentDocumentState"
      :buttons="documentStateButtonDefinitions"
      @change="handleDocumentStateChange"
    />

    <div slot="page-buttons">
      <btn v-if="documentIsAccepted && documentOptionals.length" @click="openModal" :tooltip="'Open optional work acceptance dialog'">
        <icon slot="icon" :classes="['fa', 'fa-check']" />
        Accept Optional Work
      </btn>
      <btn @click="printPdf" :class="{'is-loading': printing}" :tooltip="'Print PDF'">
        <icon slot="icon" :classes="['fa', 'fa-print']" />
        Print
      </btn>
      <btn v-if="documentIsDraft" :route="{name: 'document:edit', params: {id: $route.params.id}}" :tooltip="'Edit this document'">
        <icon slot="icon" :classes="['fa', 'fa-edit']"/>
        Edit
      </btn>
      <btn
      <btn v-if="documentIsDraft" @click="handleDelete" :tooltip="'Delete this document'">
        <icon slot="icon" :classes="['fa', 'fa-trash']"/>
        Delete
      </btn>
    </div>

    <div class="columns">
      <div class="column">
        <document-card
          :document="document.attributes"
          :job="job.attributes"
          @click:customer="navigateToCustomer(job.attributes.customer.id)"
          @click:property="navigateToProperty(job.attributes.property.id)"
          @click:job="navigateToJob(job.attributes.id)"
        />
      </div>
      <div class="column">
        <div v-if="pdf">
          <embed type="application/pdf" :src="pdf" width="100%" height="500" style="min-height:500px"/>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="columns">
        <div class="column">
          <p class="subtitle">{{ lineItemsHeading }}</p>
          <div v-for="item in document.attributes.items" class="media">
            <div class="media-content">
              <p>{{ item.text }}</p>
            </div>
          </div>
        </div>
        <div class="column">
          <p class="subtitle">Optional Work</p>
          <div v-if="documentOptionals.length" v-for="optional in document.attributes.optionals" class="media">
            <div class="media-content">
              <p>{{ optional.text }}</p>
            </div>
            <div class="media-right">
              <p>${{ optional.sum }}</p>
            </div>
          </div>
          <p v-else>
            None
          </p>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="columns">
        <div class="column">
          <p class="subtitle">Warranties</p>
          <div class="media" v-if="document.attributes.warranties.length">
            <div class="media-content">
              <warranty v-for="(warranty, i) in document.attributes.warranties" :key="i" :attributes="warranty" />
            </div>
          </div>
          <p v-else>
            None
          </p>
        </div>
        <div class="column">
          <p class="subtitle">Additional Terms</p>
          <div v-for="item in document.attributes.terms" class="media" v-if="document.attributes.terms.length">
            <div class="media-content">
              <p>{{ item.text }}</p>
            </div>
          </div>
          <p v-else>
            None
          </p>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="columns">
        <div class="column">
        </div>
        <div class="column">

        </div>
      </div>
    </div>


  </page>
</template>
<script>

  import {mapState, mapGetters, mapActions} from 'vuex';
  import DocumentContext from './mixins/DocumentContext.vue';
  import DocumentCard from '~/components/document/DocumentCard.vue';
  import ObjectTag from '~/components/page/ObjectTag.vue';
  import Warranty from '~/components/warranty/Warranty.vue';
  import P from '~/lib/promise';
  import format from '~/lib/domain/format';
  import Icon from '~/components/Icon.vue';
  import Btn from '~/components/Btn.vue';
  import Page from '~/components/Page.vue';
  import ModalContext from './mixins/ModalContext.vue';
  import Modal from '~/components/Modal.vue';
  import AcceptanceForm from '~/components/document/AcceptanceForm.vue';
  import Radio from '~/components/Radio.vue';
  import {DOCUMENT_STATE_ACCEPTED} from '~/lib/domain/constants';

  export default {

    mixins: [DocumentContext, ModalContext],

    components: {
      ObjectTag,
      DocumentCard,
      Warranty,
      Icon,
      Btn,
      Page,
      AcceptanceForm,
      Modal,
      Radio,
    },

    data () {
      return {
        docIncludes: ['attachments'],
      };
    },

    methods: {

      async handleDelete () {
        if (window.confirm('Are you sure you want to delete this document?')) {
          let {job_id} = this.document.attributes;
          let ok = await this.deleteDocument();
          if (ok) {
            this.$router.go(-1);
          }
        }
      },

      async handleSubmitAcceptanceForm () {
        await this.commitDocumentEdit();
        await this.closeModal();
      },

      async cancelDocumentEdit () {
        this.propagateDocumentAttributes();
        await this.closeModal();
      },

      async handleDocumentStateChange (state) {
        await this.attemptDocumentStateTransition(state);
        if (this.documentIsAccepted && this.documentOptionals.length) {
          await this.openModal();
        }
      },
    },

    mounted () {
      (async() => {
        await this.initializeDocumentContext();
        await this.loadJob({id: this.document.attributes.job_id});
        this.assignCustomerAttributes(this.job.attributes.customer);
        this.assignPropertyAttributes(this.job.attributes.property);
        await this.renderPdf();
      })();
    },
  };

</script>
