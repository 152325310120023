import {Component} from '~/lib/foundation';
import exceptions from '~/lib/exceptions';
import uuid from '~/lib/uuid';
import {Deferred} from '~/lib/promise';

/**
 * Job wraps a task function with a runnable interface
 */
class Job extends Component {

  get defaults () {
    return {
      ...super.defaults,
      id: uuid.v4(),
      task: null,
      parameters: [],
      ttl: null,
    };
  }

  constructor (...args) {
    super(...args);

    let task = this.option('task');
    let parameters = this.option('parameters');
    if (typeof task !== 'function') {
      throw new exceptions.InvalidArgument('task must be a function');
    }

    this.task = task;
    this.parameters = parameters;

    this._evictable = new Deferred();
    this.evictable = this._evictable.promise;

    this._finished = new Deferred();
    this.finished = this._finished.promise;
  }

  get id () {
    return this.option('id');
  }

  async run () {
    if (this._ran) {
      throw new exceptions.Forbidden('job may not run more than once');
    }

    this._ran = true;

    let noop = () => {};
    let ttl = this.option('ttl');
    let timeout = null;

    if (ttl !== null) {
      let expire = () => {
        let e = new exceptions.Expired(`job timed out after ${ttl}ms`);
        reject(e);
      };
      timeout = setTimeout(expire, ttl);
    }

    let done = async (next) => {
      clearTimeout(timeout);
      this._evictable.resolve();
      await this.evictable;
      next();
    };

    try {
      let result = await this.task(...this.parameters);
      done(() => this._finished.resolve(result));
    }
    catch (e) {
      done(() => this._finished.reject(e));
    }

    return this.finished;
  }
}

export default Job;
