import Module from '~/lib/vue/modules/module';
import datatable from './datatable';
import record from './record';
import search from './search';
import list from './list';
import territories from './territories';

export default (services, namespace='property') => {
  return Module.create({
    namespace,
    services,
    modules: {
      datatable,
      list,
      record,
      search,
      territories,
    },
  });
};
