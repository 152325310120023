<template>

  <div class="card">
    <slot name="dropdown"></slot>
    <div class="card-content">
      <div class="level">
        <div class="level-left">
          <p class="subtitle">{{ title }}</p>
        </div>
        <div class="level-right">
          <slot name="buttons"/>
        </div>
      </div>
      <div class="level">
        <div class="level-item">
          <GmapMap
            v-if="attributes.coordinates"
            :center="attributes.coordinates"
            :zoom="15"
            style="width: 500px; height: 400px"
            :options="mapOptions"
          >
            <GmapMarker
              :position="attributes.coordinates"
              :clickable="true"
              :draggable="true"
              @click="center=attributes.coordinates"
            />
          </GmapMap>
        </div>
      </div>

      <table class="table is-fullwidth is-size-6">
        <tbody>
          <tr>
            <td style="width: 0;"><label>Address</label></td>
            <td v-html="htmlAddress"/>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</template>
<script>

  import PropertyBase from './PropertyBase.vue';

  export default {
    mixins: [PropertyBase],
    data () {
      return {
        mapOptions: {
          mapTypeId: 'hybrid',
        },
      };
    },
  };

</script>
