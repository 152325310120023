<template>

  <span :class="{tag: true, [color]: true}">
    {{ text }}
  </span>

</template>
<script>

  import constants from '~/lib/domain/constants';
  const {DOCUMENT_STATES} = constants;

  export default {
    name: 'document-status',

    props: {
      document: {
        type: Object,
        required: true,
      },
    },

    computed: {
      currentState () {

        return [...this.document.states].pop();
      },

      color () {
        let map ={
          [constants.DOCUMENT_STATE_DRAFT]: 'is-light',
          [constants.DOCUMENT_STATE_PUBLISHED]: 'is-warning',
          [constants.DOCUMENT_STATE_ACCEPTED]: 'is-success',
        };
        let current = this.currentState;
        if (current) {
          return map[current.name];
        }
        return 'is-light';
      },

      text () {
        let current = this.currentState;
        if (current) {
          return DOCUMENT_STATES[current.name];
        }
        return '';
      },
    },
  };

</script>
