import * as path from '~/lib/path';
import fetch from 'isomorphic-fetch';
import {Component} from '~/lib/foundation';
import {UnexpectedOutcome, Expired} from '~/lib/exceptions';
import {HttpException} from './exceptions';
import Response from './response';

const noop = () => {};

const normalize = {

  /**
   * @param {String} url
   * @return {String}
   */
  url (url) {
    if (url.indexOf('http') === 0) {

      // strip trailing slash if the url is fully qualified
      if (url[url.length - 1] === '/') {
        return url.slice(0, -1);
      }
    }

    if (url === '//') {
      return '/';
    }

    return url;
  },

  /**
   * @param {String} path
   * @return {String}
   */
  path (...tokens) {
    let output = path.normalize(path.join(...tokens));

    if (!output.length) {
      return '/';
    }
    if (output[0] !== '/') {
      return `/${output}`;
    }
    return output;
  },

};

class Client extends Component {

  get defaults () {
    return {
      ...super.defaults,
      root: null, // base url
      errors: true,
      timeout: 60000,
      resolvers: {
        fetch: () => fetch,
      },
      fetchopts: {},
      authorization: noop,
      unauthorized: noop,
    };
  }

  /**
   * The URL root
   * @property
   * @readonly
   * @type {String}
   */
  get root () {
    return normalize.url(this.option('root'));
  }

  get authorization () {
    return this.option('authorization')();
  }

  /**
   * Derive a fully qualified URL from the url root and the given path
   * @param {String} path
   * @return {String}
   */
  url (...tokens) {
    if (tokens[0].match(/^http(s)?:\/\//)) {
      return tokens[0];
    }
    return `${this.root}${normalize.path(...tokens)}`;
  }

  /**
   * Create a fetch request options object
   * @param {Object} options
   * @return {Object}
   */
  fetchopts (options={}) {
    let out = {
      ...this.option('fetchopts'),
      ...options,
    };

    if (!out.headers) {
      out.headers = {};
    }

    if (!out.headers.hasOwnProperty('Authorization')) {
      let {authorization} = this;
      if (authorization) {
        out.headers['Authorization'] = authorization;
      }
    }

    return out;
  }
}

const create_client_method = (method) => {

  /**
   * @param {String} url
   * @param {Object} fetchopts - Fetch request options
   * @return {~/lib.rest.Response|Fetch.Response}}
   * @throws {Error}
   */
  Client.prototype[method] = async function(url, fetchopts={}) {
    let ttl = this.option('timeout', null);
    let fetch = this.make('fetch');
    let timeout;

    let expire = () => {
      throw new Expired(`request timed out after ${ttl} ms`);
    };

    if (ttl) {
      timeout = setTimeout(expire, ttl);
    }

    let fetch_response = await fetch(this.url(url), this.fetchopts({
      ...fetchopts,
      method: method.toUpperCase(),
    }));

    clearTimeout(timeout);

    if (!fetch_response) {
      throw new UnexpectedOutcome('fetch algo returned an unexpected value');
    }

    if (fetchopts.raw) {
      return fetch_response;
    }

    let response = await Response.parse(fetch_response);
    let {ok, status} = response;

    if (!ok) {
      if (status === 401) {
        this.option('unauthorized')();
      }
      if (this.option('errors')) {
        throw new HttpException('HTTP request failed', response);
      }
    }

    return response;
  };
};

['head', 'get', 'put', 'delete', 'post', 'patch'].forEach(create_client_method);

export default Client;
