<template>

  <button v-if="tooltip" :class="buttonClasses" class="button tooltip is-tooltip-bottom" :disabled="disabled" @click.prevent="handleClick" :data-tooltip="tooltip">
    <input ref="input" type="radio" :name="name" :value="value" :checked="checked" style="display: none;">
    <slot></slot>
  </button>

  <button v-else :class="buttonClasses" class="button" :disabled="disabled" @click.prevent="handleClick">
    <input ref="input" type="radio" :name="name" :value="value" :checked="checked" style="display: none;">
    <slot></slot>
  </button>

</template>
<script>

  export default {

    props: {
      name: {
        type: String,
        required: true,
      },
      value: {
        required: true,
      },
      label: {
        type: String,
        required: false,
        default: function () {
          return this.value;
        },
      },
      checked: {
        type: Boolean,
        default: false,
      },
      color: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      rounded: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      tooltip: {
        type: String,
        required: false,
        default: null,
      },
    },

    computed: {

      buttonClasses () {
        let classes = {
          button: true,
          'is-dark': this.checked,
          'is-loading': this.loading,
          'is-small': true,
        };
        if (this.color) {
          classes[this.color] = true;
        }
        return classes;
      },
    },

    methods: {
      handleClick (event) {
        let {name, value} = this.$refs.input;
        this.$emit('choose', value);
      },
    },
  }

</script>
