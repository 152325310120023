import Module from '~/lib/vue/modules/module';
import {InvalidArgument} from '~/lib/exceptions';

class DocumentItemsModule extends Module {

  get defaults () {
    return {
      ...super.defaults,
      normalize: attrs => attrs,
      defaultItem: {
        text: '',
      },
      defaultItems: (defaultItem) => [],
    };
  }

  get defaultItem () {
    return this.option('defaultItem');
  }

  get defaultState () {
    return {
      items: this.option('defaultItems')(this.defaultItem),
    };
  }

  get state () {
    return this.defaultState;
  }

  get getters () {
    return {
      items: (state) => state.items,
    };
  }

  get mutations () {
    return {
      items: (state, items) => state.items = [...items],
    };
  }

  get actions () {
    return {

      initialize: async (store) => {
        store.commit('items', this.defaultState.items);
      },

      addItem: (store) => {
        let next = [...store.state.items];
        let item = this.defaultItem;
        next.push(item);
        store.commit('items', next);
      },

      deleteItem: (store, index) => {
        let next = [...store.state.items];
        next.splice(index, 1);
        store.dispatch('updateItems', next);
      },

      updateItems: (store, items) => {
        store.commit('items', items);
      },

      updateItem: (store, {index, attrs}) => {
        let items = [...store.state.items];
        let item = items[index];
        let normalize = this.option('normalize');
        let next = normalize({...item, ...attrs});

        items[index] = next;
        store.dispatch('updateItems', items);
      },

      pushItems: (store, items) => {
        let next = [...store.state.items, ...items.map((item) => {
          return {...this.defaultItem, ...item};
        })];
        store.dispatch('updateItems', next);
        store.dispatch('pruneEmptyItems');
      },

      sortItems: (store, indexes) => {
        let items = [...store.state.items];
        let next = [];
        indexes.map(index => next.push(items[index]));
        store.commit('items', next);
      },

      pruneEmptyItems: (store) => {
        let notempty = obj => obj.text;
        let next = store.state.items.filter(notempty);
        store.commit('items', next);
      },
    };
  }
}

export default DocumentItemsModule;
