<template>
  <page>

    <modal
      :open="modalOpen"
      @close="closeModal"
    >
      <div v-if="currentForm === 'customer'" class="box" slot="body">
        <object-tag
          slot="header"
          type="Customer"
          :identifier="customer.attributes.id"
        />
        <customer-form-container
          @commit="afterCommitCustomerForm"
          @cancel="afterCancelCustomerForm"
        />
      </div>
      <div v-else-if="currentForm === 'property'" class="box" slot="body">
        <object-tag
          slot="header"
          type="Property"
          :identifier="property.attributes.id"
        />
        <property-form
          :attributes="property.form"
          :errors="property.errors"
          :territories="propertyTerritories"
          @onSubmit="commitPropertyEdit"
          @onCancel="cancelPropertyEdit"
          @onChangeAttribute="assignPropertyFormAttributes"
          @onChangeCountry="setPropertyCountry"
          @onChangeProvince="setPropertyProvince"
        />
      </div>
      <div v-else-if="currentForm === 'contact'" class="box" slot="body">
        <p slot="header" class="subtitle">Job Contact</p>
        <contact-form
          :attributes="job.form"
          :errors="job.errors"
          @onSubmit="commitJobContactEdit"
          @onCancel="closeModal"
          @onChangeAttribute="assignJobFormAttributes"
        />
      </div>
    </modal>

    <object-tag slot="page-title" type="Job" :identifier="guessJobIdFromRoute()"/>

    <div slot="page-buttons">
      <btn :route="{name: 'job:sheet', params: {id: $route.params.id}}">
        <icon slot="icon" :classes="['fa','fa-file-alt']"/>
        Crew Sheet
      </btn>
      <btn @click="handleDelete">
        <icon slot="icon" :classes="['fa','fa-trash']" />
        Delete Job
      </btn>
      <btn v-if="!jobContact" @click="openContactModal">
        <icon slot="icon" :classes="['fa','fa-address-card']" />
        Add Contact
      </btn>
    </div>

    <radio
      slot="page-search"
      name="state"
      :value="currentJobState"
      :buttons="jobStateButtonDefinitions"
      @change="attemptJobStateTransition"
    />

    <div class="columns">
      <div class="column is-one-half">
        <customer-card :attributes="customer.attributes">
          <div slot="buttons">
            <btn :route="{name: 'customer:detail', params: {id: customer.attributes.id}}" :flat="true" style="color: inherit;">
              <icon :classes="['fa', 'fa-info-circle']" :tooltip="'View Customer'" />
            </btn>
            <btn @click="openCustomerModal" :flat="true" style="color: inherit;">
              <icon :classes="['fa', 'fa-edit']" :tooltip="'Edit Customer'" />
            </btn>
            <btn :route="{name: 'job:edit', params: {id: $route.params.id}, query: {ch: 'customer'}}" :flat="true" style="color: inherit;">
              <icon :classes="['fa', 'fa-random']" :tooltip="'Change Customer'" />
            </btn>
          </div>
        </customer-card>
        <contact-card v-if="jobContact" :attributes="job.attributes.contact">
          <div slot="buttons">
            <btn @click="openContactModal" :flat="true">
              <icon :classes="['fa', 'fa-edit']" :tooltip="'Edit Contact'" />
            </btn>
            <btn @click="deleteJobContact" :flat="true">
              <icon :classes="['fa', 'fa-trash']" :tooltip="'Delete Contact'" />
            </btn>
          </div>
        </contact-card>
      </div>
      <div class="column is-one-half">
        <property-card :attributes="property.attributes">
          <div slot="buttons">
            <btn :route="{name: 'property:detail', params: {id: property.attributes.id}}" :flat="true" style="color: inherit;">
              <icon :classes="['fa', 'fa-info-circle']" :tooltip="'View Property'" />
            </btn>
            <btn @click="openPropertyModal" :flat="true" style="color: inherit;">
              <icon :classes="['fa', 'fa-edit']" :tooltip="'Edit Property'" />
            </btn>
            <btn :route="{name: 'job:edit', params: {id: $route.params.id}, query: {ch: 'property'}}" :flat="true" style="color: inherit;">
              <icon :classes="['fa', 'fa-random']" :tooltip="'Change Property'" />
            </btn>
          </div>
        </property-card>
      </div>
    </div>

    <section class="section">
      <div class="columns">
        <div class="column is-two-thirds">
          <h2 class="subtitle">Documents</h2>
        </div>
        <div class="column is-one-third">
          <div class="buttons is-pulled-right">
            <router-link
              :to="{name: 'document:create', params: {id: job.attributes.id}}"
              class="button is-pulled-right">
              <span class="icon"><i class="fa fa-plus" /></span>
              <span class="button-text">New Document</span>
            </router-link>
          </div>
        </div>
      </div>
      <data-table
        class="table"
        :columns="documentTable.columns"
        :rows="documentTable.rows"
        :limit="documentTable.limit"
        :total="documentTable.total"
        @onClickRow="navigateToDocument"
      >
        <data-pager
          slot="footer"
          :total="documentTable.total"
          :limit="documentTable.limit"
          :page="documentTable.page"
        />
      </data-table>
    </section>

    <section class="section">
      <h2 class="subtitle">Installer Notes</h2>
      <note-widget>
        <note-list slot="list" :notes="jobNotes.notes" @delete="deleteJobNote" />
        <note-input slot="input" @add="addJobNote" />
      </note-widget>
    </section>

    <section class="section">
      <h2 class="subtitle">Media</h2>
      <drop-zone @files="handleFiles">
        <div class="attachments">
          <attachment
            v-for="(attachment, i) in jobAttachments.attachments"
            :key="attachment.rel + '/' + attachment.blob.sum"
            :index="i"
            :attributes="attachment"
            :clipboard="clipboard"
            @change="updateJobAttachment"
            @detach="detachJobBlob"
          />
        </div>
      </drop-zone>
    </section>

    <section v-if="shouldShowOwnerControls">
      <div>
        <label>
          Private
          <input type="checkbox" :checked="job.attributes.required_role === 'owner'" @change="togglePrivate" />
        </label>
      </div>
    </section>

  </page>
</template>

<script>
  import P from '~/lib/promise';
  import {mapState, mapGetters, mapActions} from 'vuex';
  import ObjectTag from '~/components/page/ObjectTag.vue';
  import CustomerCard from '~/components/customer/CustomerCard.vue';
  import CustomerDropdownMenu from '~/components/customer/CustomerDropdownMenu.vue';
  import PropertyCard from '~/components/property/PropertyCard.vue';
  import PropertyDropdownMenu from '~/components/property/PropertyDropdownMenu.vue';
  import {DataTable, DataPager} from '~/lib/vue/components/datatables';
  import JobContext from './mixins/JobContext.vue';
  import ModalContext from './mixins/ModalContext.vue';
  import DocumentContext from './mixins/DocumentContext.vue';
  import Modal from '~/components/Modal.vue';
  import CustomerFormContainer from '~/containers/CustomerFormContainer.vue';
  import PropertyForm from '~/components/property/PropertyForm.vue';
  import Radio from '~/components/Radio.vue';
  import NoteWidget from '~/components/notes/NoteWidget.vue';
  import NoteList from '~/components/notes/NoteList.vue';
  import NoteInput from '~/components/notes/NoteInput.vue';
  import Page from '~/components/Page.vue';
  import Icon from '~/components/Icon.vue';
  import Btn from '~/components/Btn.vue';
  import ContactForm from '~/components/job/ContactForm.vue';
  import ContactCard from '~/components/job/ContactCard.vue';
  import DropZone from '~/components/upload/DropZone.vue';
  import UploadContext from './mixins/UploadContext.vue';
  import Attachment from '~/components/attachments/Attachment.vue';
  import constants from '~/lib/domain/constants';

  export default {

    mixins: [JobContext, DocumentContext, ModalContext, UploadContext],

    components: {
      Attachment,
      DataTable,
      DataPager,
      DropZone,
      CustomerCard,
      PropertyCard,
      ObjectTag,
      CustomerDropdownMenu,
      PropertyDropdownMenu,
      Modal,
      CustomerFormContainer,
      PropertyForm,
      Radio,
      NoteWidget,
      NoteList,
      NoteInput,
      Page,
      Btn,
      Icon,
      ContactForm,
      ContactCard,
    },

    data () {
      let id = this.guessJobIdFromRoute();
      return {
        currentForm: null,
        uploadBucket: {
          id: `job:${id}`,
          title: `Job ${id}`,
          route: {...this.$route},
        },
        clipboard: this.$service('clipboard'),
        didReceiveKonamiCode: false,
      };
    },

    computed: {
      shouldShowOwnerControls () {
        const user = this.$store.getters['auth/user'];
        const roles = user.roles ?? [];
        return roles.includes(constants.ROLE_OWNER) && this.didReceiveKonamiCode;
      },
    },

    methods: {

      async handleDelete (event) {
        event && event.preventDefault();
        if (window.confirm('Really delete this job and all of its documents?\nThis operation is not reversible.')) {
          let ok = await this.deleteJob();
          if (ok) {
            this.$router.push({name: 'job:index'});
          }
        }
      },

      openCustomerModal () {
        this.currentForm = 'customer';
        this.openModal();
      },

      openPropertyModal () {
        this.currentForm = 'property';
        this.openModal();
      },

      openContactModal () {
        this.currentForm = 'contact';
        this.openModal();
      },

      async commitJobContactEdit () {
        await this.saveJob();
        this.closeModal();
      },

      async afterUpload (upload) {
        let {sum} = upload.file;
        let rel = constants.ATTACHMENT_REL_DEFAULT;
        await this.attachJobBlob({rel, sum});
      },

      async togglePrivate () {
        const required_role = this.job.attributes.required_role !== 'owner' ? 'owner' : null;
        this.assignJobFormAttributes({ required_role });
        await this.saveJob();
      },
    },

    mounted () {
      let id = this.guessJobIdFromRoute();
      (async () => {
        await P.all([
          this.initializeJobContext(),
          this.initializeDocumentTable({
            query: {filters: {match_jobs: [id]}},
          }),
        ]);
        await this.loadJob({id, includes: ['attachments']});
        this.propagateJobObjects();
        this.loadDocumentTable();
        this.assignDocumentAttributes({job_id: id});
      })();

      this.handleKonamiCode = () => {
        this.didReceiveKonamiCode = !this.didReceiveKonamiCode;
      };
      this.$service('eventbus').on('konami', this.handleKonamiCode);
    },

    beforeDestroy () {
      this.$service('eventbus').off('konami', this.handleKonamiCode);
    },
  };

</script>
