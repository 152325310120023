import DocumentItemsModule from '~/lib/vue/modules/document/items';

export default (services, namespace='items') => {
  return DocumentItemsModule.create({
    defaultItems: defaultItem => [defaultItem],
    defaultItem: {
      text: '',
    },
  });
};
