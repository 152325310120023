import DataTableModule from '~/lib/vue/modules/datatable';
import rest from '~/lib/rest';

/**
 * @param {~/lib.foundation.Container} services
 * @return {Object}
 */
export default (services, namespace='list') => {

  return DataTableModule.create({
    services,
    namespace,
    url: '/properties',
    columns: [
      {
        label: 'Street Address',
        value: row => `${row.address.split('\n')[0]}`,
      },
      {
        label: 'City',
        value: row => row.city,
      },
    ],
  });
};
