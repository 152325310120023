<template>

  <page>
    <object-tag slot="page-title" type="Dashboard"/>
    <p class="subtitle">Recent Jobs</p>
    <data-table
      class="table"
      :columns="jobTable.columns"
      :rows="jobTable.rows"
      :limit="jobTable.limit"
      :total="jobTable.total"
      @onClickRow="navigateToJob"
    />
  </page>

</template>
<script>

  import {mapGetters, mapActions} from 'vuex';
  import ObjectTag from '~/components/page/ObjectTag.vue';
  import InquiryContext from './mixins/InquiryContext.vue';
  import JobContext from './mixins/JobContext.vue';
  import {DataTable, DataPager} from '~/lib/vue/components/datatables';
  import P from '~/lib/promise';
  import Page from '~/components/Page.vue';

  export default {
    mixins: [InquiryContext, JobContext],

    components: {
      ObjectTag,
      DataTable,
      DataPager,
      Page,
    },

    mounted () {
      (async () => {

        let query = {
          sorts: {updated_at: 'desc'},
          paging: {limit: 10}
        };

        await P.all([
          this.initializeJobTable({query}),
        ]);

        await P.all([
          this.setJobTableLimit(10),
        ]);

      })();
    }

  };

</script>
