import {Component} from '~/lib/foundation';
import lang from '~/lib/lang';

class Bucket extends Component {

  get defaults () {
    return {
      /**
       * @type {String}
       */
      id: null,

      /**
       * @type {String}
       */
      title: null,

      /**
       * @type {Object}
       */
      route: null,
    };
  }

  constructor (...args) {
    super(...args);
    this.id = this.option('id');
    this.title = this.option('title');
    this.route = this.option('route');
    this._uploads = new Map();
  }

  get uploads () {
    return lang.collection.itemize(this._uploads).map(([id, upload]) => upload);
  }

  has (upload) {
    let id = typeof upload === 'object' ? upload.id : upload;
    return this._uploads.has(id);
  }

  add (upload) {
    this._uploads.set(upload.id, upload);
  }

  remove (upload) {
    let id = typeof upload === 'object' ? upload.id : upload;
    this._uploads.delete(id);
  }

  clear () {
    this._uploads.clear();
  }

  get size () {
    return this._uploads.size;
  }

  get empty () {
    return this._uploads.size === 0;
  }

  get properties () {
    let {id, title, route, uploads, size, empty} = this;
    return {id, title, route, uploads, size, empty};
  }
}

export default Bucket;
