
const create_error_dictionary = (errors) => {
  let dict = {};
  for (let error of errors) {
    let {field, message} = error;
    dict[field] = message;
  }
  return dict;
};

/**
 * Parse a Fetch API Response, which has an asynchronous interface, into a plain
 * object. The motivation here is to provide consumer contexts with purely
 * synchronous access to the response.
 */
class Response {

  /**
   * @async
   * @param {Fetch:Response} response - see https://developer.mozilla.org/en-US/docs/Web/API/Response
   * @return {Object}
   */
  static async parse (response) {
    if (!response) {
      throw new Error('expected a Fetch.Response');
    }
    if (typeof response.ok !== 'boolean') {
      throw new Error('expected input argument to have an "ok" property');
    }

    let {ok, status, redirected, type, url, headers} = response;
    let ctype = headers.get('Content-Type');
    let payload;

    if (ctype && ctype.indexOf('json') > -1) {
      payload = await response.json();
      if (payload.errors) {
        payload.errors = create_error_dictionary(payload.errors);
      }
    }
    else {
      payload = await response.text();
    }

    return {
      ok,
      status,
      redirected,
      type,
      url,
      headers,
      payload,
    };
  }
}

export default Response;
