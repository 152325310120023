<template>

  <div class="card" v-if="document.id">
    <div class="card-content">
      <div class="content">
        <table class="table">
          <tbody>
            <tr>
              <td><label>Type</label></td>
              <td>{{ documentType }}</td>
            </tr>
            <tr>
              <td><label>Status</label></td>
              <td><document-status :document="document" /></td>
            </tr>
            <tr>
              <td><label>Job</label></td>
              <td><a @click="$emit('click:job')">{{ job.id }}</a></td>
            </tr>
            <tr>
              <td><label>Customer</label></td>
              <td>
                <a @click="$emit('click:customer')">
                  {{ customerLine }}
                  <tag v-if="customerIsCompany">Business</tag>
                </a>
              </td>
            </tr>
            <tr>
              <td><label>Property</label></td>
              <td><a @click="$emit('click:property')">{{ propertyLine }}</a></td>
            </tr>
            <tr>
              <td class="tooltip" data-tooltip="Valuation without optional work"><label>Valuation</label></td>
              <td>
                <span class="is-size-4">{{ baseValuation }}</span>
              </td>
            </tr>
            <tr v-if="hasAcceptedOptions">
              <td class="tooltip" data-tooltip="Valuation including accepted optional work"><label>Extended Valuation</label></td>
              <td>
                <span style="margin-right: 0.5rem;">$</span>
                <span class="is-size-4">{{ extendedValuation }}</span>
              </td>
            </tr>
            <tr>
              <td><label>Deposit</label></td>
              <td>{{ formattedDeposit }}</a></td>
            </tr>
            <tr>
              <td><label>Created at</label></td>
              <td>{{ documentCreationTimestamp }}</td>
            </tr>
            <tr>
              <td><label>Last modified</label></td>
              <td>{{ documentModificationTimestamp }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</template>
<script>

  import ObjectTag from '~/components/page/ObjectTag.vue';
  import format from '~/lib/domain/format';
  import currency from '~/lib/currency';
  import constants from '~/lib/domain/constants';
  import date from '~/lib/date';
  import DocumentStatus from '~/components/document/DocumentStatus.vue';
  import Tag from '~/components/Tag.vue';

  export default {

    props: {
      document: {
        type: Object,
        required: true,
      },
      job: {
        type: Object,
        required: true,
      },
    },

    components: {
      DocumentStatus,
      ObjectTag,
      Tag,
    },

    computed: {

      hasAcceptedOptions () {
        let {optionals} = this.document;
        if (optionals.length) {
          return optionals.reduce((result, i) => result || i.accepted, false);
        }
        return false;
      },

      baseValuation () {
        let {sum} = this.document;
        let formatted = currency.format(sum || 0).split('.').shift();
        return formatted.replace(/^\-?$/, '');
      },

      extendedValuation () {
        let {sum, optionals, states} = this.document;
        let state = [...states].pop();
        let result = sum;
        if (state.name === constants.DOCUMENT_STATE_ACCEPTED) {
          let add = (out, i) => out + (i.accepted ? i.sum : 0);
          result += optionals.reduce(add, 0);
        }
        let formatted = currency.format(result || 0).split('.').shift();
        return formatted.replace(/^\$/, '');
      },

      formattedDeposit () {
        let {deposit} = this.document;
        let formatted = currency.format(deposit || 0).split('.').shift();
        return formatted.replace(/^\-?$/, '');
      },

      customerLine () {
        return format.customer.line(this.job.customer);
      },

      propertyLine () {
        let {property} = this.job;
        return format.address.line(property);
      },

      sumLabel () {
        let {type} = this.document;
        let headings = {
          [constants.DOCUMENT_TYPE_CONTRACT]: 'Contract Sum',
          [constants.DOCUMENT_TYPE_CHANGE_ORDER]: 'Change to Contract Sum',
        };
        return headings[type] || 'Price';
      },

      documentType () {
        return constants.DOCUMENT_TYPES[this.document.type];
      },

      documentStatus () {
        let current = [...this.document.states].pop();
        return constants.DOCUMENT_STATES[current.name];
      },

      documentCreationTimestamp () {
        let {created_at} = this.document;
        return date(created_at).format('MMMM D YYYY, h:mm A');
      },

      documentModificationTimestamp () {
        let {updated_at} = this.document;
        return date(updated_at).format('MMMM D YYYY, h:mm A');
      },

      customerIsCompany () {
        let {customer} = this.job;
        return customer && customer.type === constants.CUSTOMER_TYPE_COMPANY;
      },
    },
  };

</script>
