<template>

  <div class="layout">
    <slot name="body"/>
  </div>

</template>
<script>

  export default {};

</script>


