import DataRecordModule from '~/lib/vue/modules/datarecord';

export default (services, namespace='company') => {

  return DataRecordModule.create({
    namespace,
    services,
    type: 'customer',
    resource: '/customers',

    /**
     * Representation of the record from server to client
     */
    attributes: {
      id: null,
      type: null,
      name: null,
      firstname: null,
      lastname: null,
      email: null,
      tel: null,
      tel_secondary: null,
      company: null,
      addresses: {
        service: null,
        billing: null,
        mailing: null,
      },
    },

    /**
     * Blank representation of the record from client to server
     */
    form: {
      type: null,
      name: null,
      company_id: null,
      firstname: null,
      lastname: null,
      email: null,
      tel: null,
      tel_secondary: null,
      service_address: null,
      service_city: null,
      service_province_id: null,
      service_postcode: null,
      billing_address: null,
      billing_city: null,
      billing_province_id: null,
      billing_postcode: null,
      mailing_address: null,
      mailing_city: null,
      mailing_province_id: null,
      mailing_postcode: null,
    },

  });
};
