<template>

  <article class="media">
    <div class="media-content">
      <div class="content">
        <p>
          <strong>{{ summary }}</strong>
          <br>
          {{ attributes.description }}
        </p>
      </div>
    </div>
  </article>

</template>
<script>

  import {duration} from '~/lib/date';
  import inflection from '~/lib/inflection';
  import lang from '~/lib/lang';

  export default {

    props: {
      attributes: {
        type: Object,
        required: true,
      },
    },

    computed: {

      summary () {
        let {type, lifetime, exclusion} = this.attributes;
        let {term} = this;

        if (exclusion) {
          return `${lang.string.capitalize(type)} warranty exclusion`;
        }

        if (lifetime) {
          return `Lifetime ${type.toLowerCase()} warranty`;
        }

        let {singularize} = inflection;
        let termstr = singularize(term);
        if (termstr === 'a year') {
          termstr = '1 year';
        }
        return `${termstr} ${type.toLowerCase()} warranty`;
      },

      term () {
        let {term} = this.attributes;
        if (term) {
          return duration.humanize(term);
        }
        return '';
      },

    },
  };

</script>
