import Module from '~/lib/vue/modules/module';
import {InvalidArgument} from '~/lib/exceptions';

export const TYPES = {
  'contract': 'Contract',
  'change-order': 'Change Order',
  'credit-memo': 'Credit Memo',
};

export default (services, namespace='types') => {

  return Module.create({
    namespace,

    state: {
      allowed: TYPES,
      current: 'contract',
    },

    getters: {
      allowed: state => state.allowed,
      current: state => state.current,
    },

    mutations: {
      current: (store, value) => store.state.current = value,
    },

    actions: {
      set: (store, value) => {
        if (!TYPES.hasOwnProperty(value)) {
          throw new InvalidArgument(`not an allowed document type: ${value}`);
        }
        store.commit('current', value);
      },
    },

  });
};
