import videoImpl from './video';
import imageImpl from './image';

export const video = videoImpl;
export const image = imageImpl;

export default {
  image: imageImpl,
  video: videoImpl
};
