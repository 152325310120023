<template>

  <company-card v-if="customerIsCompany" :attributes="attributes" :compact="compact">
    <template slot="buttons">
      <slot name="buttons"/>
    </template>
  </company-card>
  <individual-card v-else :attributes="attributes" :compact="compact">
    <template slot="buttons">
      <slot name="buttons"/>
    </template>
  </individual-card>

</template>
<script>
  import CustomerBase from './CustomerBase.vue';
  import CompanyCard from './CompanyCard.vue';
  import IndividualCard from './IndividualCard.vue';

  export default {
    mixins: [CustomerBase],
    components: {
      CompanyCard,
      IndividualCard,
    },
    props: {
      attributes: {
        required: false,
        type: Object,
      },
      compact: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
  };

</script>
