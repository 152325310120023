<template>

  <div class="media" @change="handleChangeEvent">
    <div class="media-left">
      <div class="field">
        <div class="control">
          <div class="select">
            <select ref="typeInput">
              <option v-for="(label, value) in types" :value="value" :selected="value == attributes.type">{{ label }}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <div class="control" @change="handleExclusionRadio">
          <label class="radio">
            <input type="radio" value="false" :checked="attributes.exclusion === false">
            Include
          </label>
          <label class="radio">
            <input type="radio" value="true" :checked="attributes.exclusion === true">
            Exclude
          </label>
        </div>
      </div>

      <div class="field" v-if="!attributes.exclusion && attributes.type !== 'workmanship'">
        <div class="control" @change="handleLifetimeRadio">
          <label class="radio">
            <input type="radio" value="false" :checked="!attributes.lifetime">
            Term
          </label>
          <label class="radio">
            <input type="radio" value="true" :checked="attributes.lifetime">
            Lifetime
          </label>
        </div>
      </div>

      <div class="field is-horizontal" v-if="!attributes.exclusion && !attributes.lifetime">
        <div class="control">
          <input ref="termLengthInput" class="input" type="number" :value="attributes.length">
        </div>
        <div class="select">
          <div class="control">
            <select ref="termUnitInput">
              <option v-for="(label, value) in units" :value="value">{{ label }}</option>
            </select>
          </div>
        </div>
      </div>

    </div>
    <div class="media-content">
      <div class="field">
        <div class="control is-expanded has-icons-right" v-if="attributes.type != 'workmanship'">
          <textarea ref="descriptionInput" class="textarea" rows="4" :placeholder="descriptionPlaceholder">{{ attributes.description }}</textarea>
        </div>
      </div>
    </div>
    <div class="media-right">
      <div class="field">
        <div class="control">
          <span class="icon" @click="$emit('delete')" style="cursor: pointer;">
            <i class="fa fa-times"/>
          </span>
        </div>
      </div>
    </div>
  </div>

</template>
<script>

  import {duration} from '~/lib/date';
  import {WARRANTY_TYPES, WARRANTY_TYPE_WORKMANSHIP, WARRANTY_TERM_UNITS, WARRANTY_TERM_UNIT_YEAR} from '~/lib/domain/constants';
  import Checkbox from '~/components/Checkbox.vue';

  export default {
    name: 'warranty-field',

    components: {
      Checkbox,
    },

    props: {
      types: {
        type: Object,
        default: () => WARRANTY_TYPES,
      },
      units: {
        type: Object,
        default: () => WARRANTY_TERM_UNITS,
      },
      index: {
        type: Number,
        required: true,
      },
      attributes: {
        type: Object,
        required: true,
      },
    },

    data () {
      return {
        workmanship: WARRANTY_TYPE_WORKMANSHIP,
      };
    },

    computed: {

      descriptionPlaceholder () {
        let {exclusion} = this.attributes;
        if (exclusion) {
          return 'The product(s) not under warranty';
        }
        return 'The product(s) under warranty';
      },
    },

    methods: {

      handleChangeEvent (event) {
        let {index} = this;
        let {id} = this.attributes;
        let {termUnitInput, termLengthInput, descriptionInput, typeInput} = this.$refs;
        this.$emit('update', {
          index,
          attributes: {
            type: typeInput.value,
            description: descriptionInput ? descriptionInput.value : null,
            length: termLengthInput ? termLengthInput.value : null,
            unit: termUnitInput ? termUnitInput.value : null,
          },
        });
      },

      handleLifetimeRadio (event) {
        event.stopPropagation();
        let {index} = this;
        this.$emit('update', {
          index,
          attributes: {
            lifetime: event.target.value === 'true',
          },
        });
      },

      handleExclusionRadio (event) {
        event.stopPropagation();
        let {index} = this;
        this.$emit('update', {
          index,
          attributes: {
            exclusion: event.target.value === 'true',
          },
        });
      },
    },

  };

</script>
