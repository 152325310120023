<script>

  export default {
    data () {
      return {
        modalOpen: false,
      };
    },
    methods: {
      async openModal () {
        await this.onBeforeOpenModal();
        this.modalOpen = true;
        this.attachModalKeyListeners();
        await this.onAfterOpenModal();
      },
      async closeModal () {
        await this.onBeforeCloseModal();
        this.modalOpen = false;
        this.detachModalKeyListeners();
        await this.onAfterCloseModal();
      },
      async onBeforeOpenModal () {
        // override me
      },
      async onAfterOpenModal () {
        // override me
      },
      async onBeforeCloseModal() {
        // override me
      },
      async onAfterCloseModal () {
        // override me
      },
      attachModalKeyListeners () {
        if (!this.modalListener) {
          this.modalListener = (event) => {
            event.key === 'Escape' && this.closeModal();
          };
          document.addEventListener('keyup', this.modalListener);
        }
      },
      detachModalKeyListeners () {
        if (this.modalListener) {
          document.removeEventListener('keyup', this.modalListener);
          this.modalListener = null;
        }
      },
    },
  };

</script>

