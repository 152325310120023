export default {

  'buckets': [
    {
      id: 'prep',
      label: 'Preparation',
    },
    {
      id: 'trim',
      label: 'Roof trim and metal',
    },
    {
      id: 'flat',
      label: 'Flat roof',
    },
    {
      'id': 'shingle',
      label: 'Shingle roof',
    },
    {
      id: 'tile',
      label: 'Tile roof',
    },
    {
      id: 'metal',
      label: 'Metal roof',
    },
    {
      id: 'coating',
      label: 'Coating',
    },
    {
      id: 'accessories',
      label: 'Accessories',
    },
  ],

  'plays': [

    {
      bucket: 'prep',
      label: 'T&G T/O',
      items: [
        'PREPARATION: Remove and dispose of all existing main tar and gravel roof layers to expose wood deck. Inspect exposed areas for evidence of dry rot/breakage, perform repairs where necessary at a rate of $115 per man-hour, plus the cost of materials. Price includes removal of up to two existing layers. If any additional layers are found, a charge of $1 per-square-foot-per-layer will be added to the total price of work. Mitigation of ponding water is not included in this proposal. Ponding water on a roof is a result of insufficient slope in the structural roof deck and not caused by improper flat roof membrane installation. If you have reason to suspect ponding water on your roof and wish to have it mitigated, we can provide a separate quote for such work. Please be advised that replacing a roof is a major construction, and despite our best efforts to maintain a clean work environment, some dust and debris may occur in the interior space.',
      ],
    },

    {
      bucket: 'prep',
      label: 'Flat T/O',
      items: [
        'PREPARATION: Remove and dispose of existing flat roof layers to expose bare wood deck. Remove and dispose of existing adjoining roof metals, including flashing, penetration boots, and edge trims. Inspect exposed areas for evidence of dry rot/breakage, perform repairs where necessary at a rate of $115 per man-hour, plus the cost of materials. Price includes removal of up to two existing layers. If any additional layers are found, a charge of $1 per-square-foot-per-layer will be added to the total price of work. Mitigation of ponding water is not included in this proposal. Ponding water on a roof is a result of insufficient slope in the structural roof deck and not caused by improper flat roof membrane installation. If you have reason to suspect ponding water on your roof and wish to have it mitigated, we can provide a separate quote for such work. Please be advised that replacing a roof is a major construction, and despite our best efforts to maintain a clean work environment, some dust and debris may occur in the interior space.',
      ],
    },

    {
      bucket: 'prep',
      label: 'Flat & Pitched',
      items: [
        'PREPARATION: Remove and dispose of existing flat and pitched roof layers to expose bare wood deck. Remove and dispose of existing adjoining roof metals, including flashing, penetration boots, and edge trims. Inspect exposed areas for evidence of dry rot/breakage, perform repairs where necessary at a rate of $115 per man-hour, plus the cost of materials. Price includes removal of up to two existing layers. If any additional layers are found, a charge of $1 per-square-foot-per-layer will be added to the total price of work. Mitigation of ponding water in the flat roof section(s) is not included in this proposal. Ponding water on a roof is a result of insufficient slope in the structural roof deck and not caused by improper flat roof membrane installation. If you have reason to suspect ponding water on your roof and wish to have it mitigated, we can provide a separate quote for such work. Please be advised that replacing a roof is a major construction, and despite our best efforts to maintain a clean work environment, some dust and debris may occur in the interior space.',
      ],
    },

    {
      bucket: 'prep',
      label: 'Shingle T/O',
      items: [
        'PREPARATION: Remove and dispose of existing shingle roof layers to expose bare wood deck. Remove and dispose of existing adjoining roof metals, including flashing, penetration boots, and edge trims. Inspect exposed areas for evidence of dry rot/breakage, perform repairs where necessary at a rate of $115 per man-hour, plus the cost of materials. Price includes removal of up to two existing layers. If any additional layers are found, a charge of $1 per-square-foot-per-layer will be added to the total price of work. Please be advised that replacing a roof is a major construction, and despite our best efforts to maintain a clean work environment, some dust and debris may occur in the interior space.',
      ],
    },

    {
      bucket: 'prep',
      label: 'Tile',
      items: [
        'PREPARATION: Remove existing clay tiles and carefully stack for re-use. Remove and dispose of existing tile roof membrane to expose bare wood deck beneath. Remove and dispose of existing adjoining roof metals, flashing, and edge trims. Inspect exposed areas for evidence of dry rot/breakage, perform repairs where necessary at a rate of $115 per man-hour, plus the cost of materials. Price includes removal of up to two existing layers. If any additional layers are found, a charge of $1 per-square-foot-per-layer will be added to the total price of work. Please be advised that replacing a roof is a major construction, and despite our best efforts to maintain a clean work environment, some dust and debris may occur in the interior space.',
      ],
    },

    {
      bucket: 'prep',
      label: 'Coating/Silicone',
      items: [
        'PREPARATION: Clean and prepare existing main flat roof for upgrade. Execute manufacturer\'s recommended "pull test" to assure integrity of surface and compliance with specifications for adequate substrate. Mitigation of ponding water is not included in this proposal. Ponding water on a roof is a result of insufficient slope in the structural roof deck and not caused by improper flat roof membrane installation. If you have reason to suspect ponding water on your roof and wish to have it mitigated, we can provide a separate quote for such work.',
      ],
    },

    {
      bucket: 'prep',
      label: 'Roof Deck',
      items: [
        'PREPARATION: Remove and dispose of all existing membrane layers from deck. Inspect exposed areas for evidence of dry rot/breakage, perform repairs where necessary at a rate of $95 per man-hour, plus the cost of materials. Price includes removal of up to two existing layers. If any additional layers are found, a charge of $1 per-square-foot-per-layer will be added to the total price of work. Mitigation of ponding water is not included in this proposal. Ponding water on a roof is a result of insufficient slope in the structural roof deck and not caused by improper flat roof membrane installation. If you have reason to suspect ponding water on your roof and wish to have it mitigated, we can provide a separate quote for such work.',
      ],
    },

    {
      bucket: 'prep',
      label: 'Plywood',
      items: [
        'PLYWOOD: Supply and install new 1/2 inch CDX plywood to all steep roof areas.',
      ],
    },

    {
      bucket: 'trim',
      label: 'Standard',
      items: [
        'ROOF TRIMS AND METALS: Supply and install new, custom, roof metals, edge flashings, and drain outlets. Also supply and install new roof penetration base flashings.',
      ],
    },

    {
      bucket: 'trim',
      label: 'Stainless',
      items: [
        'ROOF TRIMS AND METALS: Supply and install new, custom, stainless steel roof metals, edge flashings, and drain outlets. Also supply and install new roof penetration base flashings.',
      ],
    },

    {
      bucket: 'trim',
      label: 'Copper',
      items: [
        'ROOF TRIMS AND METALS: Supply and install new, custom, solid copper roof metals, edge flashings, and drain outlets. Also supply and install new roof penetration base flashings.',
      ],
    },

    {
      bucket: 'trim',
      label: 'Coating',
      items: [
        'ROOF TRIMS AND METALS: Inspect and secure all flashings, penetrations, drains, and transitions to assure long term service.',
      ],
    },

    {
      bucket: 'flat',
      label: 'Membrane only',
      items: [
        'FLAT ROOF: Supply and install new, two-stage, heat applied, modified bitumen roofing system. Membrane color: White.',
      ],
    },

    {
      bucket: 'flat',
      label: 'Overlay',
      items: [
        'FLAT ROOF: Supply and install new heat-applied modified bitumen roofing membrane over existing flat roof surface.',
      ],
    },

    {
      bucket: 'flat',
      label: 'SSH Signature',
      items: [
        'FLAT ROOF: Supply and install new, Sure Secure Signature Hybrid system to all main flat roof area -- includes new, fluid-applied, Energy Star, elastomeric weathering layer over two stage, heat-applied, modified bitumen membrane.',
      ],
    },

    {
      bucket: 'flat',
      label: 'SSH Premier',
      items: [
        'FLAT ROOF: Supply and install new, Sure Secure Premier Hybrid system to all main flat roof area -- includes new, fluid-applied, 20-year warranty, GE silicone weathering layer over two stage, heat-applied, modified bitumen membrane.',
      ],
    },

    {
      bucket: 'flat',
      label: 'SSH Elite',
      items: [
        'FLAT ROOF: Supply and install new, Sure Secure Premier Hybrid system to all main flat roof area -- includes new, fluid-applied, 20-year warranty, GE silicone weathering layer over two stage, heat-applied, modified bitumen membrane.',
      ],
    },

    {
      bucket: 'shingle',
      label: 'HD',
      items: [
        'PITCHED ROOF: Supply and install new Sure Secure Steep Roof Method using new, architectural, HD shingle over premium underlayment to all steep roof area. Color: Charcoal.',
      ],
    },

    {
      bucket: 'shingle',
      label: 'Ultra HD',
      items: [
        'PITCHED ROOF: Supply and install new Sure Secure Steep Roof Method using new, architectural, Ultra HD lifetime shingle with premium underlayment and high-wind nailing pattern to all steep roof area. Color: Charcoal.',
      ],
    },

    {
      bucket: 'tile',
      label: 'Tile',
      items: [
        'TILE ROOF: Supply and install new fully adhered waterproof membrane to all mansard area. Re-install clay tile with stainless steel screws and copper wire fasteners consistent with tile design. Cracked, broken, or missing tiles will be replaced with matching pieces, $18 EACH additional charge.',
      ],
    },

    {
      bucket: 'tile',
      label: 'Roof Deck',
      items: [
        'ROOF DECK: Supply and install new Sure Secure Hybrid waterproof deck membrane optimized for floating ceramic or wood deck tiles (Note: Tiles to be supplied by others).',
      ],
    },

    {
      bucket: 'metal',
      label: 'Underlayment',
      items: [
        'UNDERLAYMENT: Supply and install new high temperature ice-and-water membrane over roof area.',
      ],
    },

    {
      bucket: 'metal',
      label: 'Standing seam',
      items: [
        'METAL ROOF: Supply and install new, custom length 1" mechanical lock standing seam roof panel system with custom accessories.',
      ],
    },

    {
      bucket: 'coating',
      label: 'Sealer',
      items: [
        'SEALER: Supply and install GE silicone "Seam Sealer" with reinforcing at all critical areas, including penetrations and necessary details.',
      ],
    },

    {
      bucket: 'coating',
      label: 'Coating',
      items: [
        'COATING: Supply and install elastomeric sealer/primer at all critical areas including reinforcing to assure integrity. Supply and install premium GE silicone elastomeric roof membrane system to all main flat roof area, in compliance with manufacturer\'s specifications for 20 year warranty.',
      ],
    },

    {
      bucket: 'coating',
      label: 'GE Enduris',
      items: [
        'COATING: Apply GE Enduris "bleed blocker" to prevent membrane layers underneath from discoloring silicone layer.',
      ],
    },

    {
      bucket: 'accessories',
      label: 'Gutters and downspout pipes',
      items: [
        'GUTTERS AND DOWNSPOUT PIPES: Supply and install new gutters and downpipes to replace existing.',
      ],
    },

  ], // plays
};
