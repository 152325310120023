<template>
  <div class="container">

    <modal
      :open="modalOpen"
      @close="closeModal"
    >
      <div v-if="currentForm === 'customer'" class="box" slot="body">
        <object-tag
          slot="header"
          type="Customer" identifier="new"
        />
        <customer-form-container @commit="afterCommitCustomerForm" @cancel="afterCancelCustomerForm" />
      </div>
      <div v-else class="box" slot="body">
        <object-tag
          slot="header"
          type="Property" identifier="new"
        />
        <property-form
          :attributes="property.form"
          :errors="property.errors"
          :territories="propertyTerritories"
          @onSubmit="commitPropertyEdit"
          @onCancel="cancelPropertyEdit"
          @onChangeAttribute="assignPropertyFormAttributes"
          @onChangeCountry="setPropertyCountry"
          @onChangeProvince="setPropertyProvince"
        />
      </div>
    </modal>

    <object-tag type="Job" :identifier="job.attributes.id || 'new'" />

    <job-details
      :customer="customer"
      :customerSearch="customerSearch"
      :resetCustomerSearch="resetCustomerSearch"
      :onChangeCustomerSearchTerms="handleCustomerSearchTerm"
      :onClearCustomerSearchTerms="resetCustomerSearch"
      :onClickCustomerSearchResult="loadCustomerSearchResult"
      :property="property"
      :propertySearch="propertySearch"
      :resetPropertySearch="resetPropertySearch"
      :onChangePropertySearchTerms="handlePropertySearchTerm"
      :onClearPropertySearchTerms="resetPropertySearch"
      :onClickPropertySearchResult="loadPropertySearchResult"
      :job="job"
      @customer:new="openCustomerModal"
      @property:new="openPropertyModal"
    >
      <div slot="customer-table">
        <p style="margin-top: 1em;">Recent Customers</p>
        <data-table
          slot="customer-table"
          class="table"
          :columns="customerColumns"
          :rows="customerTable.rows"
          :limit="customerTable.limit"
          :total="customerTable.total"
          @onClickRow="loadCustomerSearchResult"
        />
      </div>
      <div slot="property-table">
        <p style="margin-top: 1em;">Recent Properties</p>
        <data-table
          slot="property-table"
          class="table"
          :columns="propertyColumns"
          :rows="propertyTable.rows"
          :limit="propertyTable.limit"
          :total="propertyTable.total"
          @onClickRow="loadPropertySearchResult"
        />
      </div>
    </job-details>

    <div v-if="shouldShowOwnerControls">
      <label>
        Private
        <input type="checkbox" :checked="isPrivate" @change="togglePrivate" />
      </label>
    </div>

    <form v-if="customer.attributes.id && property.attributes.id" @submit.prevent="submitJobForm" @change="onChangeJobAttribute" novalidate>
      <div class="level">
        <div class="level-item">
          <btn @click="submitJobForm">
            <icon slot="icon" :classes="['fa', 'fa-check']"/>
            Commit
          </btn>
          <btn @click="cancelJobEdit">
            <icon slot="icon" :classes="['fa', 'fa-ban']"/>
            Cancel
          </btn>
        </div>
      </div>
    </form>

  </div>
</template>

<script>
  import ObjectTag from '~/components/page/ObjectTag.vue';
  import JobDetails from '~/components/job/JobDetails.vue';
  import JobContext from './mixins/JobContext.vue';
  import ModalContext from './mixins/ModalContext.vue';
  import Modal from '~/components/Modal.vue';
  import CustomerFormContainer from '~/containers/CustomerFormContainer.vue';
  import PropertyForm from '~/components/property/PropertyForm.vue';
  import P from '~/lib/promise';
  import Icon from '~/components/Icon.vue';
  import Btn from '~/components/Btn.vue';
  import {DataPager, DataTable} from '~/lib/vue/components/datatables';
  import constants from '~/lib/domain/constants';

  export default {

    mixins: [JobContext, ModalContext],

    components: {
      JobDetails,
      ObjectTag,
      Modal,
      CustomerFormContainer,
      PropertyForm,
      Btn,
      Icon,
      DataTable,
    },

    data () {
      return {
        title: 'Create Job',
        currentForm: null,
        isPrivate: false,
        customerColumns: [
          {
            label: '',
            value: (row) => {
              let {firstname, lastname, company, type, name} = row;
              if (type === constants.CUSTOMER_TYPE_COMPANY) {
                return name;
              }
              let individual = `${firstname} ${lastname}`;
              if (company) {
                return `${individual} (${company.name})`;
              }
              return individual;
            },
          },
        ],
        propertyColumns: [
          {
            label: '',
            value: (row) => {
              let {address, city} = row;
              return `${address.split('\n')[0]}, ${city}`;
            },
          },
        ],
      };
    },

    computed: {
      shouldShowOwnerControls () {
        const user = this.$store.getters['auth/user'];
        const roles = user.roles ?? [];
        return roles.includes(constants.ROLE_OWNER);
      },
    },

    methods: {

      async submitJobForm () {
        let ok = await this.saveJob();
        if (ok) {
          this.$router.push({
            name: 'job:detail',
            params: {
              id: this.job.attributes.id,
            },
          });
        }
      },

      onChangeJobAttribute (attr, value) {
        this.assignJobAttributes({[attr]: value});
      },

      onClickCancelButton () {
        this.$router.go(-1);
      },

      async loadCustomerSearchResult (id) {
        await this.loadCustomer({id});
        this.syncJobAttributes();
      },

      async loadPropertySearchResult (id) {
        await this.loadProperty({id});
        this.syncJobAttributes();
      },

      openCustomerModal () {
        this.currentForm = 'customer';
        this.openModal();
      },

      openPropertyModal () {
        this.currentForm = 'property';
        this.openModal();
      },

      onAfterCloseModal () {
        this.syncJobAttributes();
      },

      syncJobAttributes () {
        this.assignJobAttributes({
          property_id: this.property.attributes.id,
          customer_id: this.customer.attributes.id,
        });
      },

      async handleChQuery () {
        let {ch} = this.$route.query;
        let tokens = (ch || '').split(',').map(v => v.trim());
        if (tokens.includes('customer')) {
          await this.initializeCustomer();
        }
        if (tokens.includes('property')) {
          await this.initializeProperty();
        }
      },

      togglePrivate () {
        this.isPrivate = !this.isPrivate;
        const required_role = this.isPrivate ? constants.ROLE_OWNER : null;
        this.assignJobAttributes({ required_role });
      },
    },

    mounted () {
      (async () => {
        await P.all([
          this.initializeJobContext(),
          this.initializeCustomerContext(),
          this.initializePropertyContext(),
        ]);
        await this.syncJobAttributes();
        await this.handleChQuery();

        this.setCustomerTableQuery({
          paging: {limit: 5, offset: 0},
          sorts: {updated_at: 'desc'},
        });
        this.setPropertyTableQuery({
          paging: {limit: 5, offset: 0},
          sorts: {updated_at: 'desc'},
        });

        await P.all([
          this.loadCustomerTable(),
          this.loadPropertyTable(),
        ]);

      })();
    },

  };
</script>
