<template>

  <page>

    <modal
      :open="modalOpen"
      @close="closeModal"
    >
      <div class="box" slot="body">
        <object-tag
          slot="header"
          type="Property"
          :identifier="guessPropertyIdFromRoute()"
        />
        <property-form
          :attributes="property.form"
          :errors="property.errors"
          :territories="propertyTerritories"
          @onSubmit="commitPropertyEdit"
          @onCancel="cancelPropertyEdit"
          @onChangeAttribute="assignPropertyFormAttributes"
          @onChangeCountry="setPropertyCountry"
          @onChangeProvince="setPropertyProvince"
        />
      </div>
    </modal>

    <object-tag
      slot="page-title"
      type="Property"
      :identifier="guessPropertyIdFromRoute()"
    />

    <div slot="page-buttons">
      <router-link
        :to="{name: 'job:create', query: {property: $route.params.id}}"
        class="button is-pulled-right"
      >
        <icon :classes="['fa', 'fa-plus-circle']"/>
        <span>New Job</span>
      </router-link>
    </div>

    <div class="columns">
      <div class="column">
        <property-card
          :attributes="property.attributes"
        >
          <div slot="buttons">
            <icon :classes="['fa', 'fa-edit']" :tooltip="'Edit Property'" :clickable="true" @click="openModal" />
            <icon v-if="property.deletable" :classes="['fa', 'fa-trash']" :tooltip="'Delete Property'" :clickable="true" @click="handleDeleteProperty" />
          </div>
        </property-card>
      </div>
      <div class="column">
        <div class="columns">
          <div class="column is-one-half">
            <h2 class="subtitle">Jobs</h2>
          </div>
          <div class="column">
          </div>
        </div>
        <data-table
          :rows="jobs.rows"
          :columns="jobColumns"
          :limit="5"
          :total="jobs.total"
          @onClickRow="navigateToJob"
        />
      </div>
    </div>

  </page>
</template>

<script>
  import {mapState, mapGetters, mapActions} from 'vuex';
  import P from '~/lib/promise';
  import {DataTable, DataPager} from '~/lib/vue/components/datatables';
  import ObjectTag from '~/components/page/ObjectTag.vue';
  import PropertyCard from '~/components/property/PropertyCard.vue';
  import PropertyForm from '~/components/property/PropertyForm.vue';
  import PropertyContext from './mixins/PropertyContext.vue';
  import ModalContext from './mixins/ModalContext.vue';
  import JobContext from './mixins/JobContext.vue';
  import Page from '~/components/Page.vue';
  import Icon from '~/components/Icon.vue';
  import Btn from '~/components/Btn.vue';
  import Modal from '~/components/Modal.vue';

  export default {
    mixins: [ModalContext, PropertyContext, JobContext],

    components: {
      PropertyCard,
      PropertyForm,
      DataTable,
      DataPager,
      Page,
      Icon,
      Btn,
      ObjectTag,
      Modal,
    },

    data () {
      return {
        jobColumns: [
          {
            label: 'ID',
            value: row => row.id,
          },
          {
            label: 'Customer',
            value: row => `${row.customer.firstname} ${row.customer.lastname}`,
          },
          {
            label: 'Status',
            html: row => `<span class="tag is-dark">${row.states.slice(0).pop().name}</span>`,
          },
        ],
      };
    },

    computed: {

      title () {
        let {title, address, city} = this.property.attributes;
        if (title) {
          return `${title}, ${city}`;
        }
        return `${address.split('\n')[0]}, ${city}`;
      },

      ...mapGetters('job/datatable', {
        'jobs': 'props',
      }),
    },

    methods: {

      ...mapActions('job/datatable', {
        initializeJobTable: 'initialize',
        loadJobTable: 'load',
        onChangeJobTablePage: 'setPage',
        onChangeJobTableLimit: 'setLimit',
      }),

    },

    mounted () {
      let id = this.guessPropertyIdFromRoute();
      this.initializePropertyContext();
      (async () => {
        await this.initializeJobTable({
          query: {
            filters: id ? {match_properties: [id]} : {},
          },
        });
        this.loadJobTable();
      })();

    },
  };

</script>
