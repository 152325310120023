import Module from '~/lib/vue/modules/module';
import caseStudies from './case-studies';
import caseStudy from './case-study';

export default (services, namespace='customer') => {
  return Module.create({
    namespace,
    services,
    modules: {
      caseStudies,
      caseStudy,
    },
  });
};
