<script>

  import lang from '~/lib/lang';
  import date from '~/lib/date';
  import format from '~/lib/domain/format';
  import constants from '~/lib/domain/constants';

  export default {

    props: {
      attributes: {
        type: Object,
        required: true,
      },
    },

    computed: {
      customerTypeOptions () {
        let {type} = this.attributes;
        let dict = {};
        lang.collection.map(constants.CUSTOMER_TYPES, (label, value) => {
          dict[value] = {label, selected: type === value};
        });
        return dict;
      },

      customerIsCompany () {
        let {type} = this.attributes;
        return type === constants.CUSTOMER_TYPE_COMPANY;
      },

      name () {
        let {firstname, lastname} = this.attributes;
        return lang.string.truncate(`${firstname} ${lastname}`, 40);
      },
      textName () {
        let {firstname, lastname} = this.attributes;
        return `${firstname} ${lastname}`;
      },
      htmlAddress () {
        return format.address(this.attributes).join('<br>');
      },
      textAddress () {
        return format.address(this.attributes).join('\n');
      },
      since () {
        let {created_at} = this.attributes;
        return created_at ? date(created_at).format('MMMM YYYY') : null;
      },
      company () {
        let {company} = this.attributes;
        return company && company.name ? company.name : '-';
      },
      phoneUrl (tel) {
        return tel ? `tel:${tel}` : null;
      },
    },
  };

</script>
