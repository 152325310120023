<template>
  <div class="upload-thumbnail" :style="styleBindings">
    <figure class="image" :class="figureClassBindings">
      <img v-if="url" ref="image" :src="url"/>
      <img v-else ref="image"/>
    </figure>
  </div>
</template>

<script>
  import {File} from '~/lib/fs';
  import {video} from '~/lib/media';

  export default {
    props: {
      file: {
        required: true,
        type: File,
      },
      size: {
        required: false,
        type: Number,
        default: 64,
      },
    },

    data () {
      let {size} = this;
      return {
        url: null,
        styleBindings: {
          width: `${size}px`,
          height: `${size}px`,
        },
        figureClassBindings: {
          [`is-${size}x${size}`]: true,
        },
      };
    },

    methods: {

      async extractVideoThumbnail () {
        let video = document.createElement('video');
        let source = document.createElement('source');
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');

        video.appendChild(source);

        return new P(async (resolve, reject) => {
          video.onloadedmetadata = () => {
            let width = video.videoWidth;
            let height = video.videoHeight;
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(video, 0, 0, width, height);
            resolve(canvas.toDataURL());
          };

          source.src = await this.file.dataURL();
        });
      },
    },

    async mounted () {
      let {file} = this;
      let {mime} = file;

      if (mime.startsWith('image/')) {
        this.url = await file.dataURL();
      }
      else if (mime.startsWith('video/')) {
        this.url = await video.thumbnail(file.dataURL());
      }
    },
  };
</script>
