<script>
  import {mapState, mapGetters, mapActions} from 'vuex';
  import P from '~/lib/promise';
  import constants from '~/lib/domain/constants';
  import format from '~/lib/domain/format';
  import lang from '~/lib/lang';
  import CustomerContext from './CustomerContext.vue';
  import PropertyContext from './PropertyContext.vue';

  export default {

    mixins: [CustomerContext, PropertyContext],

    computed: {

      ...mapGetters('job/record', {
        job: 'props',
      }),

      ...mapGetters('job/datatable', {
        jobTable: 'props',
      }),

      ...mapGetters('job/search', {
        'jobSearch': 'props',
      }),

      ...mapGetters('document/datatable', {
        documentTable: 'props',
      }),

      ...mapGetters('job/notes', {
        jobNotes: 'props',
      }),

      ...mapGetters('job/attachments', {
        jobAttachments: 'props',
      }),

      currentJobState () {
        let current = [...this.job.attributes.states].pop();
        return current ? current.name : null;
      },

      jobStateButtonDefinitions () {
        let colors = this.jobStateButtonColors;
        let {transitions} = this.job.attributes;
        return lang.collection.map(constants.JOB_STATES, (label, value) => {
          return {
            label,
            value,
            color: 'is-warning',
            disabled: value !== this.currentJobState && transitions.indexOf(value) === -1,
          };
        });
      },

      jobStateButtonColors () {
        return {
          [constants.JOB_STATE_OPEN]: 'is-light',
          [constants.JOB_STATE_ESTIMATED]: 'is-light',
          [constants.JOB_STATE_QUOTED]: 'is-warning',
          [constants.JOB_STATE_AWARDED]: 'is-warning',
          [constants.JOB_STATE_SCHEDULED]: 'is-warning',
          [constants.JOB_STATE_PROGRESS]: 'is-success',
          [constants.JOB_STATE_COMPLETED]: 'is-success',
          [constants.JOB_STATE_CLOSED]: 'is-dark',
        };
      },

      jobContact () {
        if (!this.job.attributes) {
          return null;
        }
        let {contact} = this.job.attributes;

        if (!contact) {
          return null;
        }

        let keys = [
          'firstname',
          'lastname',
          'description',
          'email',
          'tel',
          'tel_secondary',
        ];

        let entries = keys.reduce((out, k) => {
          let v = contact[k];
          v && out.push([k, v]);
          return out;
        }, []);

        return entries.length ? lang.object.fromEntries(entries) : null;
      },
    },

    methods: {

      ...mapActions('job/datatable', {
        initializeJobTable: 'initialize',
        loadJobTable: 'load',
        setJobTablePage: 'setPage',
        setJobTableLimit: 'setLimit',
        setJobTableQuery: 'setQuery',
      }),

      ...mapActions('job/record', {
        initializeJob: 'initialize',
        loadJob: 'load',
        saveJob: 'save',
        deleteJob: 'delete',
        assignJobAttributes: 'assign',
        propagateJobObjects: 'propagate',
        deleteJobContact: 'deleteContact',
        assignJobFormAttributes: 'assignFormAttributes',
        resetJobForm: 'resetForm',
        attachJobBlob: 'attach',
        detachJobBlob: 'detach',
        detachAllJobBlobs: 'detachAll',
      }),

      ...mapActions('job/search', {
        initializeJobSearch: 'initialize',
        resetJobSearch: 'reset',
        queryJobSearch: 'query',
      }),

      ...mapActions('document/datatable', {
        initializeDocumentTable: 'initialize',
        setDocumentTableQuery: 'setQuery',
      }),

      ...mapActions('job/notes', {
        initializeJobNotes: 'initialize',
        loadJobNotes: 'loadNotes',
        addJobNote: 'addNote',
        deleteJobNote: 'deleteNote',
      }),

      ...mapActions('job/attachments', {
        initializeJobAttachments: 'initialize',
        updateJobAttachment: 'updateAttachment',
      }),

      async initializeJobContext () {
        let id = this.guessJobIdFromRoute();

        await P.all([
          this.initializeCustomerContext(),
          this.initializePropertyContext(),
          this.initializeJob(),
          this.initializeJobTable({url: '/jobs'}),
          this.initializeDocumentTable(),
          this.initializeJobNotes({subject: id}),
          this.initializeJobAttachments(),
          this.initializeJobSearch(),
        ]);

        if (id) {
          await P.all([
            this.loadJob({id}),
            this.loadJobNotes(),
          ]);
        }

      },

      async commitJobEdit () {
        let ok = await this.saveJob();
        if (ok) {
          this.$router.replace({
            name: 'job:detail',
            params: {
              id: this.job.attributes.id,
            },
          });
        }
      },

      async cancelJobEdit () {
        this.$router.go(-1);
      },

      guessJobIdFromRoute () {
        let {name, params, query} = this.$route;
        if (params.id) {
          if (name.match(/^job:(detail|edit|sheet)/) || name.match(/^document:create$/)) {
            return params.id;
          }
        }
        if (query.job) {
          return query.job;
        }
        return null;
      },

      navigateToJob (id) {
        if (id) {
          this.$router.push({name: 'job:detail', params:{id}});
        }
      },

      async attemptJobStateTransition (next) {
        this.assignJobFormAttributes({state: next});
        await this.saveJob();
        await this.loadJob({id: this.job.attributes.id});
      },

      async handleJobSearchTerm (terms) {
        await this.queryJobSearch({terms});
      },

    },
  };

</script>
