<template>
  <span class="icon tooltip" :class="classBindings" v-if="tooltip" :data-tooltip="tooltipText" @click="$emit('click')" :style="styleBindings">
    <i :class="iconClasses" />
  </span>
  <span v-else class="icon" :class="classBindings" @click="$emit('click')" style="styleBindings">
    <i :class="iconClasses" />
  </span>
</template>

<script>
  export default {
    name: 'icon',

    props: {
      classes: {
        required: true,
        type: Array,
        validator: (v) => {
          let filter = v => typeof v === 'string';
          return v.filter(filter).length === v.length;
        },
      },
      tooltip: {
        required: false,
        default: '',
      },
      clickable: {
        required: false,
        type: Boolean,
        default: false,
      },
    },

    computed: {
      styleBindings () {
        let styles = {};
        if (this.clickable) {
          styles.cursor = 'pointer';
        }
        return styles;
      },

      iconClasses () {
        return this.classes.reduce((bindings, cls) => {
          bindings[cls] = true;
          return bindings;
        }, {});
      },

      tooltipText () {
        let {tooltip} = this;
        if (tooltip) {
          if (typeof tooltip === 'string') {
            return tooltip;
          }
          if (typeof tooltip.text === 'string') {
            return tooltip.text;
          }
        }
        return '';
      },

      tooltipPositionClass () {
        let {tooltip} = this;
        if (tooltip) {
          if (typeof tooltip.position === 'string') {
            return `is-tooltip-${tooltip.position}`;
          }
        }
        return 'is-tooltip-top';
      },

      classBindings () {
        return {
          [this.tooltipPositionClass]: true,
        };
      }
    },
  };

</script>
