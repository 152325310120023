<script>
  import {mapState, mapGetters, mapActions} from 'vuex';
  import P from '~/lib/promise';
  import constants from '~/lib/domain/constants';
  import lang from '~/lib/lang';

  export default {

    data () {
      return {
        uploadBucket: {
          id: 'default',
          title: 'Files',
          route: {...this.$route},
        },
      };
    },

    computed: {
      ...mapGetters('upload', {
        uploadBuckets: 'buckets',        
        uploads: 'uploads',        
      }),
    },

    methods: {

      ...mapActions('upload', {
        getUploadBucket: 'getBucket',
        removeUploadBucket: 'removeBucket',
        removeUpload: 'removeUpload',
        upload: 'upload',
      }),

      async handleFiles (blobs) {
        let bucket = await this.getUploadBucket(this.uploadBucket);
        blobs.forEach(async (blob) => {
          let upload = await this.upload({bucket, blob});
          await upload.finished;
          this.afterUpload(upload);
        });
      },

      async afterUpload (upload) {
        console.log(`UploadContext#afterUpload: upload ${upload.id} finished`);
      },
    },
  };

</script>

