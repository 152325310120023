<script>
  import {mapGetters, mapActions} from 'vuex';
  import JobContext from './JobContext.vue';
  import P from '~/lib/promise';
  import currency from '~/lib/currency';
  import poll from '~/lib/poll';
  import date from '~/lib/date';
  import lang from '~/lib/lang';
  import constants from '~/lib/domain/constants';

  export default {

    mixins: [JobContext],

    data () {
      return {
        docTypes: constants.DOCUMENT_TYPES,
        pdf: null,
        docIncludes: [],
      };
    },

    computed: {
      ...mapGetters('document/datatable', {
        'documentTable': 'props',
      }),
      ...mapGetters('document/record', {
        'document': 'props',
      }),
      ...mapGetters('document/printer', {
        'printing': 'printing',
      }),

      ...mapGetters('document/warranties', {
        'documentWarranties': 'props',
      }),

      documentItems: {
        get () {
          return this.$store.getters['document/items/items'];
        },
      },

      documentOptionals: {
        get () {
          return this.$store.getters['document/optionals/items'];
        },
      },

      documentTerms: {
        get () {
          return this.$store.getters['document/terms/items'];
        },
      },

      lineItemsHeading () {
        let {type, summary} = this.document.attributes;

        if (type === 'contract') {
          if (summary) {
            let str = lang.string.capitalize(summary || '');
            return `Scope of Work: ${str}`;
          }
          return 'Scope of Work';
        }

        return 'Changes';
      },

      formattedSum () {
        let {sum} = this.document.attributes;
        return currency.format(sum || 0, {code: 'USD'}).split('.').shift();
      },

      sumLabel () {
        let {type} = this.document.attributes;
        let headings = {
          'contract': 'Contract Sum',
        };
        return headings[type] || 'Price';
      },

      documentType () {
        let {type} = this.document.attributes;
        return this.docTypes[type];
      },

      currentDocumentState () {
        let current = [...this.document.attributes.states].pop();
        if (current && current.name) {
          return current.name;
        }
        return null;
      },

      documentIsDraft () {
        return this.currentDocumentState === constants.DOCUMENT_STATE_DRAFT;
      },

      documentIsAccepted () {
        return this.currentDocumentState === constants.DOCUMENT_STATE_ACCEPTED;
      },

      documentCreationTimestamp () {
        let {created_at} = this.document.attributes;
        return date(created_at).format('MMMM D YYYY,  h:mm A');
      },

      documentEditPageTitle () {
        let {name} = this.$route;
        return name.match(/edit$/) ? 'Edit Document' : 'Create Document';
      },

      documentIsPrintable () {
        let {addresses} = this.customer.attributes;
        return addresses && addresses.service && addresses.service.address;
      },

      documentPdfBlob () {
        if (this.pdf) {
          return this.pdf;
        }
        let {attachments} = this.document.attributes;
        if (Array.isArray(attachments) && attachments.length > 0) {
          let artifact = attachments.filter(a => a.rel === 'artifact').pop();
          return artifact ? artifact.blob : null;
        }
        return null;
      },

      documentStateTooltips () {
        let {DOCUMENT_STATE_DRAFT, DOCUMENT_STATE_PUBLISHED, DOCUMENT_STATE_ACCEPTED} = constants;
        return {
          [DOCUMENT_STATE_DRAFT]: 'A draft document is mutable.',
          [DOCUMENT_STATE_PUBLISHED]: 'A published document is immutable.',
          [DOCUMENT_STATE_ACCEPTED]: 'An accepted document is immutable and legally binding.',
        };
      },

      documentStateButtonDefinitions () {
        let tooltips = this.documentStateTooltips;
        return lang.collection.map(constants.DOCUMENT_STATES, (label, value) => {
          let tooltip = tooltips[value];
          return {label, value, tooltip};
        });
      },
    },

    methods: {

      ...mapActions('document/printer', {
        'print': 'print',
      }),

      ...mapActions('document/datatable', {
        initializeDocumentTable: 'initialize',
        loadDocumentTable: 'load',
        setDocumentTablePage: 'setPage',
        setDocumentTableLimit: 'setLimit',
        setDocumentTableQuery: 'setQuery',
      }),

      ...mapActions('document/record', {
        initializeDocument: 'initialize',
        assignDocumentAttributes: 'assign',
        loadDocument: 'load',
        saveDocument: 'save',
        deleteDocument: 'delete',
        assignDocumentFormAttributes: 'assignFormAttributes',
        resetDocumentForm: 'resetForm',
        propagateDocumentAttributes: 'propagate',
      }),

      ...mapActions('document/items', {
        initializeDocumentItems: 'initialize',
        addDocumentItem: 'addItem',
        updateDocumentItems: 'updateItems',
        updateDocumentItem: 'updateItem',
        deleteDocumentItem: 'deleteItem',
        pushDocumentItems: 'pushItems',
        sortDocumentItems: 'sortItems',
      }),

      ...mapActions('document/optionals', {
        initializeDocumentOptionals: 'initialize',
        addDocumentOptional: 'addItem',
        updateDocumentOptionals: 'updateItems',
        updateDocumentOptional: 'updateItem',
        deleteDocumentOptional: 'deleteItem',
        pushDocumentOptionals: 'pushItems',
        sortDocumentOptionals: 'sortItems',
      }),

      ...mapActions('document/terms', {
        initializeDocumentTerms: 'initialize',
        addDocumentTerm: 'addItem',
        updateDocumentTerms: 'updateItems',
        updateDocumentTerm: 'updateItem',
        deleteDocumentTerm: 'deleteItem',
        sortDocumentTerms: 'sortItems',
      }),

      ...mapActions('document/warranties', {
        initializeWarranties: 'initialize',
        setWarranties: 'setWarranties',
        addWarranty: 'addWarranty',
        removeWarranty: 'removeWarranty',
        updateWarranty: 'updateWarranty',
      }),

      async initializeDocumentContext () {
        await P.all([
          this.initializeJobContext(),
          this.initializeDocument(),
          this.initializeDocumentItems(),
          this.initializeDocumentOptionals(),
          this.initializeDocumentTerms(),
          this.initializeDocumentTable({url: '/documents'}),
          this.initializeWarranties(),
        ]);

        let id = this.guessDocumentIdFromRoute();
        if (id) {
          let includes = this.docIncludes;
          await this.loadDocument({id, includes});
          let {ref_document_id} = this.document.attributes;
          if (ref_document_id) {
            await this.loadReferenceDocument({id: ref_document_id});
          }
        }

        let {job_id} = this.document.attributes;
        if (!job_id) {
          job_id = this.guessJobIdFromRoute();
        }

        if (job_id) {
          await this.loadJob({id: job_id});
          this.assignDocumentFormAttributes({job_id})
        }
      },

      handleChangeDocumentItem (index, attributes) {
        this.updateDocumentItem({index, attributes});
      },

      handleAddDocumentItem () {
        this.addDocumentItem();
      },

      handleChangeDocumentOptional (index, attributes) {
        this.updateDocumentOptional({index, attributes});
      },

      handleAddDocumentOptional() {
        this.addDocumentOptional();
      },

      async commitDocumentEdit () {
        let ok = await this.saveDocument();
        if (ok) {
          let {id} = this.document.attributes;
          this.$router.replace({name: 'document:detail', params: {id}});
        }
      },

      async cancelDocumentEdit () {
        this.$router.go(-1);
      },

      guessDocumentIdFromRoute () {
        let {name, params} = this.$route;
        if (name.match(/^document:(edit|detail)$/)) {
          return params.id;
        }
        return null;
      },

      navigateToDocument (id) {
        if (id) {
          this.$router.push({name: 'document:detail', params: {id}});
        }
      },

      async renderPdf () {
        let {id} = this.document.attributes;;
        let force = this.documentIsDraft;
        let url = await this.print({document: id, force});
        if (url) {
          this.pdf = url;
        }
        return url;
      },

      async printPdf () {
        if (this.pdf) {
          let link = document.createElement("a");
          link.download = name;
          link.href = this.pdf;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      },

      async attemptDocumentStateTransition (state) {
        this.assignDocumentFormAttributes({state});
        return await this.commitDocumentEdit();
      },
    },

  };

</script>
