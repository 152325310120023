import CustomerIndexPage from '~/pages/CustomerIndexPage.vue';
import CustomerDetailPage from '~/pages/CustomerDetailPage.vue';
import PropertyIndexPage from '~/pages/PropertyIndexPage.vue';
import PropertyDetailPage from '~/pages/PropertyDetailPage.vue';
import PropertyEditPage from '~/pages/PropertyEditPage.vue';
import JobIndexPage from '~/pages/JobIndexPage.vue';
import JobDetailPage from '~/pages/JobDetailPage.vue';
import JobEditPage from '~/pages/JobEditPage.vue';
import JobSheetPage from '~/pages/JobSheetPage.vue';
import InquiryIndexPage from '~/pages/InquiryIndexPage.vue';
import InquiryDetailPage from '~/pages/InquiryDetailPage.vue';
import DocumentIndexPage from '~/pages/DocumentIndexPage.vue';
import DocumentDetailPage from '~/pages/DocumentDetailPage.vue';
import DocumentEditPage from '~/pages/DocumentEditPage.vue';
import LoginPage from '~/pages/LoginPage.vue';
import TestPage from '~/pages/TestPage.vue';
import DashboardPage from '~/pages/DashboardPage.vue';

export default () => {

  return [
    {
      name: 'root',
      path: '/',
      redirect: '/dashboard',
      meta: {guest: true},
    },
    {
      name: 'dashboard',
      path: '/dashboard',
      component: DashboardPage,
    },
    {
      name: 'auth:login',
      path: '/auth/login',
      meta: {guest: true},
      component: LoginPage,
    },
    {
      name: 'customer:index',
      path: '/customers',
      component: CustomerIndexPage,
    },
    {
      name: 'customer:detail',
      path: '/customers/:id',
      component: CustomerDetailPage,
    },
    {
      name: 'property:create',
      path: '/properties/new',
      component: PropertyEditPage,
    },
    {
      name: 'property:index',
      path: '/properties',
      component: PropertyIndexPage,
    },
    {
      name: 'property:detail',
      path: '/properties/:id',
      component: PropertyDetailPage,
    },
    {
      name: 'property:edit',
      path: '/properties/:id/edit',
      component: PropertyEditPage,
    },
    {
      name: 'job:create',
      path: '/jobs/new',
      component: JobEditPage,
    },
    {
      name: 'job:index',
      path: '/jobs',
      component: JobIndexPage,
    },
    {
      name: 'job:detail',
      path: '/jobs/:id',
      component: JobDetailPage,
    },
    {
      name: 'job:edit',
      path: '/jobs/:id/edit',
      component: JobEditPage,
    },
    {
      name: 'job:sheet',
      path: '/jobs/:id/sheet',
      component: JobSheetPage,
    },
    {
      name: 'document:index',
      path: '/documents',
      component: DocumentIndexPage,
    },
    {
      name: 'document:edit',
      path: '/documents/:id/edit',
      component: DocumentEditPage,
    },
    {
      name: 'document:create',
      path: '/jobs/:id/documents/new',
      component: DocumentEditPage,
    },
    {
      name: 'document:detail',
      path: '/documents/:id',
      component: DocumentDetailPage,
    },
    {
      name: 'inquiry:index',
      path: '/inquiries',
      component: InquiryIndexPage,
    },
    {
      name: 'inquiry:detail',
      path: '/inquiries/:id',
      component: InquiryDetailPage,
    },

    {
      name: 'test',
      path: '/test',
      component: TestPage,
    },
  ];

};
