<template>

  <page>
    <object-tag type="Documents" slot="page-title" />
    <data-table
      class="table"
      :columns="documentTable.columns"
      :rows="documentTable.rows"
      :limit="documentTable.limit"
      :total="documentTable.total"
      @onClickRow="navigateToDocument"
    >
      <data-pager
        slot="footer"
        :total="documentTable.total"
        :limit="documentTable.limit"
        :page="documentTable.page"
        @onChangePage="setDocumentTablePage"
        @onChangeLimit="setDocumentTableLimit"
      />
    </data-table>
  </page>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import {DataTable, DataPager} from '~/lib/vue/components/datatables';
  import DocumentContext from './mixins/DocumentContext.vue';
  import ObjectTag from '~/components/page/ObjectTag.vue';
  import Page from '~/components/Page.vue';

  export default {

    mixins: [DocumentContext],

    components: {
      DataTable,
      DataPager,
      ObjectTag,
      Page,
    },

    mounted () {
      (async () => {
        await this.initializeDocumentTable({
          url: '/documents',
          query: {sorts: {'id': 'desc'}},
        });
        await this.setDocumentTablePage(1);
      })();
    },
  };

</script>
