import DataRecordModule from '~/lib/vue/modules/datarecord';
import P from '~/lib/promise';

export default (services, namespace='record') => {

  return DataRecordModule.create({
    namespace,
    services,
    type: 'job',
    resource: '/jobs',
    describe: payload => `Job ${payload.id}`,
    attributes: {
      id: null,
      title: null,
      state: 'open',
      states: [],
      transitions: [],
      customer: null,
      property: null,
      contact: {
        firstname: null,
        lastname: null,
        description: null,
        email: null,
        tel: null,
        tel_secondary: null,
      },
      attachments: [],
      required_role: null,
    },

    form: {
      customer_id: null,
      property_id: null,
      title: null,
      state: 'open',
      contact_firstname: null,
      contact_lastname: null,
      contact_description: null,
      contact_email: null,
      contact_tel: null,
      contact_tel_secondary: null,
      required_role: null,
    },

    map: (attributes) => {
      let {customer, property, contact} = attributes;
      return {
        ...attributes,
        customer_id: customer ? customer.id : null,
        property_id: property ? property.id : null,
        contact_firstname: contact.firstname,
        contact_lastname: contact.lastname,
        contact_description: contact.description,
        contact_email: contact.email,
        contact_tel: contact.tel,
        contact_tel_secondary: contact.tel_secondary,
      };
    },

    marshal: ({rootGetters}, form) => {
      return {
        ...form,
        customer_id: rootGetters['customer/record/attributes'].id,
        property_id: rootGetters['property/record/attributes'].id,
      };
    },

    afterLoad: ({dispatch})  => dispatch('propagate'),
    afterSave: ({dispatch}) => dispatch('propagate'),

    actions: {

      propagate ({dispatch}) {
        dispatch('propagateCustomer');
        dispatch('propagateProperty');
        dispatch('propagateContact');
        dispatch('propagateAttachments');
      },

      propagateCustomer ({state, dispatch}) {
        let {customer} = state.attributes;
        customer && dispatch('customer/record/assign', customer, {root: true});
      },

      propagateProperty ({state, dispatch}) {
        let {property} = state.attributes;
        property && dispatch('property/record/assign', property, {root: true});
      },

      propagateContact ({state, dispatch}) {
        let {contact} = state.attributes;
        dispatch('assign', {
          contact_firstname: contact.firstname,
          contact_lastname: contact.lastname,
          contact_description: contact.description,
          contact_email: contact.email,
          contact_tel: contact.tel,
          contact_tel_secondary: contact.tel_secondary,
        });
      },

      propagateAttachments ({state, getters, dispatch}) {
        let {attachments} = state.attributes;
        let url = getters.urls.attachments;
        dispatch('job/attachments/setRootUrl', url, {root: true});
        dispatch('job/attachments/receiveAttachments', attachments, {root: true});
      },

      async deleteContact ({dispatch}) {
        dispatch('assignFormAttributes', {
          contact_firstname: null,
          contact_lastname: null,
          contact_description: null,
          contact_email: null,
          contact_tel: null,
          contact_tel_secondary: null,
        });
        await dispatch('save');
      },
    },

  });

};
