import TermSearchModule from '~/lib/vue/modules/termsearch';
import constants from '~/lib/domain/constants';

export default (services) => {

  return TermSearchModule.create({
    services,
    url: '/search/customers',
    placeholder: 'Find companies',
    filters: {
      type: constants.CUSTOMER_TYPE_COMPANY,
    },
  });
};
