import lang from '~/lib/lang';
import {InvalidArgument, ParseError} from '~/lib/exceptions';
import moment from 'moment';

export {default} from 'moment';

export const duration = {};

duration.UNITS = {
  'Y': 'years',
  'M': 'months',
  'D': 'days',
};

duration.tokenize = (spec) => {
  if (typeof spec !== 'string') {
    throw new InvalidArgument('expected spec arg to be a string');
  }
  let pattern = /^P(\d+)(\w+)$/;
  let tokens = spec.match(pattern);
  if (!tokens || tokens.length < 3) {
    throw new ParseError('failed to parse ISO duration spec');
  }
  let [_, length, unit] = tokens;
  return {unit, length};
};

duration.humanize = (spec) => moment.duration(spec).humanize();

duration.spec = (unit, length) => {
  let units = duration.UNITS;
  let reducer = (ok, current) => !ok || units.hasOwnProperty(unit);
  let valid = length > 0 && lang.collection.reduce(units, reducer, true);
  return `P${length}${unit}`;
};
