import Validation from 'validatorjs';
import {Component} from '~/lib/foundation';
import {Invalid, InvalidArgument, NotImplemented} from '~/lib/exceptions';
import re from '~/lib/re';

class Validator extends Component {

  get rules () {
    throw new NotImplemented();
  }

  validate (form) {
    if (!form || typeof form !== 'object') {
      throw new InvalidArgument('Invalid form argument');
    }
    let validation = new Validation(form, this.rules);
    if (!validation.passes()) {
      throw new Invalid(validation.errors.all());      
    }
  }
}

export class NullValidator extends Validator {

  validate (form) {
    // no-op
  }
}

export class CaseStudyValidator extends Validator {

  get rules () {
    return {
      'title': ['present'],
      'text': ['present'],
      'published_at': ['present'],
      'content': ['present'],
      'content.specs': ['present'],
      'content.specs.*.architect': ['present'],
      'content.specs.*.engineer': ['present'],
      'content.specs.*.gc': ['present'],
      'content.specs.*.area': ['present'],
      'content.poster': ['present'],
      'content.poster.tagline': ['present'],
      'content.poster.text': ['present'],
      'content.poster.image': ['present', `regex:${re.sha256sum}`],
      'content.poster.thumbnail': ['present', `regex:${re.sha256sum}`],
      'content.pictures': ['present', 'array'],
      'content.pictures.*.caption': ['present'],
      'content.pictures.*.image ': ['present', `regex:${re.sha256sum}`],
      'content.pictures.*.image ': ['present', `regex:${re.sha256sum}`],
    };
  }
}

export default {
  CaseStudyValidator,
  NullValidator,
};
