import DataRecordModule from '~/lib/vue/modules/datarecord';
import {TYPES} from './types';
import {Warranty} from '~/lib/domain/warranty';
import constants from '~/lib/domain/constants';

export default (services, namespace='record') => {

  return DataRecordModule.create({
    type: 'document',
    namespace,
    services,
    resource: '/documents',
    describe: payload => `${TYPES[payload.type]} ${payload.id}`,

    attributes: {
      id: null,
      job_id: null,
      ref_document_id: null,
      type: 'contract',
      title: null,
      summary: null,
      sum: 0,
      deposit: null,
      items: [],
      optionals: [],
      terms: [],
      warranties: [],
      attachments: [],
      states: [],
    },

    form: {
      job_id: null,
      ref_document_id: null,
      type: 'contract',
      state: 'draft',
      title: null,
      summary: null,
      sum: 0,
      deposit: null,
      items: [],
      optionals: [],
      terms: [],
      warranties: [],
    },

    map: (attributes) => attributes,

    marshal: ({rootGetters}, form) => {
      let validate = {
        item: i => Boolean(i.text),
        optional: i => Boolean(i.text) && typeof i.sum !== undefined,
      };

      let compute_default_deposit = (sum) => {
        let fraction = sum * constants.DEPOSIT_DEFAULT_FRACTION;
        let max = constants.DEPOSIT_MAX;
        return fraction > max ? max : fraction;
      };

      let {deposit, sum} = form;
      if (sum) {
        if (deposit === null) {
          deposit = compute_default_deposit(sum);
        }
      }

      return {
        ...form,
        deposit,
        items: [...rootGetters['document/items/items']].filter(validate.item),
        optionals: [...rootGetters['document/optionals/items']].filter(validate.optional),
        terms: [...rootGetters['document/terms/items']].filter(validate.item),
        warranties: [...rootGetters['document/warranties/params']],
      };
    },

    afterLoad: store => store.dispatch('propagate'),
    afterSave: store => store.dispatch('propagate'),

    onValidationErrors: ({dispatch}, errors) => {
      // TODO: fix document/warranties/receiveErrors
      //dispatch('document/warranties/receiveErrors', errors, {root: true});
    },

    actions: {

      propagate: ({state, dispatch}) => {
        let {warranties, items, optionals, terms} = state.attributes;
        dispatch('document/warranties/receiveWarranties', [...warranties], {root: true});
        dispatch('document/items/updateItems', [...items], {root: true});
        dispatch('document/optionals/updateItems', [...optionals], {root: true});
        dispatch('document/terms/updateItems', [...terms], {root: true});
      },
    },

    getters: {

      currentState: ({attributes}) => {
        let {states} = attributes;
        return [...states].pop();
      },

      allowedStateTransitions: ({attributes}) => {
        let {states} = attributes;
        let current = [...states].pop();
        if (current && current.name) {
          let {DOCUMENT_STATE_DRAFT, DOCUMENT_STATE_PUBLISHED, DOCUMENT_STATE_ACCEPTED} = constants;
          let map = {
            [DOCUMENT_STATE_DRAFT]: [DOCUMENT_STATE_PUBLISHED],
            [DOCUMENT_STATE_PUBLISHED]: [DOCUMENT_STATE_DRAFT, DOCUMENT_STATE_ACCEPTED],
            [DOCUMENT_STATE_ACCEPTED]: [DOCUMENT_STATE_PUBLISHED],
          };
          let allowed = map[current.name];
          if (allowed) {
            return allowed;
          }
        }
        return [];
      },
    },

  });
};
