<template>
  <transition
		@enter="transitionEnter"
		@leave="transitionLeave"
	>
    <instrumentation-panel ref="panel">
      <p class="subtitle" slot="title">Uploads</p>
      <upload-manager :buckets="upload.buckets" :eventbus="eventbus" />
    </instrumentation-panel>
  </transition>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import UploadManager from '~/components/upload/UploadManager.vue';
  import InstrumentationPanel from '~/components/InstrumentationPanel.vue';
  import {EventEmitter} from '~/lib/events';
  import Vue from 'vue';
  import anime from 'animejs';

  export default {

    data () {
      return {
        eventbus: new Vue(),
      };
    },

    components: {
      InstrumentationPanel,
      UploadManager,
    },

    computed: {
      ...mapGetters('upload', {
        upload: 'props',
      }),
    },

    methods: {
      ...mapActions('upload', {
        removeBucket: 'removeBucket',
        removeUpload: 'removeUpload',
        closePanel: 'closePanel',
      }),
			async transitionEnter (el, done) {
        let animation = anime({
          targets: el,
          translateY: ['-100%', 0],
          easing: 'easeOutQuad',
          duration: 250,
        });
        await animation.finished;
        done();
			},
      async transitionLeave (el, done) {
        let animation = anime({
          targets: el,
          translateY: [0, '-100%'],
          easing: 'easeOutQuad',
          duration: 250,
        });
        await animation.finished;
        done();
      },
    },

    mounted () {
      let {eventbus} = this;

      eventbus.$on('bucket:remove', (id) => this.removeBucket({id}));
      eventbus.$on('upload:remove', (id) => this.removeUpload({id}));

      this.onEscapeKeypress = (event) => {
        let {key} = event;
        key === 'Escape' && this.closePanel();
      };

      window.addEventListener('keyup', this.onEscapeKeypress);
    },

    beforeDestroy () {
      this.eventbus.$off();
      window.removeEventListener('keyup', this.onEscapeKeypress);
    },

  };
</script>
