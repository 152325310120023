<template>
	<aside class="sidebar menu">
		<p class="menu-label">
			General
		</p>
		<ul class="menu-list">
			<li><router-link :to="{name: 'dashboard'}">Dashboard</router-link></li>
		</ul>
		<p class="menu-label">
			Database
		</p>
		<ul class="menu-list">
			<li><router-link :to="{name: 'customer:index'}">Customers</router-link></li>
			<li><router-link :to="{name: 'property:index'}">Properties</router-link></li>
			<li><router-link :to="{name: 'job:index'}">Jobs</router-link></li>
			<li><router-link :to="{name: 'document:index'}">Documents</router-link></li>
		</ul>
	</aside>
</template>

<script>
export default {};
</script>

<style lang="scss">
	aside.sidebar.menu {
    position: fixed;
    top: 72px;
    left: 20px;
    width: 140px;
    display: inline-block;
    border-right: 1px solid #eee;
    font-size: 0.9em;
    height: 100%;
	}
</style>
