<template>

  <div class="layout">
    <slot name="menu"/>
    <slot name="instrumentation"/>
    <slot name="sidebar"/>
    <div class="router-view-container">
      <slot name="body"/>
    </div>
  </div>

</template>
<script>

  export default {};

</script>

<style lang="scss">
  .router-view-container {
    position: absolute;
    left: 180px;
    right: 50px;
  }
</style>

