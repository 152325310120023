<template>

  <div class="app">
    <portal-target v-if="modalsEnabled" name="modal"/>
    <notifications v-if="auth.user && notificationsEnabled" position="bottom right" />
    <component v-bind:is="layouts[layoutName]" >
      <main-menu-container slot="menu"/>
      <sidebar-container v-if="auth.user" slot="sidebar"/>
      <router-view slot="body"/>
    </component>
  </div>

</template>
<script>

  import {mapGetters, mapActions} from 'vuex';
  import P from '~/lib/promise';
  import MainMenuContainer from './MainMenuContainer.vue';
  import UploadInstrumentationContainer from './UploadInstrumentationContainer.vue';
  import DefaultLayout from '~/components/layouts/DefaultLayout.vue';
  import PrintLayout from '~/components/layouts/PrintLayout.vue';
  import SidebarContainer from './SidebarContainer.vue';

  export default {

    data () {
      return {
        layouts: {
          default: DefaultLayout,
          print: PrintLayout,
        },
      };
    },

    components: {
      MainMenuContainer,
      SidebarContainer,
      UploadInstrumentationContainer,
    },

    computed: {
      ...mapGetters('layout', [
        'layoutName',
        'notificationsEnabled',
        'modalsEnabled',
      ]),
      ...mapGetters('upload', {
        upload: 'props',
      }),
      ...mapGetters('auth', {
        auth: 'props',
      }),
    },
  };

</script>

<style lang="scss">

</style>
