import Module from './module';
import rest from '~/lib/rest';

const DEFAULT_COUNTRY = '/territories/us';
const DEFAULT_PROVINCE = '/territories/us/california';

export class TerritoryModule extends Module {

  get taxonComparator () {
    let clean = str => str.replace(/[^a-zA-Z]+/g, '');

    return (a, b) => {
      let tokens = [a, b].map(taxon => clean(taxon.name.toLowerCase()));
      if (tokens[0] < tokens[1]) {
        return -1;
      }
      if (tokens[0] > tokens[1]) {
        return 1;
      }
      return 0;
    };
  }

  async fetchTaxons (parent_id) {
    let cache = this.services.get('cache');
    let api = this.services.get('api');
    let query = new rest.Query({includes: ['children']});
    let url = `/taxons${parent_id}?${String(query)}`;

    let children = cache.get(url);
    if (!children) {
      let response = await api.get(url);
      children = [...response.payload.children];
      let result = children.sort(this.taxonComparator);
      cache.set(url, children);
    }

    return children;
  }

  async fetchCountries () {
    return await this.fetchTaxons('/territories');
  }

  async fetchProvinces (country_id) {
    return await this.fetchTaxons(country_id);
  }

  get initialState () {
    return {

      // country container; a list of taxon payloads
      countries: [],

      // province container; a list of taxon payloads
      provinces: [],

      // country taxon id
      country_id: DEFAULT_COUNTRY,

      // province taxon id,
      province_id: DEFAULT_PROVINCE,
    };
  }

  get state () {
    return {...this.initialState};
  }

  get getters () {
    return {
      countries: state => state.countries,
      provinces: state => state.provinces,
      country_id: state => state.country_id,
      province_id: state => state.province_id,
    };
  }

  get mutations () {
    return {
      countries: (state, countries) => state.countries = countries,
      provinces: (state, provinces) => state.provinces = provinces,
      country_id: (state, country_id) => state.country_id = country_id,
      province_id: (state, province_id) => state.province_id = province_id,
    };
  }

  get actions () {
    let cache = this.services.get('cache');

    return {

      initialize: async (store) => {
        let {country_id, province_id} = this.initialState;
        await store.dispatch('acquireCountries');
        await store.dispatch('setCountry', country_id);
        await store.dispatch('setProvince', province_id);
      },

      acquireCountries: async (store) => {
        let key = `${this.namespace}:countries`;
        let countries = cache.get(key);
        if (!countries) {
          countries = await this.fetchCountries();
          cache.set(key, countries);
        }
        store.commit('countries', countries);
      },

      acquireProvinces: async (store) => {
        let {country_id} = store.state;
        let key = `${this.namespace}:countries:${country_id}:provinces`;
        let provinces = cache.get(key);
        if (!provinces) {
          provinces = await this.fetchProvinces(country_id);
          cache.set(key, provinces);
        }
        store.commit('provinces', provinces);
      },

      setCountry: async (store, country_id) => {
        if (country_id) {
          store.commit('country_id', country_id);
          await store.dispatch('acquireProvinces');
        }
      },

      setProvince: async (store, province_id) => {
        if (province_id) {
          store.commit('province_id', province_id);
        }
      },
    };
  }

}

export default TerritoryModule;
