import {Component} from '~/lib/foundation';
import exceptions from '~/lib/exceptions';
import querystring from 'querystring';

export class Client extends Component {

  /**
   * @property api
   * @type {rest.Client}
   */
  get api () {
    return this.option('api');
  }

  /**
   * @property url
   * @type {String}
   */
  get url () {
    return this.option('url');
  }

  /**
   * @async
   * @param {Blob} blob
   * @return {Object}
   */
  async info (blob) {
    this.assertImageBlob(blob);
    let url = `${this.url}/info}`;
    let response = await this.api.get(url, {raw: true, body: blob});
    return await response.json();
  }

  /**
   * @async
   * @param {Blob} blob
   * @param {String} operation - imaginary operation
   * @param {Object} params - operation parameters
   * @return {Blob}
   */
  async transform (blob, operation, params={}) {
    this.assertImageBlob(blob);
    let qs = querystring.stringify(params);
    let url = `${this.url}/${operation.toLowerCase()}?${qs}`;
    let response = await this.api.post(url, {raw: true, body: blob});
    return await response.blob();
  }

  /**
   * @param {mixed} blob
   * @throws {exceptions.InvalidArgument}
   */
  assertImageBlob (blob) {
    if (!blob) {
      throw new exceptions.InvalidArgument('expected blob argument');
    }
    let {type, size} = blob;
    if (type === undefined || size === undefined) {
      throw new exceptions.InvalidArgument('supplied blob does not appear to be a Blob');
    }
    if (!type.startsWith('image/')) {
      throw new exceptions.InvalidArgument('blob is not an image');
    }
  }
}


export default {
  Client,
};
