<template>

  <div class="page container">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <slot name="page-title"/>
        </div>
      </div>
      <div class="level-item">
        <slot name="page-search"/>
      </div>
      <div class="level-right">
        <div class="level-item">
          <slot name="page-buttons"/>
        </div>
      </div>
    </div>

    <slot/>

  </div>


</template>
<script>

  export default {
    name: 'page',
  };

</script>

<style lang="scss">

  .page.container {
    margin-bottom: 120px;
  }
</style>
