import Vue from 'vue';
import * as Sentry from "@sentry/vue";

export const bootSentry = (router: any): void => {
  // @ts-expect-error
  const dsn: string = import.meta.env.VITE_SENTRY_DSN || '';
  if (!dsn) {
    return;
  }
  Sentry.init({
    Vue,
    dsn,
     // @ts-expect-error
    environment: import.meta.env.VITE_APP_ENV || 'local',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};
