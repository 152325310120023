<template>

  <span class="tag" :class="classBindings"><slot/></span>

</template>
<script>

  export default {
    props: {
      classes: {
        type: Array,
        required: false,
        default: () => [],
      },
    },
    computed: {
      classBindings () {
        let bindings = {};
        this.classes.forEach((cls) => {
          bindings[cls] = true;
        });
        return bindings;
      },
    },
  };

</script>

