<script>
  import {mapState, mapGetters, mapActions} from 'vuex';
  import P from '~/lib/promise';

  export default {

    computed: {
      ...mapGetters('inquiry/datatable', {
        'inquiryTable': 'props',
      }),
      ...mapGetters('inquiry/record', {
        'inquiry': 'props',
      }),
    },

    methods: {

      ...mapActions('inquiry/datatable', {
        initializeInquiryTable: 'initialize',
        loadInquiryTable: 'load',
        setInquiryTablePage: 'setPage',
        setInquiryTableLimit: 'setLimit',
        setInquiryTableQuery: 'setQuery',
      }),

      ...mapActions('inquiry/record', {
        initializeInquiry: 'initialize',
        assignInquiryAttributes: 'assign',
        loadInquiry: 'load',
        deleteInquiry: 'delete',
      }),

      async initializeInquiryContext () {
        await P.all([
          this.initializeInquiry(),
          this.initializeInquiryTable(),
        ]);

        let id = this.guessInquiryIdFromRoute();
        if (id) {
          await this.loadInquiry({id, includes: ['stats']});
        }
      },

      guessInquiryIdFromRoute () {
        let {name, params, query} = this.$route;
        if (name.match(/^inquiry:(detail|edit)/)) {
          return params.id;
        }
        if (query.property) {
          return query.property;
        }
        return null;
      },

      async navigateToInquiry (id) {
        if (id) {
          this.$router.push({name: 'inquiry:detail', params: {id}});
        }
      },

      async handleDeleteInquiry () {
        await this.deleteInquiry();
        this.$router.replace({name: 'inquiry:index'});
      },
    },

  };

</script>

