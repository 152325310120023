import {EventEmitter2} from 'eventemitter2';
import P from '~/lib/promise';

export class EventEmitter extends EventEmitter2 {

  /**
   * removeListener alias
   * @param {String} event - optional event name
   * @param {Function} listener - optional listener
   * @return {EventEmitter}
   */
  off (...args) {
    let arity = args.length;
    let [event, listener] = args;
    let remove = (...args) => this.removeListener(...args);

    // remove a single listener at arity 2
    if (arity >= 2) {
      remove(event, listener);
    }

    // remove listeners of a type at arity 1
    else if (arity === 1) {
      this.listeners(event).forEach(listener => remove(event, listener));
    }

    // remove all listeners at arity 0
    else {
      this.removeAllListeners();
    }

    return this;
  }
}

export default {EventEmitter};
