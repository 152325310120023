import P from '~/lib/promise';

export const thumbnail = async (url, options={}) => {
  let defaults = {scale: 1};
  let {scale} = {...defaults, ...options};

  let video = document.createElement('video');
  let source = document.createElement('source');
  let canvas = document.createElement('canvas');
  let ctx = canvas.getContext('2d');

  video.crossOrigin = 'anonymous';
  video.appendChild(source);

  return new P(async (resolve, reject) => {
    video.onloadeddata = () => {
      let width = video.videoWidth;
      let height = video.videoHeight;
      canvas.width = width * scale;
      canvas.height = height * scale;
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      resolve(canvas.toDataURL());
    };

    source.src = url;
  });
};

export default {
  thumbnail,
};
