<template>
  <page>

    <object-tag slot="page-title" type="Jobs" />

    <div slot="page-buttons">
      <router-link
        :to="{name: 'job:create'}"
        class="button"
      >
        <icon :classes="['fa', 'fa-plus-circle']" />
        <span>New Job</span>
      </router-link>
    </div>

    <term-search-form
      slot="page-search"
      :terms="jobSearch.terms"
      :results="jobSearch.results"
      :error="jobSearch.error"
      :progress="jobSearch.progress"
      :valid="jobSearch.valid"
      :placeholder="jobSearch.placeholder"
      @change="handleJobSearchTerm"
      @reset="resetJobSearch"
    >
      <job-search-results
        slot="results"
        :valid="jobSearch.valid"
        :records="jobSearch.results"
        @choose="navigateToJob"
      />
    </term-search-form>

    <data-table
      class="table"
      :columns="jobTable.columns"
      :rows="jobTable.rows"
      :limit="jobTable.limit"
      :total="jobTable.total"
      @onClickRow="navigateToJob"
    >
      <data-pager
        slot="footer"
        :total="jobTable.total"
        :limit="jobTable.limit"
        :page="jobTable.page"
        @onChangePage="setJobTablePage"
        @onChangeLimit="setJobTableLimit"
      />
    </data-table>
  </page>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import {DataTable, DataPager} from '~/lib/vue/components/datatables';
  import {TermSearchForm} from '~/lib/vue/components/search';
  import ObjectTag from '~/components/page/ObjectTag.vue';
  import JobContext from './mixins/JobContext.vue';
  import Btn from '~/components/Btn.vue';
  import Page from '~/components/Page.vue';
  import Icon from '~/components/Icon.vue';
  import JobSearchResults from '~/components/job/JobSearchResults.vue';

  export default {

    mixins: [JobContext],

    components: {
      DataTable,
      DataPager,
      JobSearchResults,
      TermSearchForm,
      ObjectTag,
      Page,
      Btn,
      Icon,
    },

    mounted () {
      (async () => {
        await this.initializeJobContext();
        this.setJobTableQuery({
          sorts: {updated_at: 'desc'},
        });
        await this.loadJobTable();
      })();
    },
  };

</script>
