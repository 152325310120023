<template>

  <dropdown class="is-pulled-right is-right" style="margin-top: 1.5rem; margin-right: 1.5rem;">
    <div class="dropdown-content">
      <a
        class="dropdown-item"
        @click="$emit('edit')"
      >
        <span class="icon"><i class="fa fa-edit" /></span>
        Edit
      </a>
    </div>
  </dropdown>

</template>
<script>

  import PropertyBase from './PropertyBase.vue';
  import ObjectTag from '~/components/page/ObjectTag.vue';
  import Dropdown from '~/components/page/Dropdown.vue';
  import Clippable from '~/components/clipboard/Clippable.vue';
  export default {
    name: 'property-dropdown-menu',
    mixins: [PropertyBase],
    components: {
      ObjectTag,
      Dropdown,
      Clippable,
    },
  };

</script>
