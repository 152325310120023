export default {

  'buckets': [
    {
      id: 'flat',
      label: 'Flat roof',
    },
    {
      id: 'metal',
      label: 'Metal roof',
    },
    {
      id: 'features',
      label: 'Features',
    },
  ],

  'plays': [

    {
      bucket: 'flat',
      label: 'SSH Upgrade - Premier',
      items: [
        'Install Sure Secure Premier hybrid roof system with custom stainless steel roof metals and GE Silicone 20-year warranty weathering layer',
      ],
    },

    {
      bucket: 'flat',
      label: 'SSH Upgrade - Elite',
      items: [
        'Install Sure Secure Premier hybrid roof system with custom solid copper roof metals and GE Silicone 20-year warranty weathering layer',
      ],
    },

    {
      bucket: 'flat',
      label: 'Bleed blocker',
      items: [
        'Supply and install bleed blocker mid-layer to reduce silicone discoloration from out-gassing of substrates (note: product use is for aesthetic purposes only',
      ],
    },

    {
      bucket: 'features',
      label: 'Front shingles',
      items: [
        'Replace front steep shingles with new, Charcoal Ultra HD Lifetime dimensional shingles with custom roof metals',
      ],
    },

    {
      bucket: 'features',
      label: 'Front tiles',
      items: [
        'Remove front clay tiles, supply and install new membrane, re-install and re-grout existing tiles replacing (missing and/or damaged tiles will be replaced with new at a cost of $18 per tile)',
      ],
    },

    {
      bucket: 'features',
      label: 'Front metal',
      items: [
        'Replace front steep shingles with new, standing seam, metal roof panels with custom, matching roof metals',
      ],
    },

    {
      bucket: 'features',
      label: 'Skylight',
      items: [
        'Replace existing skylights with custom made, stainless steel element with wired safety glass',
      ],
    },

    {
      bucket: 'features',
      label: 'Gutters and downspout pipes',
      items: [
        'Supply and install matching seamless gutters and downspouts',
      ],
    },

    {
      bucket: 'features',
      label: 'Chimney cap',
      items: [
        'Replace existing chimney cap with new, custom, metal element with directional flue',
      ],
    },

    {
      bucket: 'features',
      label: 'Insulation',
      items: [
        'Supply and install new Micro Foil foam low-profile insulation',
      ],
    },

  ], // plays
};
