<template>

  <form class="media" @submit="handleSubmit">
    <div class="media-content">
      <div class="field">
        <p class="control">
          <input ref="input" name="text" class="input" placeholder="Add a note..."/>
        </p>
      </div>
      <nav class="level">
        <div class="level-left">
          <div class="level-item">
            <button type="submit" class="button">Submit</button>
          </div>
        </div>
      </nav>
    </div>
  </form>

</template>
<script>

  export default {

    methods: {

      async handleSubmit (event) {
        event.preventDefault();
        let {input} = this.$refs;
        let text = input.value;
        input.value = '';
        this.$emit('add', text);
      },
    },
  };

</script>
