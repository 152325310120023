<template>
  <table
    :class="{'is-hoverable': total}"
    class="table is-striped is-fullwidth is-hoverable data-table">
    <thead>
      <tr>
        <th v-for="column in columns">
          {{ column.label }}
        </th>
      </tr>
    </thead>

    <tbody v-if="rows.length">
      <tr v-for="row in rows" @click="onClickRow(row)">
        <td v-for="column in columns" v-html="renderColumnValue(column, row)" />
      </tr>
      <tr v-for="row in padding">
        <td v-for="column in columns"/>
      </tr>
    </tbody>

    <tbody v-else>
      <tr>
        <td v-bind:colspan="columns.length">
          <p>{{ messages ? messages.none : defaults.messages.none }}</p>
        </td>
      </tr>
    </tbody>

    <tfoot v-if="total">
      <tr>
        <td :colspan="columns.length">
          <slot name="footer"></slot>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
  import lang from '~/lib/lang';

  export default {
    name: 'data-table',

    props: {
      pk: {
        type: String,
        default: 'id',
      },
      columns: {
        type: Array,
        required: true,
      },
      rows: {
        type: Array,
        required: true,
      },
      total: {
        type: Number,
        required: true,
      },
      messages: {
        type: Object,
        required: false,
        default: () => ({none: 'No records.'}),
      },
    },

    computed: {

      padding () {
        let {limit, rows} = this;
        return lang.array.range(1, limit - rows.length);
      },
    },

    methods: {

      onClickRow (row) {
        let id = row[this.pk];
        if (id === undefined) {
          throw new Error('failed to resolve row primary key');
        }
        this.$emit('onClickRow', id);
      },

      renderColumnValue (column, row) {
        let method = typeof column.html === 'function' ? 'html' : 'value';
        return column[method](row);
      },
    },
  };

</script>
