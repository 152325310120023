import Module from '~/lib/vue/modules/module';
import {InvalidArgument} from '~/lib/exceptions';

export const STATES = {
  'draft': 'Draft',
  'published': 'Published',
  'approved': 'Approved',
  'voided': 'Voided',
};

export default (services, namespace='states') => {

  return Module.create({
    namespace,

    state: {
      allowed: STATES,
      current: STATES.draft,
    },

    getters: {
      allowed: state => state.allowed,
      current: state => state.current,
    },

    mutations: {
      current: (store, value) => store.state.current = value,
    },

    actions: {
      set: (store, value) => {
        if (!STATES.hasOwnProperty(value)) {
          throw new InvalidArgument(`not an allowed document state: ${value}`);
        }
        store.commit('current', value);
      },
    },
  });
};
