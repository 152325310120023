<script>

  import lang from '~/lib/lang';
  import format from '~/lib/domain/format';
  import html from '~/lib/html';

  export default {

    props: {
      attributes: {
        type: Object,
        required: true,
      },
      search: {
        type: Object,
        required: false,
      },
    },

    computed: {

      picture () {
        let {attachments} = this.attributes;
        if (attachments && attachments.length) {
          return attachments[0];
        }
        return null;
      },

      title () {
        let {name, address, city} = this.attributes;
        if (name) {
          return name;
        }
        let tokens = [];
        address && tokens.push(address.split('\n')[0]);
        city && tokens.push(city);
        return tokens.join(', ');
      },

      htmlAddress () {
        return format.address(this.attributes).map(html.encode).join('<br>');
      },

      province () {
        let {province} = this.attributes;
        return province ? province.name : '';
      },
    },
  };

</script>
