import Module from '~/lib/vue/modules/module';
import datatable from './datatable';
import record from './record';
import reference from './reference';
import states from './states';
import types from './types';
import items from './items';
import printer from './printer';
import warranties from './warranties';
import optionals from './optionals';
import terms from './terms';

export default (services, namespace='document') => {
  return Module.create({
    namespace,
    services,
    modules: {
      datatable,
      record,
      reference,
      states,
      types,
      printer,
      warranties,
      items,
      optionals,
      terms,
    },
  });
};
