import DataTableModule from '~/lib/vue/modules/datatable';
import rest from '~/lib/rest';
import constants from '~/lib/domain/constants';

/**
 * @param {~/lib.foundation.Container} services
 * @return {Object}
 */
export default (services, namespace='datatable') => {

  return DataTableModule.create({
    namespace,
    services,
    url: '/customers',
    columns: [
      {
        label: 'ID',
        value: row => row.id,
      },
      {
        label: 'Name',
        value: row => row.name,
      },
      {
        label: 'Company',
        value: row => row.company ? row.company.name : '-',
      },
      {
        label: 'Email',
        value: row => row.email,
      },
      {
        label: 'Tel',
        value: row => row.tel,
      },
    ],
  });
};
