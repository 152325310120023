import _Upload from './upload';
import _Bucket from './bucket';
import _processors from './processors';

export const Upload = _Upload;
export const Bucket = _Bucket;
export const processors = _processors;

export default {
  Upload,
  Bucket,
  processors,
};
