<template>

  <div class="clipboard" :data-text="text">
    <span class="icon is-small is-pulled-right clipboard-button" style="cursor: pointer;">
      <span class="fa fa-clipboard" />
    </span>
    <p v-if="slotted"><slot></slot></p>
  </div>

</template>
<script>

  export default {

    props: {
      text: {
        type: String,
        default: '',
      },
      ns: {
        type: String,
        default: 'clipboard',
      },
      icon: {
        type: String,
        default: 'fa-clipboard',
      },
    },

    computed: {
      buttonClass () {
        return `clipboard-button`;
      },
      slotted () {
        return !!this.$slots.default;
      },
    },
  };


</script>
