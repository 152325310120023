import Module from './module';
import exceptions from '~/lib/exceptions';
import rest from '~/lib/rest';
import lang from '~/lib/lang';

export class DataTableModule extends Module {

  /**
   * A list of column definitions. A column definition is an object that contains
   * these properties:
   *
   *    label {String} the table column heading
   *    value {Function} a function that accepts a row object and returns a String
   *
   * @readonly
   * @property
   * @type {Array}
   */
  get columns () {
    return this.option('columns');
  }

  get defaultQueryParams () {
    let defaults = {
      sorts: {id: 'desc'},
      filters: {},
      includes: [],
      paging: {offset: 0, limit: 10},
    };
    return lang.object.merge(defaults, this.option('query', {}));
  }

  /**
   * @inheritdoc
   */
  get state () {
    return {
      columns: this.columns,
      rows: [],
      page: 1,   // current page number
      total: 0,  // total number of records in the dataset
      url: null, // the REST collection resource path
      query: this.defaultQueryParams,
    };
  }

  /**
   * @inheritdoc
   */
  get getters () {
    return {
      ...this.option('getters', {}),
      columns: state => state.columns || [],
      rows: state => state.rows || [],
      page: state => state.page,
      limit: state => Number(state.query.paging.limit),
      total: state => Number(state.total || 0),
      query: state => state.query,
      url: state => state.url,
    };
  }

  /**
   * @inheritdoc
   */
  get mutations () {
    return {
      ...this.option('mutations', {}),
      page: (state, page) => state.page = page,
      columns: (state, columns) => state.columns = columns,
      rows: (state, rows) => state.rows = rows,
      limit: (state, limit) => state.query.paging.limit = limit,
      total: (state, total) => state.total = total,
      query: (state, query) => state.query = query,
      url: (state, url) => state.url = url,
    };
  }

  /**
   * @inheritdoc
   */
  get actions () {
    let services = this.services;
    let api = services.get('api');
    let storage = services.get('storage');

    return {

      ...this.option('actions', {}),

      initialize: async (store, params={}) => {
        let {url, query} = params;
        store.commit('url', url || this.option('url'));
        store.commit('query', this.defaultQueryParams);
        if (query) {
          store.dispatch('setQuery', query);
        }
      },

      load: async (store) => {
        let limit = Number(storage.get('paging:limit') || 10);
        await store.dispatch('setLimit', limit);
      },

      /**
       * Update paging limit
       * @async
       * @param {Object} store
       * @param {Number} page
       * @return void
       */
      setLimit: async (store, limit) => {
        limit = Number(limit);
        storage.get('paging:limit', limit);
        await store.dispatch('setQuery', {paging: {limit}});
        await store.dispatch('setPage', 1);
      },

      /**
       * Navigate to a datatable page
       * @async
       * @param {Object} store
       * @param {Number} page
       * @return void
       */
      setPage: async (store, page) => {
        store.commit('page', Number(page));

        let {state} = store;
        let limit = state.query.paging.limit;
        let offset = (state.page - 1) * limit;

        await store.dispatch('setQuery', {paging: {limit, offset}});

        let qs = String(new rest.Query(state.query));
        let url = qs ? `${state.url}?${qs}` : state.url;
        let response = await api.get(url);
        let rows = lang.object.freeze(response.payload);
        let crange = response.headers.get('Content-Range');
        let {total} = rest.parse.contentRange(crange);

        store.commit('rows', rows);
        store.commit('total', total);
      },

      /**
       * Set query parameters
       * @async
       * @param {Object} params
       * @return void
       */
      setQuery: (store, params) => {
        let {parse, stringify} = JSON;
        let base = parse(stringify(store.state.query));
        let query = lang.object.merge(base, params);
        if (params.sorts) {
          query.sorts = {...params.sorts};
        }

        store.commit('query', query);
      },

      setState: (store, mutations) => {
        Object.keys(this.mutations).map((key) => {
          if (mutations.hasOwnProperty(key)) {
            store.commit(key, mutations[key]);
          }
        });
      },
    };
  }
}


export default DataTableModule;

