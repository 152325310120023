import {Component} from '~/lib/foundation';
import {File} from '~/lib/fs';
import constants from '~/lib/domain/constants';
import exceptions from '~/lib/exceptions';
import {Deferred} from '~/lib/promise';
import uuid from '~/lib/uuid';

class Upload extends Component {

  get defaults () {
    return {
      ...super.defaults,

      /**
       * @type {String}
       */
      id: uuid.v4(),

      /**
       * @type {String}
       */
      bucket: null,

      /**
       * @type {fs.File}
       */
      file: null,

      /**
       * @type {rest.Client}
       */
      api: null,

      /**
       * Array of upload file preprocessors
       * @type {uploads.processors.Processor[]}
       */
      preprocessors: [],

      resolvers: {
        XMLHttpRequest: () => new XMLHttpRequest(),
        FormData: () => new FormData(),
      },
    };
  }

  constructor (...args) {
    super(...args);
    this.progress = null;
    this.error = null;
    this._state = constants.UPLOAD_STATE_QUEUED,

    this._started = new Deferred();
    this.started = this._started.promise;

    this._transferred = new Deferred();
    this.transferred = this._transferred.promise;

    this._finished = new Deferred();
    this.finished = this._finished.promise;

    this._aborted = new Deferred();
    this.aborted = this._aborted.promise;
  }

  get id () {
    return this.option('id');
  }

  get bucket () {
    return this.option('bucket');
  }

  get file () {
    return this.option('file');
  }

  get state () {
    return this._state;
  }

  set state (next) {
    let allowed = Object.keys(constants.UPLOAD_STATES);
    let kosher = allowed.includes(next);
    if (!kosher) {
      throw new exceptions.InvalidState('invalid upload state');
    }
    this._state = next;
  }

  get progress () {
    return this._progress;
  }

  set progress (progress) {
    if (progress < 0) {
      progress = 0;
    }
    if (progress > 1) {
      progress = 1;
    }
    if (progress !== this._progress) {
      this._progress = progress;
      this.emit('progress', progress);
    }
  }

  get api () {
    return this.option('api');
  }

  get preprocessors () {
    return this.option('preprocessors');
  }

  async start () {
    if (this._startCalled) {
      return this.started;
    }

    this._startCalled = true;

    (async () => {
      let {file} = this;
      try {
        this.state = constants.UPLOAD_STATE_PREPARING;
        file = await this.preprocess(file);

        this.progress = 0;
        this.send(file);

        await this.started;
        this.state = constants.UPLOAD_STATE_PROGRESS;

        // XMLHtpRequest is transferring here

        await this.transferred;
        this.state = constants.UPLOAD_STATE_COMPLETE;
        this.progress = 1;
        this.request = null;
        this._finished.resolve(file);
      }
      catch (e) {
        this.state = constants.UPLOAD_STATE_FAILED;
        this.error = e.message;
        this._finished.reject(e);
      }
    })();

    return this.started;
  }

  /**
   * @async
   * @param {Number} ttl - timeout in milliseconds; default 5 minutes
   * @return {fs.File}
   */
  async send (file, ttl=300000) {
    if (this._sendCalled) {
      return this.started;
    }
    this._sendCalled = true;

    let {blob, sum} = file;
    let form = this.make('FormData');
    form.append(sum, blob);

    let {api} = this;
    let url = `${api.root}/blobs`;

    this.request = this.xhr(file);
    this.request.upload.onprogress = ({total, loaded, lengthComputable}) => {
      if (lengthComputable) {
        this.progress = loaded / total; 
      }
    };

    this.request.open('POST', url, true);
    this.request.setRequestHeader('Authorization', api.authorization);
    this.request.send(form);
    this._started.resolve();

    return this.started;
  }

  async abort () {
    let abortable_states = [
      constants.UPLOAD_STATE_QUEUED,
      constants.UPLOAD_STATE_PREPARING,
      constants.UPLOAD_STATE_PROGRESS,
    ];

    if (abortable_states.includes(this.state)) {
      this.state = constants.UPLOAD_STATE_ABORTED;
      this.request && this.request.abort();
      this._started.reject(new exceptions.Aborted());
      this._finished.reject(new exceptions.Aborted());
    }

    this._aborted.resolve();

    return this.aborted;
  }

  /**
   * Pass the file through the upload processor pipeline 
   * @param {String} moment ("before" or "after" the XHR upload)
   * @return {fs.File}
   */
  async preprocess (file) {
    let {preprocessors} = this;

    /**
     * @param {fs.File} previous
     * @param {upload.processors.AbstractMiddleware} processors
     * @return {fs.File}
     */
    let reducer = async (previous, preprocessor, index) => {
      let next = await preprocessor.process(previous);
      if (!next) {
        throw new exceptions.UnexpectedOutcome(`processor #${index} did not return next file`);
      }
      return next;
    };

    if (preprocessors.length) {
      return await preprocessors.reduce(reducer, file);
    }

    return file;
  }

  get pending () {
    let pending_states = [
      constants.UPLOAD_STATE_QUEUED,
      constants.UPLOAD_STATE_PREPARING,
      constants.UPLOAD_STATE_PROGRESS,
    ];
    return pending_states.includes(this.state);
  }

  get properties () {
    let {id, bucket, file, state, progress, pending, error} = this;
    return {id, bucket, file, state, progress, pending, error};
  }

  /**
   * Create an XMLHttpRequest instance
   * @param {Function} done
   * @return {XMLHttpRequest}
   */
  xhr (file) {
    let request = this.make('XMLHttpRequest');

    request.onreadystatechange = () => {
      let {readyState, status, statusText} = request;
      if (readyState === 4) {
        if (status >= 200 && status < 300) {
          this._transferred.resolve();
        }
        else {
          let e = new exceptions.UnexpectedOutcome(statusText);
          this._transferred.reject(e);
        }
      }
    };

    return request;
  }
}

export default Upload;
