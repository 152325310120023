import {Exception} from '~/lib/exceptions';

export class HttpException extends Exception {

  constructor (message, response) {
    let {payload} = response;
    super(payload && payload.message ? payload.message : message);
    this.response = response;
  }
}

export default {
  HttpException,
};
