<template>
  <div class="upload-manager">
    <upload-splash v-if="!buckets.length" />
    <upload-bucket v-else v-for="bucket in buckets" :key="bucket.id" :bucket="bucket" :eventbus="eventbus"/>
  </div>
</template>

<script>
  import UploadBucket from './UploadBucket.vue';
  import UploadSplash from './UploadSplash.vue';
  import Vue from 'vue';
  export default {
    components: {
      UploadBucket,
      UploadSplash,
    },
    props: {
      buckets: {
        required: true,
        type: Array,
      },
      eventbus: {
        required: true,
        type: Vue,
      },
    },
  };
</script>
