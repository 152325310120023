<template>

  <div class="columns object-tag">
    <div class="column is-narrow">
      <p class="tags has-addons">
        <span class="tag is-dark is-medium">
          {{ type }}
        </span>
        <span v-if="identifier" class="tag is-light is-medium">
          {{ identifier }}
        </span>
      </p>
    </div>
    <div v-if="slotted" class="column">
      <slot></slot>
    </div>
  </div>

</template>
<script>

  export default {
    name: 'object-tag',

    props: {
      type: {
        type: String,
        required: true,
      },
      identifier: {
        required: false,
        default: null,
      },
    },

    computed: {
      slotted () {
        return !!this.$slots.default;
      },
    },
  };

</script>

