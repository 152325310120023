import lodashMerge from 'lodash.merge';
import map from 'lodash.map';
import each from 'lodash.foreach';
import reduce from 'lodash.reduce';
import capitalize from 'lodash.capitalize';
import find from 'lodash.find';
import deepfreeze from 'deep-freeze';
import clone from 'lodash.clone';

export const is = {};

is.plain = value => value?.constructor === Object;

/**
 * Works like PHP's empty() function
 * @param mixed value
 * @return {Boolean}
 */
is.empty = (value) => {
  if (!value) {
    return value === undefined || !isNaN(value);
  }

  if (typeof value === 'number') {
    return value === 0;
  }

  if (typeof value.length === 'number') {
    return !value.length;
  }

  if (typeof value.size === 'number') {
    return !value.size;
  }

  if (typeof value === 'object') {
    for (let k in value) {
      if (value.hasOwnProperty(k)) {
        return false;
      }
    }

    return true;
  }

  return false;
};

export const object = {};

object.clean = (obj) => {
  let plain = {};
  Object.keys(obj).forEach(k => plain[k] = obj[k]);
  return plain;
};

object.clone = clone;
object.merge = lodashMerge;

object.freeze = import.meta.env.ENVIRONMENT === 'local' ? deepfreeze : v => v;

object.fromEntries = (entries) => {
  if (!Array.isArray(entries)) {
    throw new Error('invalid argument: expected entries to be an array');
  }
  let obj = {};
  each(entries, (entry) => {
    let [k, v] = entry;
    obj[k] = v;
  });
  return obj;
};

export const array = {};

array.range = (start, end, step=1) => {
  let range = [];
  for (let i = start; i <= end; i += step) {
    range.push(i);
  }
  return range;
};

export const string = {capitalize};

string.truncate = (str, length, ellipsis='...') => {
  if (typeof str !== 'string') {
    throw new Error('invalid str argument');
  }
  if (typeof length !== 'number' || isNaN(length)) {
    throw new Error('invalid length argument');
  }
  let trimmed = str.trim();
  if (trimmed.length <= length) {
    return trimmed;
  }
  return `${trimmed.slice(0, length)}${ellipsis}`;

};

export const collection = {each, map, find, reduce};

collection.itemize = (collection) => {
  if (!collection || typeof collection !== 'object') {
    throw new Error('invalid collection argument');
  }

  let items = [];
  if (Array.isArray(collection) || collection instanceof Map || collection instanceof Set) {
    collection.forEach((v, k) => items.push([k, v]));
  }
  else if (typeof collection === 'object') {
    Object.keys(collection).forEach((k) => items.push([key, collection[key]]));
  }
  else {
    throw new Error('unexpected collection type');
  }

  return items;
};

export const merge = lodashMerge;

export default {
  is,
  object,
  array,
  collection,
  string,
};
