<template>
  <li class="field has-addons">
    <div class="control is-expanded">
      <textarea
        :class="{'is-danger': errors.text}"
        :value="attributes.text"
        @keyup="handleAttributeChange"
        data-attr="text"
        type="text"
        rows="4"
        class="textarea"
      />
      <p v-if="errors.text" class="help is-error">
        {{ errors.text }}
      </p>
      <p v-else v-html="'&nbsp;'"/>
    </div>
    <div class="control">
      <button
        class="delete"
        v-on:click.prevent.stop="handleDeleteButtonClick"
        style="border: none; cursor: pointer; margin-left: 1rem"
        :style="{visibility: deletable ? 'visible' : 'hidden'}"
      />
      <slot name="handle"/>
    </div>
  </li>
</template>

<script>

  // UI representation of a DocumentItem
  // see https://gitlab.com/industrydigital/sure-platform/blob/160dbbf6f5c3cd9987b05de4c6627077b89effb7/docs/swagger.yml#L1099

  export default {
    name: 'document-form-item',

    props: {
      index: {
        type: Number,
        required: true,
      },
      deletable: {
        type: Boolean,
        required: true,
      },
      attributes: {
        type: Object,
        required: true,
      },
      errors: {
        type: Object,
        default: () => ({}),
      },
    },

    methods: {
      handleAttributeChange (event) {
        let el = event.target;
        let {index} = this;
        let {value} = el;
        let {attr} = el.dataset;
        let attrs = {[attr]: value};
        this.$emit('update', {index, attrs});
      },
      handleDeleteButtonClick (event) {
        this.$emit('delete', this.index);
      },
      handleFocus (event) {
        event.target.select();
      },
    },

  };

</script>
