import DataTableModule from '~/lib/vue/modules/datatable';
import rest from '~/lib/rest';
import lang from '~/lib/lang';
import constants from '~/lib/domain/constants';
import currency from '~/lib/currency';

/**
 * @param {~/lib.foundation.Container} services
 * @return {Object}
 */
export default (services, namespace='datatable') => {

  return DataTableModule.create({
    namespace,
    services,
    url: '/documents',
    columns: [
      {
        label: 'ID',
        value: row => row.id,
      },
      {
        label: 'Type',
        value: row => constants.DOCUMENT_TYPES[row.type],
      },
      {
        label: 'Status',
        html: row => {
          let status = row.states.slice(0).pop();
          let colors = {
            [constants.DOCUMENT_STATE_DRAFT]: 'is-dark',
            [constants.DOCUMENT_STATE_ACCEPTED]: 'is-success',
          };

          let color = colors[status.name] || 'is-warning';
          let text = constants.DOCUMENT_STATES[status.name];
          let html = `<span class="tag ${color}">${ text }</span>`;

          return html;
        },
      },
      {
        label: 'Valuation',
        align: 'right',
        value: (row) => {
          let sum = row.sum || 0;
          let status = row.states.slice(0).pop();
          if (status.name === constants.DOCUMENT_STATE_ACCEPTED) {
            sum += row.optionals.reduce((out, i) => out + (i.accepted ? i.sum : 0), 0);
          }
          return currency.format(sum || 0, {code: 'USD'});
        },
      },
    ],
  });
};
