import TermSearchModule from '~/lib/vue/modules/termsearch';

export default (services) => {

  return TermSearchModule.create({
    services,
    url: '/search/customers',
    placeholder: 'Find customers',
  });
};
