import {EventEmitter} from '~/lib/events';
import {merge} from '~/lib/lang';
import P from '~/lib/promise';
import {Deferred} from '~/lib/promise';

/**
 * Component is a configurable event emitter.
 * @example
 *
 *   // configure the component via constructor options argument
 *   let component = new Component({foo: 'some value'});
 *
 *   // configure the component via configure()
 *   component.configure({bar: 'another value');
 *
 *   // retrieve a configured value
 *   component.option('foo'); // returns "some value"
 *   component.option('bar'); // returns "another value"
 *
 *   try {
 *     component.option('blah'); // throws ("blah" is not configured);
 *   }
 *   catch (e) {
 *     component.option('blah', 'fallback'); // returns "fallback"
 *   }
 *
 *   await component.destroy(); // lifecycle moment
 */
export class Component extends EventEmitter {

  /**
   * @readonly
   * @property
   * @type {Object}
   */
  get defaults () {
    return {
      resolvers: {},
    };
  }

  /**
   * @constructor
   * @param {Object} options - instance configuration
   */
  constructor (options={}) {
    super();
    this._options = this.defaults;
    this.configure(options);
  }

  /**
   * @param {String} key
   * @param {Object} opts
   * @param {*} opts.fallback
   * @param {Boolean} opts.pull
   * @return {*}
   */
  option (key, fallback=undefined) {
    let {_options} = this;
    let value = fallback;
    let defined = _options.hasOwnProperty(key) && _options[key] !== undefined;

    if (!defined) {
      if (fallback === undefined) {
        throw new Error(`missing option: ${key}`);
      }
      return fallback;
    }

    value = _options[key];

    return value;
  }

  /**
   * @param {Object} options
   * @return void
   */
  configure (options={}) {
    let resolvers = {...this.option('resolvers', {}), ...(options.resolvers || {})};
    this._options = {...this._options, ...options, resolvers};
  }

  make (key, ...args) {
    let resolvers = this.option('resolvers', {});
    let resolver = resolvers[key];
    if (!typeof resolver === 'function') {
      throw new Error(`resolver named "${key}" not found`);
    }
    return resolver(...args);
  }

  /**
   * @async
   */
  async destroy () {
    this.emit('destroying');
    return new P((resolve, reject) => {
      this.off();
      resolve();
    });
  }
}

/**
 * Container is a key-value store that has:
 *  - an asynchronous set() method
 *  - a synchronous get() method
 */
export class Container extends Component {

  /**
   * @inheritdoc
   */
  get defaults () {
    return {
      ...super.defaults,
      bindings: new Map(),
    };
  }

  /**
   * @inheritdoc
   */
  constructor (options={}) {
    super(options);
    this.bindings = this.option('bindings', {pull: true});
  }

  /**
   * Bind a value to the container on the supplied key. Value may be any of the
   * following:
   *  - any primitive data type
   *  - a promise
   *  - a function that returns anything, including a promise
   *
   * A promised value will bind to the container when the promise settles.
   *
   * @async Promise
   * @param {String} key
   * @param mixed value
   * @return void
   */
  async set (key, value) {
    let {bindings} = this;

    if (bindings.has(key)) {
      throw new Error(`bind conflict on key "${key}"`);
    }

    let bind = (...args) => {
      if (!args.length) {
        throw new Error('expected an argument');
      }
      bindings.set(key, args[0]);
      return P.resolve();
    };

    if (value) {
      if (typeof value === 'function') {
        value = await value();
      }
      else if (typeof value.then === 'function') {
        value.then(bind);
      }
    }

    return bind(value);
  }

  get (key) {
    let {bindings} = this;

    if (!bindings.has(key)) {
      throw new Error(`binding not found on key "${key}"`);
    }

    return bindings.get(key);
  }

  /**
   * @inheritdoc
   */
  async destroy () {
    // see https://github.com/babel/babel/issues/3930 (wtf?)
    await Component.prototype.destroy.call(this);
    this.bindings = {};
  }
}

export default {
  Component,
  Container,
};
