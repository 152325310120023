import {Component} from '~/lib/foundation';
import lang from '~/lib/lang';

/**
 * Base Vuex module implementation that provides access application services.
 * @see https://vuex.vuejs.org/en/modules.html
 */
export class Module extends Component {

  /**
   * @param {...args} args
   * @return {~/lib.vue.state:Module}
   */
  static create (...args) {
    let instance = new this(...args);
    return instance.clean();
  }

  /**
   * @inheritdoc
   */
  constructor (...args) {
    super(...args);
    this._debounces = {};
  }

  /**
   * @readonly
   * @property
   * @type {~/lib.foundation:Container}
   */
  get services () {
    return this.option('services');
  }

  /**
   * @see https://vuex.vuejs.org/en/modules.html#namespacing
   * @readonly
   * @property
   * @type {Boolean}
   */
  get namespaced () {
    return true;
  }

  /**
   * @see https://vuex.vuejs.org/en/state.html
   * @readonly
   * @property
   * @type {Object}
   */
  get state () {
    return this.option('state', {});
  }

  /**
   * @see https://vuex.vuejs.org/en/getters.html
   * @readonly
   * @property
   * @type {Object}
   */
  get getters () {
    return this.option('getters', {});
  }

  /**
   * @see https://vuex.vuejs.org/en/mutations.html
   * @readonly
   * @property
   * @type {Object}
   */
  get mutations () {
    return this.option('mutations', {});
  }

  /**
   * Return a dictionary containing arrow functions that are lexically scoped
   * to this instance.
   * @see https://vuex.vuejs.org/en/actions.html
   * @readonly
   * @property
   * @type {Object}
   */
  get actions () {
    return this.option('actions', {});
  }

  /**
   * @see https://vuex.vuejs.org/en/modules.html#namespacing
   * @readonly
   * @property
   * @type {Object}
   */
  get modules () {
    let mods = {};
    lang.collection.each(this.option('modules', {}), (resolver, namespace) => {
      let mod = resolver(this.services, namespace);
      mods[namespace] = mod;
    });
    return mods;
  }

  /**
   * Return a plain object representation of the module
   * @param void
   * @return {Object}
   */
  clean () {
    let {namespaced, state, getters, mutations, actions, modules} = this;
    getters.props = this.createPropsDictionary({...getters});
    return {namespaced, state, getters, mutations, actions, modules};
  }

  /**
   * Extract a named message from options.messages
   * @param {String} key
   * @return {String}
   */
  message (key) {
    let messages = this.option('messages', {});
    return messages[key] || '';
  }

  /**
   * @param {Function} callback
   * @param {String} handle
   * @return void
   */
  debounce (callback, handle='default') {
    let timeout = this._debounces[handle];
    timeout !== undefined && clearTimeout(timeout);
    this._debounces[handle] = setTimeout(callback, this.option('debounce', 250));
  }

  /**
   * @param {Object} getters
   * @return {Function}
   */
  createPropsDictionary (getters) {
    return (store) => {
      return Object.keys(getters).reduce((props, key) => {
        props[key] = getters[key](store);
        return props;
      }, {});
    };
  }
}

export default Module;
