<template>
  <div class="attachment-thumbnail" :style="styleBindings">
    <a :href="blob.url" target="_blank">
      <figure class="image" :class="figureClassBindings">
        <img v-if="url" ref="image" :src="url"/>
        <span v-else class="text">{{ fileExtension }}</span>
      </figure>
    </a>
  </div>
</template>

<script>
  import P from '~/lib/promise';
  import lang from '~/lib/lang';
  import {video} from '~/lib/media';

  export default {
    props: {
      attributes: {
        required: true,
        type: Object,
      },
      size: {
        required: false,
        type: Number,
        default: 128,
      },
    },

    data () {
      let {size} = this;
      let {blob} = this.attributes;
      return {
        url: null,
        href: null,
        blob,
        styleBindings: {
          width: `${size}px`,
          height: `${size}px`,
        },
        fileExtension: blob.ext,
      };
    },

    computed: {
      figureClassBindings () {
        let {size, url} = this;
        return {
          [`is-${size}x${size}`]: true,
          bordered: !url,
        };
      },
    },

    async mounted () {
      let {blob} = this;
      let type = blob.mime.match(/^([\w\-]+)\//)[1];

      if (type === 'video') {
        this.url = await video.thumbnail(blob.url);
      }
      else if (type === 'image') {
        this.url = blob.url;
      }
    },
  };
</script>
