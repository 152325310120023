import {Component} from '~/lib/foundation';
import P from '~/lib/promise';
import crypto from '~/lib/crypto';

export class Filesystem extends Component {

  get defaults () {
    return {
      fs: async () => {
        return new P((resolve, reject) => {
          let rfs = window.requestFileSystem || window.webkitRequestFileSystem;
          let type = window.TEMPORARY;
          let mb = 1024 * 1024;
          let size = 8 * mb;
          rfs.call(window, type, size, resolve, reject);
        });
      },
    };
  }

  /**
   * Write the supplied Blob to the temporary filesystem as the given filename.
   * Return the blob's URL.
   * @async
   * @param {String} filename
   * @param {Blob} blob
   * @return {String}
   */
  async put (filename, blob) {
    let fs = await this.option('fs')();
    let noop = () => {};

    return new P((resolve, reject) => {
      let expire = () => reject(new Error('print operation timed out'));
      let timeout = setTimeout(expire, 10000);

      fs.root.getFile(filename, {create: true}, (entry) => {

        entry.createWriter((writer) => {
          let done, onwriteend, onerror;

          done = (next) => {
            clearTimeout(timeout);
            writer.removeEventListener('writeend', onwriteend);
            writer.removeEventListener('error', onerror);
            next();
          };

          onwriteend = () => done(() => resolve(entry.toURL()));
          onerror = (e) => done(() => reject(e));
          writer.addEventListener('writeend', onwriteend);
          writer.addEventListener('error', onerror);

          writer.write(blob);

        }, noop);
      }, noop);
    });
  }
}

export class File extends Component {

  static async from (blob) {
    let sum = await File.sum(blob);
    let instance = new File({blob, sum});
    return instance;
  }

  static async sum (blob) {
    let instance = new File({blob});
    let hash = crypto.algo.SHA256.create();
    let update = async (buffer) => hash.update(crypto.lib.WordArray.create(buffer));
    await instance.tap(update, {as: 'readAsArrayBuffer'});
    return hash.finalize().toString(crypto.enc.Hex);
  }

	get defaults () {
		return {
      ...super.defaults,
      resolvers: {
        reader: () => new FileReader(),
      },
		};
	}

  get sum () {
    return this.option('sum');
  }

  get blob () {
    return this.option('blob');
  }

  get name () {
    return this.blob.name || null;
  }

  get mime () {
    return this.blob.type;
  }

  get size () {
    return this.blob.size;
  }

  async base64 () {
    let url = await this.dataURL();
    let regex = new RegExp(`^data:[\w-]+\/[\w-]+;base64,`);
    return url.replace(regex, '');
  }

  async dataURL () {
    let {blob} = this;
    let reader = this.make('reader');

    return await new P((resolve, reject) => {
      reader.onerror = reject;
      reader.onload = async (event) => resolve(reader.result);
      reader.readAsDataURL(this.blob);
    });
  }

  async read (options={}) {
    let defaults = {as: 'readAsBinaryString'};
    let {as} = {...defaults, ...options};
    let {blob} = this;
    let reader = this.make('reader');

    return new P((resolve, reject) => {
      reader.onerror = reject;
      reader.onload = async (event) => resolve(event.target.result);
      reader[as](blob);
    });
  }

  async tap (callback, options={}) {
    let defaults = {
      as: 'readAsBinaryString',
      chunksize: 1024 * 1024, // 1 MB
    };

    let {as, chunksize} = {...defaults, ...options};
    let {blob} = this;
    let reader = this.make('reader');

    let read = async (start, end) => {
      let chunk = blob.slice(start, end);
      return new P((resolve, reject) => {
        reader.onerror = reject;
        reader.onload = async (event) => resolve(event.target.result);
        reader[as](chunk);
      });
    };

    let {size} = blob;
    let max = size - 1; // max index for blob.slice() call

    let range = (start) => {
      let end = start + chunksize;
      if (end > size) {
        end = size;
      }
      return [start, end];
    };

    for (let cursor = 0; cursor < size; cursor += chunksize) {
      let data = await read(...range(cursor));
      await callback(data);
    }
  }
}

export default {
  Filesystem,
  File,
};
