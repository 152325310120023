<template>

  <div class="container">
    <form ref="form" class="form">
      <radio
        name="topping"
        :value="radioValue"
        :values="radioValues"
        @change="handleChangeRadioValue"
      />
    </form>
  </div>

</template>
<script>

  import P from '~/lib/promise';
  import {mapState, mapGetters, mapActions} from 'vuex';
  import Radio from '~/components/Radio.vue';
  import RadioButton from '~/components/RadioButton.vue';

  export default {

    components: {
      Radio,
      RadioButton,
    },

    data () {
      return {
        radioValue: 'pineapple',
        radioValues: [
          {label: 'Pepperoni', value: 'pepperoni'},
          {label: 'Pineapple', value: 'pineapple'},
          {label: 'Parma Ham', value: 'parma-ham'},
          {label: 'Olives', value: 'olives'},
          {label: 'Porcini mushrooms', value: 'porcini'},
        ],
      };
    },

    methods: {

      handleChangeRadioValue (value) {
        this.radioValue = value;
        console.log(value);
      }
    },
  };


</script>
