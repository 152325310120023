export const decodeWithoutVerification = (jwt = '') => {
    const segments = jwt.split('.').slice(0, 2); //--> We only care about the second segment
    const e = new Error('Input argument does not appear to be a JWT');
    if (segments.length < 2) {
        throw e;
    }
    const json = atob(segments[1]);
    if (!json) {
        throw e;
    }
    try {
        return JSON.parse(json);
    }
    catch (e) {
        throw e;
    }
};
export const parse = (jwt) => {
    if (jwt) {
        try {
            return decodeWithoutVerification(jwt);
        }
        catch (e) {
            // JWT parse error; bury it
        }
    }
    return null;
};
