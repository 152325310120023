<template>

  <div class="dropdown is-hoverable">
    <div class="dropdown-trigger">
      <span class="icon is-small has-text-grey-lighter">
        <i class="fa fa-bars" />
      </span>
    </div>
    <div class="dropdown-menu" role="menu">
      <slot></slot>
    </div>
  </div>

</template>
<script>

  export default {
    name: 'dropdown',
  };

</script>
