import DataRecordModule from '~/lib/vue/modules/datarecord';
import {ADDRESS_TYPE_SERVICE} from '~/lib/domain/constants';

export default (services, namespace='address') => {

  const propagate = async ({state, dispatch}) => {
    let {country, province} = state.attributes;
    if (country) {
      await dispatch('customer/territories/setCountry', country.id, {root: true});
    }
    if (province) {
      await dispatch('customer/territories/setProvince', province.id, {root: true});
    }
  };

  const refresh = async ({dispatch}) => {
    await dispatch('customer/record/reload', null, {root: true});
  };

  return DataRecordModule.create({
    namespace,
    services,
    type: 'address',
    describe: payload => `Address ${payload.id}`,
    attributes: {
      id: null,
      type: ADDRESS_TYPE_SERVICE,
      address: null,
      city: null,
      postcode: null,
      country: null,
      province: null,
    },

    afterLoad: propagate,
    afterUpdate: propagate,
    afterSave: refresh,
    afterDelete: refresh,

    form: ({rootGetters}, attributes) => {
      return {
        ...attributes,
        country_id: rootGetters['customer/territories/country_id'],
        province_id: rootGetters['customer/territories/province_id'],
      };
    },

  });
};
