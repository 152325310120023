<template>
  <form v-on:submit.prevent="onSubmit" novalidate>

    <fieldset @change="handleChangeEvent">

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label" for="type">Type</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <div class="select">
                <select id="type" data-attr="type">
                  <option v-for="(label, type) in types" :value="type" :selected="attributes.type === type" v-html="label" />
                </select>
              </div>
            </div>
            <p v-if="errors.type" class="help is-error">
              {{ errors.type }}
            </p>
            <p v-else v-html="'&nbsp;'"/>
          </div>
        </div>
      </div>

      <div v-if="documentIsContract" class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label" for="summary">Summary</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input
               type="text"
               data-attr="summary"
               class="input"
               :value="attributes.summary"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label" for="sum">Valuation</label>
        </div>
        <div class="field-body">
          <div class="field has-addons">
            <div class="control">
              <a class="button is-static">&dollar;</a>
            </div>
            <div class="control">
              <input
               type="number"
               data-attr="sum"
               class="input"
               :value="attributes.sum || 0"
               min="0"
               step="10"
               style="text-align: right; width: 10rem;"
               @focus="selectInput"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label" for="sum">Deposit</label>
        </div>
        <div class="field-body">
          <div class="field has-addons">
            <div class="control">
              <a class="button is-static">&dollar;</a>
            </div>
            <div class="control">
              <input
                type="number"
                data-attr="deposit"
                class="input"
                :value="attributes.deposit"
                min="0"
                :max="attributes.sum || 0"
                step="1"
                style="text-align: right; width: 10rem;"
                @focus="selectInput"
              />
            </div>
          </div>
        </div>
      </div>
    </fieldset>



    <div class="is-divider"/>

    <fieldset>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Line Items</label>
        </div>
        <div class="field-body">
          <div class="field">
            <slot name="standard-playbook" style="margin-bottom: 1em;"/>
            <slot name="items"></slot>
          </div>
        </div>
      </div>
    </fieldset>

    <fieldset>
      <div v-if="attributes.type == 'contract'" class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Optional Work</label>
        </div>
        <div class="field-body">
          <div class="field">
            <slot name="optionals-playbook" style="margin-bottom: 1em;"/>
            <slot name="optionals"></slot>
          </div>
        </div>
      </div>
    </fieldset>

    <div v-if="documentIsContract" class="is-divider"/>

    <fieldset v-if="documentIsContract">
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Warranties</label>
        </div>
        <div class="field-body">
          <div class="field">
            <slot name="warranties"></slot>
          </div>
        </div>
      </div>
    </fieldset>

    <div v-if="documentIsContract" class="is-divider"/>

    <fieldset v-if="documentIsContract">
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Additional Terms</label>
        </div>
        <div class="field-body">
          <div class="field">
            <slot name="terms"></slot>
          </div>
        </div>
      </div>
    </fieldset>

    <div class="is-divider"/>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
      </div>
      <div class="field-body">
        <div class="field">
          <slot name="buttons"></slot>
        </div>
      </div>
    </div>

  </form>
</template>

<script>
  import DocumentFormItem from '~/components/document/DocumentFormItem.vue';
  import constants from '~/lib/domain/constants';

  export default {
    name: 'document-form',

    props: {
      attributes: {
        type: Object,
        required: true,
      },
      errors: {
        type: Object,
        default: () => ({}),
      },
      types: {
        type: Object,
        required: true,
      },
      job: {
        type: Object,
        required: true,
      },
      customer: {
        type: Object,
        required: true,
      },
      property: {
        type: Object,
        required: true,
      },
    },

    computed: {
      documentIsContract () {
        return this.attributes.type === constants.DOCUMENT_TYPE_CONTRACT;
      },
    },

    methods: {

      onSubmit (event) {
        event.preventDefault();
        event.stopPropagation();
        this.$emit('save');
      },

      handleChangeEvent (event) {
        let {value} = event.target;
        let {attr} = event.target.dataset;
        this.$emit('onChangeAttribute', {[attr]: value});
      },

      selectInput (event) {
        event.target.select();
      },
    },

  };

</script>
