<template>
  <form @submit="handleSubmit" @change="handleAttributeChange" novalidate>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="type">Type</label>
      </div>
      <div class="field-body">
        <div class="field has-addons">
          <div class="select">
            <div class="control">
              <select id="type" data-attr="type" @change="handleCustomerTypeChange">
                <option v-for="({label, selected}, type) in customerTypeOptions" :value="type" :selected="selected" v-html="label" />
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="customerIsCompany" class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="name">Name</label>
      </div>
      <div v-if="customerIsCompany" class="field-body">
        <div class="field has-addons">
          <div class="control">
            <input id="name" data-attr="name" class="input" :class="{'is-danger': errors.name}" type="text" :value="attributes.name" placeholder="Name of company">
          </div>
          <p v-if="errors.name" class="help is-error">
            {{ errors.name }}
          </p>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label v-if="customerIsCompany" class="label" for="firstname">Representative</label>
        <label v-else class="label" for="firstname">Name</label>
      </div>
      <div class="field-body">
        <div class="field has-addons">
          <div class="control">
            <input id="firstname" data-attr="firstname" class="input" :class="{'is-danger': errors.firstname}" type="text" :value="attributes.firstname" placeholder="First name">
          </div>
          <p v-if="errors.firstname" class="help is-error">
            {{ errors.firstname }}
          </p>
          <div class="control">
            <input id="lastname" data-attr="lastname" class="input" :class="{'is-danger': errors.lastname}" type="text" :value="attributes.lastname" placeholder="Last name">
          </div>
          <p class="help is-error" v-if="errors.lastname">
            {{ errors.lastname}}
          </p>
        </div>
      </div>
    </div>

    <div v-if="!customerIsCompany" class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="name">Company</label>
      </div>
      <div class="field-body">
        <slot name="company-search"/>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label">
        <label class="label" for="email">Contact Information</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input id="email" data-attr="email" class="input" :class="{'is-danger': errors.email}" type="email" :value="attributes.email" placeholder="Email">
          </div>
          <p class="help is-error" v-if="errors.email">
            {{ errors.email}}
          </p>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label"/>
      <div class="field-body">
        <div class="field has-addons">
          <div class="control">
            <input
              data-attr="tel"
              class="input"
              :class="{'is-danger': errors.tel}"
              type="tel"
              :value="attributes.tel"
              @change="prependDefaultCountryCode"
              placeholder="Phone"
            />
          </div>
          <p class="help is-error" v-if="errors.tel">
            {{ errors.tel }}
          </p>
          <div class="control">
            <input
              data-attr="tel_secondary"
              class="input"
              :class="{'is-danger': errors.fax}"
              type="tel"
              :value="attributes.tel_secondary"
              placeholder="Secondary phone"
              @change="prependDefaultCountryCode"
            />
          </div>
          <p class="help is-error" v-if="errors.fax">
            {{ errors.fax }}
          </p>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="service_address">Service Address</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <textarea id="service_address" data-attr="service_address" class="textarea" :class="{'is-danger': errors.service_address}" type="text" :value="attributes.service_address" placeholder="Street address" rows="2"/>
          </div>
          <p v-if="errors.service_address" class="help is-error">
            {{ errors.service_address}}
          </p>
        </div>
      </div>
    </div>

    <div class="field is-horizontal has-addons">
      <div class="field-label"></div>
      <div class="field-body">
          <div class="control">
            <input data-attr="service_city" class="input" :class="{'is-danger': errors.service_city}" type="text" :value="attributes.service_city" placeholder="City">
          </div>
          <p v-if="errors.service_city" class="help is-error">
            {{ errors.service_city}}
          </p>
          <div class="control">
            <div class="select">
              <select placeholder="State/Province" @change="handleChangeServiceProvince">
                <option
                  v-for="territory in serviceAddressTerritories.provinces"
                  :value="territory.id"
                  :selected="territory.id === serviceAddressTerritories.province_id"
                >
                  {{ territory.abbrev }}
                </option>
              </select>
            </div>
          </div>
          <div class="control">
            <input data-attr="service_postcode" class="input" :class="{'is-danger': errors.service_postcode}" type="text" :value="attributes.service_postcode" placeholder="ZIP" style="width: 8em;">
          </div>
          <p v-if="errors.service_postcode" class="help is-error">
            {{ errors.service_postcode}}
          </p>
      </div>
    </div>

    <div class="field is-horizontal" v-if="hasBillingAddress">
      <div class="field-label is-normal">
        <label class="label" for="billing_address">Billing Address</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <textarea id="billing_address" data-attr="billing_address" class="textarea" :class="{'is-danger': errors.billing_address}" type="text" :value="attributes.billing_address" placeholder="Street address" rows="2"/>
          </div>
          <p v-if="errors.billing_address" class="help is-error">
            {{ errors.billing_address}}
          </p>
        </div>
      </div>
    </div>

    <div class="field is-horizontal" v-if="hasBillingAddress">
      <div class="field-label"></div>
      <div class="field-body">
        <div class="field has-addons">
          <div class="control">
            <input id="billing_city" data-attr="billing_city" class="input" :class="{'is-danger': errors.billing_city}" type="text" :value="attributes.billing_city" placeholder="City">
          </div>
          <p v-if="errors.billing_city" class="help is-error">
            {{ errors.billing_city}}
          </p>
          <div class="control">
            <div class="select">
              <select placeholder="State/Province" @change="handleChangeBillingProvince">
                <option
                  v-for="territory in billingAddressTerritories.provinces"
                  :value="territory.id"
                  :selected="territory.id === billingAddressTerritories.province_id"
                >
                  {{ territory.abbrev }}
                </option>
              </select>
            </div>
          </div>
          <div class="control is-narrow">
            <input id="billing_postcode" data-attr="billing_postcode" class="input" :class="{'is-danger': errors.billing_postcode}" type="text" :value="attributes.billing_postcode" placeholder="ZIP">
          </div>
          <p v-if="errors.billing_postcode" class="help is-error">
            {{ errors.billing_postcode}}
          </p>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label"></div>
      <div class="field-body">
        <div class="control">
          <label class="checkbox">
            <input type="checkbox" data-attr="has_separate_billing_address" @change.stop="handleChangeBillingAddressCheckbox" :checked="editingBillingAddress || hasBillingAddress">
            <span>Separate billing address</span>
          </label>
        </div>
      </div>
    </div>

    <div class="field is-horizontal" v-if="hasMailingAddress">
      <div class="field-label is-normal">
        <label class="label" for="mailing_address">Mailing Address</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <textarea id="mailing_address" data-attr="mailing_address" class="textarea" :class="{'is-danger': errors.mailing_address}" type="text" :value="attributes.mailing_address" placeholder="Street address" rows="2"/>
          </div>
          <p v-if="errors.mailing_address" class="help is-error">
            {{ errors.mailing_address}}
          </p>
        </div>
      </div>
    </div>

    <div class="field is-horizontal" v-if="hasMailingAddress">
      <div class="field-label"></div>
      <div class="field-body">
        <div class="field has-addons">
          <div class="control">
            <input id="mailing_city" data-attr="mailing_city" class="input" :class="{'is-danger': errors.mailing_city}" type="text" :value="attributes.mailing_city" placeholder="City">
          </div>
          <p v-if="errors.mailing_city" class="help is-error">
            {{ errors.mailing_city}}
          </p>
          <div class="control">
            <div class="select">
              <select placeholder="State/Province" @change="handleChangeMailingProvince">
                <option
                  v-for="territory in mailingAddressTerritories.provinces"
                  :value="territory.id"
                  :selected="territory.id === mailingAddressTerritories.province_id"
                >
                  {{ territory.abbrev }}
                </option>
              </select>
            </div>
          </div>
          <div class="control is-narrow">
            <input id="mailing_postcode" data-attr="mailing_postcode" class="input" :class="{'is-danger': errors.mailing_postcode}" type="text" :value="attributes.mailing_postcode" placeholder="ZIP">
          </div>
          <p v-if="errors.mailing_postcode" class="help is-error">
            {{ errors.mailing_postcode}}
          </p>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label"></div>
      <div class="field-body">
        <div class="control">
          <label class="checkbox">
            <input type="checkbox" data-attr="has_separate_mailing_address" @change.stop="handleChangeMailingAddressCheckbox" :checked="editingMailingAddress || hasMailingAddress">
            <span>Separate mailing address</span>
          </label>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label">
        <label class="label"></label>
      </div>
      <div class="field-body">
        <div class="field">
          <span class="control">
            <button type="submit" class="button" @submit="handleSubmit">
              <span class="icon">
                <span class="fa fa-save" />
              </span>
              <span class="button-text">Commit</span>
            </button>
          </span>
          <span class="control">
            <button class="button" @click.prevent="handleCancel">
              <span class="icon">
                <span class="fa fa-times" />
              </span>
              <span class="button-text">Cancel</span>
            </button>
          </span>
        </div>
      </div>
    </div>

  </form>
</template>

<script>
  import CustomerBase from './CustomerBase.vue';
  import Icon from '~/components/Icon.vue';

  export default {
    name: 'customer-form',
    mixins: [CustomerBase],
    components: {
      Icon,
    },

    props: {
      attributes: {
        type: Object,
        required: true,
      },
      errors: {
        type: Object,
        default: () => ({}),
      },
      serviceAddressTerritories: {
        type: Object,
        validator: (v) => {
          let {countries, provinces, country_id, province_id} = v;
          return Array.isArray(countries) && Array.isArray(provinces);
        },
      },
      billingAddressTerritories: {
        type: Object,
        validator: (v) => {
          let {countries, provinces, country_id, province_id} = v;
          return Array.isArray(countries) && Array.isArray(provinces);
        },
      },
      mailingAddressTerritories: {
        type: Object,
        validator: (v) => {
          let {countries, provinces, country_id, province_id} = v;
          return Array.isArray(countries) && Array.isArray(provinces);
        },
      },
    },

    data () {
      return {
        editingBillingAddress: false,
        editingMailingAddress: false,
      };
    },

    computed: {
      companyName () {
        let {company, name} = this.attributes;
        return company && company.name ? company.name : name || '';
      },

      hasBillingAddress () {
        let {billing_address} = this.attributes;
        return billing_address || this.editingBillingAddress;
      },

      hasMailingAddress () {
        let {mailing_address} = this.attributes;
        return mailing_address || this.editingMailingAddress;
      },
    },

    methods: {

      parseAttributeChangeEvent (event) {
        return attr ? {attr, value} : {};
      },

      async handleAttributeChange (event) {
        let {value, dataset} = event.target;
        let {attr} = dataset;
        this.$emit('onChangeAttribute', {[attr]: value});
      },

      async handleChangeServiceProvince (event) {
        let {value} = event.target;
        this.$emit('onChangeServiceProvince', value);
      },

      async handleChangeBillingProvince (event) {
        let {value} = event.target;
        this.$emit('onChangeBillingProvince', value);
      },

      async handleChangeMailingProvince (event) {
        let {value} = event.target;
        this.$emit('onChangeMailingProvince', value);
      },

      async handleSubmit (event) {
        event.preventDefault();
        this.$emit('onSubmit');
      },

      async handleCancel (event) {
        event.preventDefault();
        this.$emit('onCancel');
      },

      async prependDefaultCountryCode (event) {
        let {name, value} = event.target;
        if (value && !/^\+\d+\s/.test(value)) {
          event.target.value = `+1 ${value}`;
        }
      },

      async handleChangeBillingAddressCheckbox (event) {
        let {checked} = event.target;
        this.editingBillingAddress = checked;
        !checked && this.$emit('clearBillingAddress');
      },


      async handleChangeMailingAddressCheckbox (event) {
        let {checked} = event.target;
        this.editingMailingAddress = checked;
        !checked && this.$emit('clearMailingAddress');
      },

      async handleCustomerTypeChange (event) {

      },
    },

  };

</script>
