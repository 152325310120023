export const DEFAULT_TAXON_COUNTRY = '/territories/us';
export const DEFAULT_TAXON_PROVINCE = `${DEFAULT_TAXON_COUNTRY}/california`;

export const JOB_STATE_OPEN = 'open';
export const JOB_STATE_ESTIMATED = 'estimated';
export const JOB_STATE_QUOTED = 'quoted';
export const JOB_STATE_AWARDED = 'awarded';
export const JOB_STATE_SCHEDULED = 'scheduled';
export const JOB_STATE_PROGRESS = 'progress';
export const JOB_STATE_COMPLETED = 'completed';
export const JOB_STATE_CLOSED = 'closed';
export const JOB_STATES = {
  [JOB_STATE_OPEN]: 'Open',
  [JOB_STATE_ESTIMATED]: 'Estimated',
  [JOB_STATE_QUOTED]: 'Quoted',
  [JOB_STATE_AWARDED]: 'Awarded',
  [JOB_STATE_SCHEDULED]: 'Scheduled',
  [JOB_STATE_PROGRESS]: 'Progress',
  [JOB_STATE_COMPLETED]: 'Completed',
  [JOB_STATE_CLOSED]: 'Closed',
};

export const DOCUMENT_TYPE_CONTRACT = 'contract';
export const DOCUMENT_TYPE_CHANGEORDER = 'change-order';
export const DOCUMENT_TYPES = {
  [DOCUMENT_TYPE_CONTRACT]: 'Contract',
  [DOCUMENT_TYPE_CHANGEORDER]: 'Change Order',
};

export const DOCUMENT_STATE_DRAFT = 'draft';
export const DOCUMENT_STATE_PUBLISHED = 'published';
export const DOCUMENT_STATE_ACCEPTED = 'accepted';
export const DOCUMENT_STATES = {
  [DOCUMENT_STATE_DRAFT]: 'Draft',
  [DOCUMENT_STATE_PUBLISHED]: 'Published',
  [DOCUMENT_STATE_ACCEPTED]: 'Accepted',
};

export const DOCUMENT_ITEM_TYPE_WORK = 'work';
export const DOCUMENT_ITEM_TYPE_OPTWORK = 'optwork';
export const DOCUMENT_ITEM_TYPE_TERM = 'term';
export const DOCUMENT_ITEM_TYPES = {
  [DOCUMENT_ITEM_TYPE_WORK]: 'Work',
  [DOCUMENT_ITEM_TYPE_OPTWORK]: 'Optional Work',
  [DOCUMENT_ITEM_TYPE_TERM]: 'Term',
};

export const PAPER_SIZE_A4 = 'a4';
export const PAPER_SIZE_LETTER = 'letter';
export const PAPER_SIZE_LEGAL = 'legal';

export const PAPER_SIZES = [
  PAPER_SIZE_A4,
  PAPER_SIZE_LETTER,
  PAPER_SIZE_LEGAL,
];

export const WARRANTY_TYPE_WORKMANSHIP = 'workmanship';
export const WARRANTY_TYPE_MANUFACTURER = 'manufacturer';
export const WARRANTY_TYPES = {
  [WARRANTY_TYPE_WORKMANSHIP]: 'Workmanship',
  [WARRANTY_TYPE_MANUFACTURER]: 'Manufacturer',
};

export const WARRANTY_TERM_UNIT_YEAR = 'Y';
export const WARRANTY_TERM_UNIT_MONTH = 'M';
export const WARRANTY_TERM_UNIT_DAY = 'D';
export const WARRANTY_TERM_UNITS = {
  [WARRANTY_TERM_UNIT_YEAR]: 'years',
  [WARRANTY_TERM_UNIT_MONTH]: 'months',
  [WARRANTY_TERM_UNIT_DAY]: 'days',
};

export const ADDRESS_TYPE_BILLING = 'billing';
export const ADDRESS_TYPE_SERVICE = 'service';
export const ADDRESS_TYPES = {
  [ADDRESS_TYPE_BILLING]: 'Billing',
  [ADDRESS_TYPE_SERVICE]: 'Service',
};

export const CUSTOMER_TYPE_INDIVIDUAL = 'individual';
export const CUSTOMER_TYPE_COMPANY = 'company';
export const CUSTOMER_TYPES = {
  [CUSTOMER_TYPE_INDIVIDUAL]: 'Individual',
  [CUSTOMER_TYPE_COMPANY]: 'Business',
};

export const UPLOAD_STATE_QUEUED = 'queued';
export const UPLOAD_STATE_PREPARING = 'preparing';
export const UPLOAD_STATE_PROGRESS = 'progress';
export const UPLOAD_STATE_COMPLETE = 'complete';
export const UPLOAD_STATE_ABORTED = 'aborted';
export const UPLOAD_STATE_FAILED = 'failed';
export const UPLOAD_STATES = {
  [UPLOAD_STATE_QUEUED]: 'Queued',
  [UPLOAD_STATE_PREPARING]: 'Preparing',
  [UPLOAD_STATE_PROGRESS]: 'Transferring',
  [UPLOAD_STATE_COMPLETE]: 'Complete',
  [UPLOAD_STATE_ABORTED]: 'Aborted',
  [UPLOAD_STATE_FAILED]: 'Failed',
};

export const ATTACHMENT_REL_DEFAULT = 'default';
export const ATTACHMENT_RELS = {
  [ATTACHMENT_REL_DEFAULT]: 'None',
};

export const DEPOSIT_DEFAULT_FRACTION = 0.1;
export const DEPOSIT_MAX = 1000;

export const ROLE_OWNER = 'owner';
export const ROLE_SALES = 'sales';
export const ROLES = [ROLE_OWNER, ROLE_SALES];

export default {
  DEFAULT_TAXON_COUNTRY,
  DEFAULT_TAXON_PROVINCE,
  JOB_STATE_OPEN,
  JOB_STATE_ESTIMATED,
  JOB_STATE_AWARDED,
  JOB_STATE_SCHEDULED,
  JOB_STATE_PROGRESS,
  JOB_STATE_COMPLETED,
  JOB_STATE_CLOSED,
  JOB_STATES,
  DOCUMENT_TYPE_CONTRACT,
  DOCUMENT_TYPE_CHANGEORDER,
  DOCUMENT_TYPES,
  DOCUMENT_STATE_DRAFT,
  DOCUMENT_STATE_PUBLISHED,
  DOCUMENT_STATE_ACCEPTED,
  DOCUMENT_STATES,
  DOCUMENT_ITEM_TYPE_WORK,
  DOCUMENT_ITEM_TYPE_OPTWORK,
  DOCUMENT_ITEM_TYPE_TERM,
  DOCUMENT_ITEM_TYPES,
  PAPER_SIZE_A4,
  PAPER_SIZE_LETTER,
  PAPER_SIZE_LEGAL,
  PAPER_SIZES,
  WARRANTY_TYPE_WORKMANSHIP,
  WARRANTY_TYPE_MANUFACTURER,
  WARRANTY_TYPES,
  WARRANTY_TERM_UNIT_YEAR,
  WARRANTY_TERM_UNIT_MONTH,
  WARRANTY_TERM_UNIT_DAY,
  WARRANTY_TERM_UNITS,
  ADDRESS_TYPE_BILLING,
  ADDRESS_TYPE_SERVICE,
  ADDRESS_TYPES,
  CUSTOMER_TYPE_INDIVIDUAL,
  CUSTOMER_TYPE_COMPANY,
  CUSTOMER_TYPES,
  UPLOAD_STATE_QUEUED,
  UPLOAD_STATE_PREPARING,
  UPLOAD_STATE_PROGRESS,
  UPLOAD_STATE_COMPLETE,
  UPLOAD_STATE_ABORTED,
  UPLOAD_STATE_FAILED,
  UPLOAD_STATES,
  ATTACHMENT_REL_DEFAULT,
  ATTACHMENT_RELS,
  DEPOSIT_DEFAULT_FRACTION,
  DEPOSIT_MAX,
  ROLE_OWNER,
  ROLE_SALES,
  ROLES,
};
