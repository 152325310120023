<template>
  <page>

    <modal
      :open="modalOpen"
      @close="closeModal"
    >
      <div class="box" slot="body">
        <object-tag
          slot="header"
          type="Customer"
          :identifier="customer.attributes.id"
        />
        <customer-form-container
          @commit="afterCommitCustomerForm"
          @cancel="afterCancelCustomerForm"
        />
      </div>
    </modal>

    <object-tag
      slot="page-title"
      type="Customer"
      :identifier="guessCustomerIdFromRoute()"
    />

    <div class="columns">
      <div class="column">
        <customer-card
          :attributes="customer.attributes"
        >
          <div slot="buttons">
            <icon :classes="['fa', 'fa-edit']" :tooltip="'Edit Customer'" :clickable="true" @click="openModal" style="cursor: pointer;" />
            <icon v-if="customer.deletable" :classes="['fa', 'fa-trash']" :tooltip="'Delete Customer'" :clickable="true" @click="handleDeleteCustomer"/>
          </div>
        </customer-card>
      </div>
      <div class="column">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h2 class="subtitle">Jobs</h2>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <btn :route="{name: 'job:create', query: {customer: $route.params.id}}">
                <icon slot="icon" :classes="['fa', 'fa-plus-circle']"/>
                New Job
              </btn>
            </div>
          </div>
        </div>
        <data-table
          :rows="jobs.rows"
          :columns="jobColumns"
          :limit="5"
          :total="jobs.total"
          @onClickRow="navigateToJob"
        />
      </div>
    </div>

    <h2 class="subtitle">Sales Notes</h2>
    <note-widget>
      <note-list slot="list" :notes="customerNotes.notes" @delete="deleteCustomerNote" />
      <note-input slot="input" @add="addCustomerNote" />
    </note-widget>

  </page>
</template>
<script>

  import {mapState, mapGetters, mapActions} from 'vuex';
  import P from '~/lib/promise';
  import CustomerCard from '~/components/customer/CustomerCard.vue';
  import {DataTable, DataPager} from '~/lib/vue/components/datatables';
  import ObjectTag from '~/components/page/ObjectTag.vue';
  import CustomerContext from './mixins/CustomerContext.vue';
  import JobContext from './mixins/JobContext.vue';
  import NoteWidget from '~/components/notes/NoteWidget.vue';
  import NoteList from '~/components/notes/NoteList.vue';
  import NoteInput from '~/components/notes/NoteInput.vue';
  import ModalContext from './mixins/ModalContext.vue';
  import Modal from '~/components/Modal.vue';
  import CustomerFormContainer from '~/containers/CustomerFormContainer.vue';
  import Icon from '~/components/Icon.vue';
  import Btn from '~/components/Btn.vue';
  import Page from '~/components/Page.vue';

  export default {
    mixins: [CustomerContext, JobContext, ModalContext],

    components: {
      CustomerCard,
      CustomerFormContainer,
      DataTable,
      DataPager,
      Icon,
      ObjectTag,
      Modal,
      NoteWidget,
      NoteList,
      NoteInput,
      Page,
      Btn,
    },

    data () {
      return {
        jobColumns: [
          {
            label: 'ID',
            value: row => row.id,
          },
          {
            label: 'Property',
            value: (row) => {
              let {property} = row;
              return [
                property.address.split('\n')[0],
                property.city,
              ].join('; ');
            },
          },
          {
            label: 'Status',
            html: row => `<span class="tag is-dark">${row.states.slice(0).pop().name}</span>`,
          },
        ],
      };
    },

    computed: {

      ...mapGetters('job/datatable', {
        'jobs': 'props',
      }),
    },

    methods: {

      ...mapActions('job/datatable', {
        initializeJobTable: 'initialize',
        loadJobTable: 'load',
        onChangeJobTablePage: 'setPage',
        onChangeJobTableLimit: 'setLimit',
      }),

      async onClickDeleteButton () {
        this.deleteCustomer();
      },

      async handleClickNewAddress () {
        await this.openModal();
      },
    },

    mounted () {
      let id = this.guessCustomerIdFromRoute();

      (async () => {

        await this.initializeCustomerContext();
        this.initializeJobTable({
          query: {
            includes: ['states'],
            filters: {
              match_customers: [id],
            },
          },
        });
        this.loadJobTable();

      })();
    },
  };

</script>
