<template>

	<article class="media upload">
		<figure class="media-left">
			<div class="thumbnail">
				<upload-thumbnail :file="upload.file"/>
			</div>
		</figure>
		<div class="media-content">
			<div class="level is-mobile">

        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Filename</p>
            <p class="tooltip" :data-tooltip="upload.file.name">{{ abbreviatedFilename }}</p>
          </div>
        </div>
				<div class="level-item has-text-centered">
          <div>
            <p class="heading">Type</p>
            <p>{{ upload.file.mime }}</p>
          </div>
        </div>
				<div class="level-item has-text-centered">
          <div>
            <p class="heading">Size</p>
            <p>{{ fileSize}}</p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Status</p>
            <p>{{ status }}</p>
          </div>
        </div>

      </div>
      <progress class="progress" :class="progressClassBindings" :value="progressPercentage" max="100"/>
    </div>
		<div class="media-right">
      <btn :flat="true" @click="handleCancel">
        <icon :classes="['fa', 'fa-times']" :tooltip="cancelButtonTooltip"/>
      </btn>
		</div>
	</article>

</template>

<script>
  import {Upload} from '~/lib/uploads';
  import UploadThumbnail from './UploadThumbnail.vue';
  import Btn from '~/components/Btn.vue';
  import Icon from '~/components/Icon.vue';
  import constants from '~/lib/domain/constants';

  export default {

    components: {
      Btn,
      Icon,
      UploadThumbnail,
    },

    props: {
      upload: {
        required: true,
        type: Upload,
      },
      eventbus: {
        required: true,
      },
    },

    computed: {
      abbreviatedSum () {
        let {sum} = this.upload.file;
        return sum.slice(0, 16);
      },
      progressPercentage () {
        let {progress} = this.upload;
        return Math.floor(progress * 100);
      },
      progressClassBindings () {
        let {state} = this.upload;
        let bindings = {};
        if (state === constants.UPLOAD_STATE_COMPLETE) {
          bindings['is-success'] = true;
        }
        return bindings;
      },
      cancelButtonTooltip () {
        let {state} = this.upload;
        if (state === constants.UPLOAD_STATE_COMPLETE) {
          return 'Clear';
        }
        return 'Abort';
      },
      fileSize () {
        let {size} = this.upload.file;
        let kb = 1024;
        let mb = 1024 * kb;
        let gb = 1000 * mb;
        let format = (v, unit) => `${Number.parseFloat(v).toFixed(1)} ${unit}`;
        if (size > gb) {
          return format(size/gb, 'GB');
        }
        if (size > mb) {
          return format(size/mb, 'MB');
        }
        return format(size/kb, 'kB');
      },
      abbreviatedFilename () {
        let filename = this.upload.file.name;
        let max = 32;
        if (filename.length < max) {
          return filename;
        }
        return `${filename.slice(0, max)}...`;
      },
      status () {
        let {state} = this.upload;
        return constants.UPLOAD_STATES[state];
      },
    },

    methods: {
      handleCancel () {
        this.eventbus.$emit('upload:remove', this.upload.id);
      },
    },
  };

</script>
