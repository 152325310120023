import Module from '~/lib/vue/modules/module';
import P from '~/lib/promise';
import constants from '~/lib/domain/constants';
import {File} from '~/lib/fs';

class PrinterModule extends Module {

  get state () {
    return {
      queue: [], // document id buffer
    };
  }

  get getters () {
    return {
      printing: state => state.queue.length > 0,
    };
  }

  get mutations () {
    return {
      queue: (state, queue) => state.queue = [...queue],
    };
  }

  get actions () {
    let api = this.services.get('api');
    let fs = this.services.get('fs');

    return {

      initialize: async () => {
        store.commit('queue', []);
      },

      addJob: (store) => {
        let queue = [...store.state.queue];
        queue.push(1);
        store.commit('queue', queue);
      },

      removeJob: (store) => {
        let queue = [...store.state.queue];
        queue.pop();
        store.commit('queue', queue);
      },

      /**
       * @async
       * @param {Object} params
       *  @param {Number} document - a Document id
       * @return {Object} - a blob payload (see Swagger spec)
       */
      print: async (store, {document, size, orientation, force}) => {
        store.dispatch('addJob');

        let response = await api.post('/print', {
          raw: true,
          body: JSON.stringify({
            document,
            size: size || constants.PAPER_SIZE_LEGAL,
            orientation: orientation || constants.ORIENTATION_PORTRAIT,
            force: !!force,
          }),
        });

				let blob = await response.blob();
        let file = new File({blob});
        let url = await file.dataURL();

        store.dispatch('removeJob');

        return url;
      },
    };
  }

}

export default PrinterModule;
