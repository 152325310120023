export default ({store, storage}) => {

  return (to, from, next) => {
    let grant = storage.get('auth');
    let {user, token} = (grant || {});
    let allowed = route => route.meta.guest || user;
    if (to.matched.some(allowed)) {
      return next();
    }
    let dest = storage.get('auth:dest') || to;
    return next({name: 'auth:login', query: {dest: dest.path}});
  };
};
