import Vue from 'vue';
import {sync} from 'vuex-router-sync';
import boot from '~/boot';
import AppContainer from '~/containers/AppContainer.vue';

import '~/scss/main.scss';
import '~/lib/icons';

Array.prototype.each = Array.prototype.forEach;

(async () => {

  // boot services
  let services = await boot();

  // service resolver function
  let $service = (key, ...params) => services.get(key, ...params);

  // expose services to Vue contexts
  Vue.prototype.$service = $service;

  let store = $service('store');
  let router = $service('router');

  // sync router with store state
  sync(store, router);

  // render the root component
  const app = new Vue({
    el: '#app',
    router,
    store,
    render: h => h(AppContainer)
  });

})();
